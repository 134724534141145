import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const SidebarNavContainer = styled.nav<{ open: boolean }>`
  width: 372px;
  height: 100vh;
  max-height: 100vh;
  position: fixed;
  top: 0;
  left: ${(props) => (props.open ? 0 : '-372px')};
  padding: 20px 8px;
  transition: 0.2s;
  background: #212121;
  overflow: hidden;
  z-index: 200;
  & li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    cursor: pointer;
    border-radius: 8px;
    margin-bottom: 2px;
    & a {
      padding: 8px 12px;
    }

    &:hover {
      background: #353535;
    }
    & > ul {
    }
  }

  @media (max-width: ${WIDTH.mobileMax}) {
    width: 100%;
    left: ${(props) => (props.open ? 0 : '-100%')};
  }
`;

export const SidebarHeader = styled.div`
  padding: 8px;
  padding-bottom: 20px;
  padding-top: 0;
  border-bottom: 1px solid #ffffff1a;
  display: flex;
  align-items: center;
  padding-right: 12px;
  justify-content: space-between;
  margin-bottom: 8px;
  & > div {
    display: flex;
    align-items: center;
  }
`;

export const ListContainer = styled.ul<{ active: boolean }>`
  display: block;
  width: 372px;
  height: 100vh;
  position: absolute;
  max-height: 100vh;
  top: 0;
  left: ${(props) => (props.active ? '0' : '372px')};
  transition: 0.2s;
  padding: 20px 8px;
  background: #212121;
  overflow: hidden;
  z-index: 20;
  @media (max-width: ${WIDTH.mobileMax}) {
    width: 100%;
    left: ${(props) => (props.active ? 0 : '100%')};
  }
`;

export const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 150;
`;
