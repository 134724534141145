import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { NavbarChildrenInterface } from '../../Interface/NavbarInterface';
import { saveEventV3 } from '../../utils/eventTracking';
import { BackgroundIcon, MaskIcon } from '../Icons/Icons';
import { ListContainer, SidebarHeader } from './SidebarMegamenuStyles';

interface NavItemPropsI {
  data: NavbarChildrenInterface;
  type: 'subDivision' | 'children';
  handleClose: () => void;
  open: boolean;
  additional?: boolean;
  step: number;
  parent?: string;
}

const NavItem: React.FC<NavItemPropsI> = ({
  data,
  type,
  handleClose,
  open,
  additional,
  step,
  parent,
}) => {
  const router = useRouter();
  const [active, setActive] = useState<boolean>(false);

  useEffect(() => {
    if (!open) {
      setActive(false);
    }
  }, [open]);

  return (
    <li>
      <Link
        href={additional ? (data.url ? data.url : '') : '/store/' + data.name}
        passHref
      >
        <a
          onClick={
            // @ts-ignore
            data[type] && data[type].length > 0
              ? (e) => {
                  e.preventDefault();
                  setActive((prev) => !prev);

                  saveEventV3({
                    action: 'click',
                    category: 'navigation_sidebar',
                    label: data.displayName,
                    value: [data.queryStr],
                    properties: String('step_' + step),
                    from: router,
                    jsonData: {
                      parent: parent ?? '',
                    },
                  });
                }
              : () => {
                  saveEventV3({
                    action: 'click',
                    category: 'navigation_sidebar',
                    label: data.displayName,
                    value: [data.queryStr],
                    properties: String('step_' + step),
                    from: router,
                    jsonData: {
                      parent: parent ?? '',
                    },
                  });
                  handleClose();
                  setActive((prev) => !prev);
                }
          }
          style={{ width: '100%' }}
        >
          <span
            // onClick={() => }
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center;',
            }}
          >
            <div>
              {data.displayName}
              {data.name === 'steal deals' ? (
                <BackgroundIcon
                  height="24px"
                  width="24px"
                  margin="-8px 0 0px 4px"
                  url="/gifs/hot-trending.gif"
                />
              ) : (
                data.name === 'save with plus' && (
                  <BackgroundIcon
                    height="24px"
                    width="36px"
                    margin="0 5px 0 5px"
                    url="/logo/plus-logo.svg"
                  />
                )
              )}
            </div>
            {/* @ts-ignore */}
            {data.viewAll ? (
              <MaskIcon
                url="/icons/arrow_right_alt.svg"
                width="24px"
                height="24px"
                margin="0"
                color="#fff"
                style={{ transform: 'rotate(0deg)' }}
              />
            ) : (
              // @ts-ignore
              data[type] &&
              // @ts-ignore
              data[type].length > 0 && (
                <MaskIcon
                  url="/icons/keyboard_arrow_left.svg"
                  width="24px"
                  height="24px"
                  margin="0"
                  color="#fff"
                  style={{ transform: 'rotate(180deg)' }}
                />
              )
            )}
          </span>
        </a>
      </Link>

      {/* @ts-ignore */}
      {data[type] && data[type].length > 0 ? (
        <ListContainer active={active}>
          <SidebarHeader>
            <div onClick={() => setActive(false)}>
              <MaskIcon
                url="/icons/keyboard_arrow_left.svg"
                width="24px"
                height="24px"
                margin="0"
                color="#fff"
              />
              <p>{data.displayName}</p>
            </div>
            <MaskIcon
              url="/icons/close-24.svg"
              width="24px"
              height="24px"
              margin="0"
              color="#fff"
              onClick={() => {
                handleClose();
              }}
              style={{ cursor: 'pointer' }}
            />
          </SidebarHeader>
          <div style={{ maxHeight: 'calc(100vh - 92px)', overflow: 'auto' }}>
            {/* @ts-ignore */}
            {data[type].map((cur: NavbarChildrenInterface, i) => (
              <NavItem
                handleClose={handleClose}
                type="children"
                key={i}
                data={cur}
                open={open}
                step={step + 1}
                parent={data.displayName}
              />
            ))}
          </div>
        </ListContainer>
      ) : null}
    </li>
  );
};

export default NavItem;
