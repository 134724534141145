import { MessageKeyTypes } from '../Interface/MessageKeyTypes';
import { NewNavbarInterface } from '../Interface/NavbarInterface';

export const generateNavigation = (
  messages: { [key in MessageKeyTypes]: string } | undefined
): NewNavbarInterface => {
  const {
    action_msg,
    adventure_msg,
    rpg_msg,
    indie_msg,
    strategy_msg,
    tps_msg,
    simulation_msg,
    sports_msg,
    pubg_mobile_uc_msg,
    pubg_new_state_nc_msg,
    garena_free_fire_diamonds_msg,
    gta_online_cash_msg,
    riot_points_msg,
    xbox_games_msg,
    xbox_game_pass_msg,
    psn_games_msg,
    psn_gift_cards_msg,
    nintendo_cards_msg,
    nintendo_eshop_cards_msg,
    nintendo_games_msg,
    nintendo_switch_games_msg,
    nintendo_msg,
    nintendo_switch_msg,
    steam_giftcard_msg,
    steam_dlcs_msg,
    steam_games_msg,
    by_genre_msg,
    platform_msg,
    platform_products_msg,
    by_region_msg,
    platform_subscription_by_region_msg,
    ps_plus_by_region_msg,
    gift_cards_by_region_msg,
    lifestyle_cards_msg,
    egift_cards_msg,
    subscriptions_msg,
    ea_play_msg,
    ubisoft_connect_games_msg,
    gog_com_msg,
    epic_games_msg,
    xbox_live_gift_cards_us_msg,
    europe_msg,
    united_kingdom_msg,
    united_states_msg,
    global_msg,
    gift_card_msg,
    game_msg,
    pre_order_msg,
    store_msg,
    game_point_msg,
    genre_msg,
    dlcs_msg,
    steal_deals_msg,
    view_all_msg,
    plus_save_with_plus_msg,
  } = messages || {};

  return {
    name: 'Nav-items',
    displayName: '',
    children: [
      // {
      //   name: 'store',
      //   displayName: store_msg ?? '',
      //   url: '/store',
      //   queryStr: '/store',
      //   id: 1,
      //   navbar: true,
      // },
      // -------------------------------------------------------------------------------
      {
        name: 'steam',
        displayName: 'Steam' ?? '',
        url: '/store?platform=Steam&page=1',
        queryStr: 'platform=Steam',
        id: 2,
        navbar: false,
        subDivision: [
          {
            name: game_point_msg ?? '',
            displayName: 'Game Points',
            idSub: 104,
            children: [
              {
                name: 'ea-sports-fc-24-gamepoints',
                displayName: 'FC 24 POINTS',
                url: '/store?q=ea+sports+fc+24&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=ea+sports+fc+24&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1036,
                navbar: false,
              },
            ],
          },
        ],
      },
      {
        name: 'xbox',
        displayName: 'Xbox' ?? '',
        url: '/store?platform=Xbox+Live&page=1',
        queryStr: 'platform=Xbox+Live',
        id: 2,
        navbar: false,
        subDivision: [
          {
            name: game_point_msg ?? '',
            displayName: 'Game Points',
            idSub: 104,
            children: [
              {
                name: 'ea-sports-fc-24-gamepoints',
                displayName: 'FC 24 POINTS',
                url: '/store?q=ea+sports+fc+24&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=ea+sports+fc+24&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1036,
                navbar: false,
              },
            ],
          },
        ],
      },
      {
        name: 'ubisoft-connect',
        displayName: 'Ubisoft Connect' ?? '',
        url: '/store?platform=Ubisoft+Connect&page=1',
        queryStr: 'platform=Ubisoft+Connect',
        id: 2,
        navbar: false,
        subDivision: [
          {
            name: game_point_msg ?? '',
            displayName: 'Game Points',
            idSub: 104,
            children: [
              {
                name: 'ea-sports-fc-24-gamepoints',
                displayName: 'FC 24 POINTS',
                url: '/store?q=ea+sports+fc+24&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=ea+sports+fc+24&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1036,
                navbar: false,
              },
            ],
          },
        ],
      },
      {
        name: 'psn',
        displayName: 'PSN' ?? '',
        url: '/store?platform=PSN&page=1',
        queryStr: 'platform=PSN',
        id: 2,
        navbar: false,
        subDivision: [
          {
            name: game_point_msg ?? '',
            displayName: 'Game Points',
            idSub: 104,
            children: [
              {
                name: 'ea-sports-fc-24-gamepoints',
                displayName: 'FC 24 POINTS',
                url: '/store?q=ea+sports+fc+24&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=ea+sports+fc+24&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1036,
                navbar: false,
              },
            ],
          },
        ],
      },
      {
        name: 'nintendo',
        displayName: 'Nintendo' ?? '',
        url: '/store?platform=Nintendo&page=1',
        queryStr: 'platform=Nintendo',
        id: 2,
        navbar: false,
        subDivision: [
          {
            name: game_point_msg ?? '',
            displayName: 'Game Points',
            idSub: 104,
            children: [
              {
                name: 'ea-sports-fc-24-gamepoints',
                displayName: 'FC 24 POINTS',
                url: '/store?q=ea+sports+fc+24&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=ea+sports+fc+24&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1036,
                navbar: false,
              },
            ],
          },
        ],
      },
      {
        name: 'ea-play',
        displayName: 'EA Play' ?? '',
        url: '/store?platform=EA+Play&page=1',
        queryStr: 'platform=EA+Play',
        id: 2,
        navbar: false,
        subDivision: [
          {
            name: game_point_msg ?? '',
            displayName: 'Game Points',
            idSub: 104,
            children: [
              {
                name: 'ea-sports-fc-24-gamepoints',
                displayName: 'FC 24 POINTS',
                url: '/store?q=ea+sports+fc+24&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=ea+sports+fc+24&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1036,
                navbar: false,
              },
            ],
          },
        ],
      },
      {
        name: 'zalando',
        displayName: 'Zalando' ?? '',
        url: '/store?platform=Zalando&page=1',
        queryStr: 'platform=Zalando',
        id: 2,
        navbar: false,
        subDivision: [
          {
            name: game_point_msg ?? '',
            displayName: 'Game Points',
            idSub: 104,
            children: [
              {
                name: 'ea-sports-fc-24-gamepoints',
                displayName: 'FC 24 POINTS',
                url: '/store?q=ea+sports+fc+24&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=ea+sports+fc+24&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1036,
                navbar: false,
              },
            ],
          },
        ],
      },
      {
        name: 'zomato',
        displayName: 'Zomato' ?? '',
        url: '/store?platform=Zomato&page=1',
        queryStr: 'platform=Zomato',
        id: 2,
        navbar: false,
        subDivision: [
          {
            name: game_point_msg ?? '',
            displayName: 'Game Points',
            idSub: 104,
            children: [
              {
                name: 'ea-sports-fc-24-gamepoints',
                displayName: 'FC 24 POINTS',
                url: '/store?q=ea+sports+fc+24&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=ea+sports+fc+24&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1036,
                navbar: false,
              },
            ],
          },
        ],
      },
      {
        name: 'myntra',
        displayName: 'Myntra' ?? '',
        url: '/store?platform=Myntra&page=1',
        queryStr: 'platform=Myntra',
        id: 2,
        navbar: false,
        subDivision: [
          {
            name: game_point_msg ?? '',
            displayName: 'Game Points',
            idSub: 104,
            children: [
              {
                name: 'ea-sports-fc-24-gamepoints',
                displayName: 'FC 24 POINTS',
                url: '/store?q=ea+sports+fc+24&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=ea+sports+fc+24&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1036,
                navbar: false,
              },
            ],
          },
        ],
      },
      {
        name: 'makemytrip',
        displayName: 'MakeMyTrip' ?? '',
        url: '/store?platform=MakeMyTrip&page=1',
        queryStr: 'platform=MakeMyTrip',
        id: 2,
        navbar: false,
        subDivision: [
          {
            name: game_point_msg ?? '',
            displayName: 'Game Points',
            idSub: 104,
            children: [
              {
                name: 'ea-sports-fc-24-gamepoints',
                displayName: 'FC 24 POINTS',
                url: '/store?q=ea+sports+fc+24&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=ea+sports+fc+24&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1036,
                navbar: false,
              },
            ],
          },
        ],
      },
      {
        name: 'fm-redeem',
        displayName: 'FM Redemm' ?? '',
        url: '/store?platform=FM+Redeem&page=1',
        queryStr: 'platform=FM+Redeem',
        id: 2,
        navbar: false,
        subDivision: [
          {
            name: game_point_msg ?? '',
            displayName: 'Game Points',
            idSub: 104,
            children: [
              {
                name: 'ea-sports-fc-24-gamepoints',
                displayName: 'FC 24 POINTS',
                url: '/store?q=ea+sports+fc+24&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=ea+sports+fc+24&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1036,
                navbar: false,
              },
            ],
          },
        ],
      },
      {
        name: 'roblox',
        displayName: 'Roblox' ?? '',
        url: '/store?platform=Roblox&page=1',
        queryStr: 'platform=Roblox',
        id: 2,
        navbar: false,
        subDivision: [
          {
            name: game_point_msg ?? '',
            displayName: 'Game Points',
            idSub: 104,
            children: [
              {
                name: 'ea-sports-fc-24-gamepoints',
                displayName: 'FC 24 POINTS',
                url: '/store?q=ea+sports+fc+24&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=ea+sports+fc+24&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1036,
                navbar: false,
              },
            ],
          },
        ],
      },
      {
        name: 'swiggy',
        displayName: 'Swiggy' ?? '',
        url: '/store?platform=Swiggy&page=1',
        queryStr: 'platform=Swiggy',
        id: 2,
        navbar: false,
        subDivision: [
          {
            name: game_point_msg ?? '',
            displayName: 'Game Points',
            idSub: 104,
            children: [
              {
                name: 'ea-sports-fc-24-gamepoints',
                displayName: 'FC 24 POINTS',
                url: '/store?q=ea+sports+fc+24&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=ea+sports+fc+24&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1036,
                navbar: false,
              },
            ],
          },
        ],
      },
      {
        name: 'nykaa',
        displayName: 'Nykaa' ?? '',
        url: '/store?platform=Nykaa&page=1',
        queryStr: 'platform=Nykaa',
        id: 2,
        navbar: false,
        subDivision: [
          {
            name: game_point_msg ?? '',
            displayName: 'Game Points',
            idSub: 104,
            children: [
              {
                name: 'ea-sports-fc-24-gamepoints',
                displayName: 'FC 24 POINTS',
                url: '/store?q=ea+sports+fc+24&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=ea+sports+fc+24&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1036,
                navbar: false,
              },
            ],
          },
        ],
      },
      {
        name: 'microsoft-store',
        displayName: 'Microsoft Store' ?? '',
        url: '/store?platform=Microsoft+Store&page=1',
        queryStr: 'platform=Microsoft+Store',
        id: 2,
        navbar: false,
        subDivision: [
          {
            name: game_point_msg ?? '',
            displayName: 'Game Points',
            idSub: 104,
            children: [
              {
                name: 'ea-sports-fc-24-gamepoints',
                displayName: 'FC 24 POINTS',
                url: '/store?q=ea+sports+fc+24&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=ea+sports+fc+24&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1036,
                navbar: false,
              },
            ],
          },
        ],
      },
      // -------------------------------------------------------------------------------
      {
        name: 'games',
        displayName: game_msg ?? '',
        url: '/store?productType[]=game&page=1',
        queryStr: 'productType=game',
        id: 2,
        navbar: true,
        subDivision: [
          {
            name: platform_msg ?? '',
            displayName: 'Gaming Platforms',
            idSub: 101,
            children: [
              {
                name: 'steam-games',
                displayName: 'Steam',
                url: '/store?productType[]=game&page=1&platform[]=Steam&worksIn=false',
                queryStr: 'productType=game&platform=Steam&worksIn=false',
                idSubChild: 1001,
                navbar: true,
              },
              {
                name: 'ea-play-games',
                displayName: ea_play_msg ?? '',
                url: '/store?productType[]=game&page=1&platform[]=EA+Play&worksIn=false',
                queryStr: 'productType=game&platform=EA+Play&worksIn=false',
                idSubChild: 1003,
                navbar: true,
              },
              {
                name: 'xbox-games',
                displayName: 'Xbox',
                url: '/store?productType[]=game&page=1&platform[]=Xbox+Live&worksIn=false',
                queryStr: 'productType=game&platform=Xbox+Live&worksIn=false',
                idSubChild: 1005,
                navbar: true,
              },
              {
                name: 'epic-games',
                displayName: epic_games_msg ?? '',
                url: '/store?productType[]=game&page=1&platform[]=Epic+Games&worksIn=false',
                queryStr: 'productType=game&platform=Epic+Games&worksIn=false',
                idSubChild: 1010,
                navbar: true,
              },
              {
                name: 'nintendo-games',
                displayName: nintendo_msg ?? '',
                url: '/store?productType[]=game&page=1&platform[]=Nintendo&worksIn=false',
                queryStr: 'productType=game&platform=Nintendo&worksIn=false',
                idSubChild: 1008,
                navbar: true,
              },
              {
                name: 'psn-games',
                displayName: 'PSN Games' ?? '',
                url: '/store?productType[]=game&page=1&platform[]=PSN&worksIn=false',
                queryStr: 'productType=game&platform=PSN&worksIn=false',
                idSubChild: 1009,
                navbar: true,
              },
              {
                name: 'gog-games',
                displayName: gog_com_msg ?? '',
                url: '/store?productType[]=game&page=1&platform[]=GOG+COM&worksIn=false',
                queryStr: 'productType=game&platform=GOG+COM&worksIn=false',
                idSubChild: 1007,
                navbar: true,
              },
              {
                name: 'ubisoft-connect-games',
                displayName: ubisoft_connect_games_msg ?? '',
                url: '/store?productType[]=game&page=1&platform[]=Ubisoft+Connect&worksIn=false',
                queryStr:
                  'productType=game&platform=Ubisoft+Connect&worksIn=false',
                idSubChild: 1004,
                navbar: true,
              },
              {
                name: 'rockstar-games',
                displayName: 'Rockstar',
                url: '/store?productType[]=game&page=1&platform[]=Rockstar+Games&worksIn=false',
                queryStr:
                  'productType=game&platform=Rockstar+Games&worksIn=false',
                idSubChild: 1002,
                navbar: true,
              },
              {
                name: 'games',
                displayName: view_all_msg ?? '',
                url: '/store?productType=game&page=1&worksIn=false',
                queryStr: 'productType=game&worksIn=false',
                idSubChild: 1011,
                navbar: true,
                viewAll: true,
              },
            ],
          },
          {
            name: genre_msg ?? '',
            displayName: 'Popular Genres',
            idSub: 102,
            children: [
              {
                name: 'action-games',
                displayName: action_msg ?? '',
                url: '/store?genre[]=Action&page=1&worksIn=false',
                queryStr: 'productType=game&genre=Action&worksIn=false',
                idSubChild: 1015,
                navbar: true,
              },
              {
                name: 'adventure-games',
                displayName: adventure_msg ?? '',
                url: '/store?page=1&genre[]=Adventure&worksIn=false',
                queryStr: 'productType=game&genre=Adventure&worksIn=false',
                idSubChild: 1016,
                navbar: true,
              },
              {
                name: 'casual-games',
                displayName: 'Casual' ?? '',
                url: '/store?page=1&genre[]=Casual&worksIn=false',
                queryStr: 'productType=game&genre=Casual&worksIn=false',
                idSubChild: 1017,
                navbar: true,
              },
              {
                name: 'indie-games',
                displayName: indie_msg ?? '',
                url: '/store?productType[]=game&page=1&genre[]=Indie&worksIn=false',
                queryStr: 'productType=game&genre=Indie&worksIn=false',
                idSubChild: 1018,
                navbar: true,
              },
              {
                name: 'racing-games',
                displayName: 'Racing' ?? '',
                url: '/store?productType[]=game&page=1&genre[]=Racing&worksIn=false',
                queryStr: 'productType=game&genre=Racing&worksIn=false',
                idSubChild: 1018,
                navbar: true,
              },
              {
                name: 'rpg-games',
                displayName: rpg_msg ?? '',
                url: '/store?page=1&genre[]=RPG&worksIn=false',
                queryStr: 'productType=game&genre=RPG&worksIn=false',
                idSubChild: 1017,
                navbar: true,
              },
              {
                name: 'simulation-games',
                displayName: simulation_msg ?? '',
                url: '/store?productType[]=game&page=1&genre[]=Simulation&worksIn=false',
                queryStr: 'productType=game&genre=Simulation&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'sports-games',
                displayName: sports_msg ?? '',
                url: '/store?productType[]=game&page=1&genre[]=Sports&worksIn=false',
                queryStr: 'productType=game&genre=Sports&worksIn=false',
                idSubChild: 1022,
                navbar: true,
              },

              {
                name: 'strategy-games',
                displayName: strategy_msg ?? '',
                url: '/store?productType[]=game&page=1&genre[]=Strategy&worksIn=false',
                queryStr: 'productType=game&genre=Strategy&worksIn=false',
                idSubChild: 1019,
                navbar: true,
              },
              {
                name: 'tps-games',
                displayName: tps_msg ?? '',
                url: '/store?productType[]=game&page=1&genre[]=TPS&worksIn=false',
                queryStr: 'productType=game&genre=TPS&worksIn=false',
                idSubChild: 1020,
                navbar: true,
              },

              {
                name: 'multiplayer-games',
                displayName: 'Massively Multiplayer',
                url: '/store?productType[]=game&page=1&genre[]=Massively+Multiplayer&worksIn=false',
                queryStr:
                  'productType=game&genre=Massively+Multiplayer&worksIn=false',
                idSubChild: 1023,
                navbar: true,
              },
              // {
              //   name: 'fighting-games',
              //   displayName: 'Fighting',
              //   url: '/store?productType[]=game&page=1&genre[]=Fighting',
              //   queryStr: 'productType=game&genre=Fighting',
              //   idSubChild: 1024,
              // },
              {
                name: 'fps-games',
                displayName: 'FPS',
                url: '/store?page=1&genre[]=FPS&productType=game&worksIn=false',
                queryStr: 'genre=FPS&productType=game&worksIn=false',
                idSubChild: 1025,
                navbar: true,
              },

              {
                name: 'hack-and-slash-games',
                displayName: 'Hack & Slash',
                url: '/store?productType[]=game&page=1&genre[]=Hack+and+Slash&worksIn=false',
                queryStr: 'productType=game&genre=Hack+and+Slash&worksIn=false',
                idSubChild: 1026,
                navbar: true,
              },
              {
                name: 'games',
                displayName: view_all_msg ?? '',
                url: '/store?productType=game&page=1&worksIn=false',
                queryStr: 'productType=game&worksIn=false',
                idSubChild: 1024,
                navbar: true,
                viewAll: true,
              },
              // {
              //   name: 'mmo-games',
              //   displayName: 'MMO',
              //   url: '/store?productType[]=game&page=1&genre[]=MMO',
              //   queryStr: 'productType=game&genre=MMO',
              //   idSubChild: 1027,
              // },
              // {
              //   name: 'music-games',
              //   displayName: 'Music/Soundtrack',
              //   url: '/store?productType[]=game&page=1&genre[]=Music+/+Soundtrack',
              //   queryStr: 'productType=game&genre=Music+/+Soundtrack',
              //   idSubChild: 1028,
              // },
              // {
              //   name: 'platformer-games',
              //   displayName: 'Platformer',
              //   url: '/store?productType[]=game&page=1&genre[]=Platformer',
              //   queryStr: 'productType=game&genre=Platformer',
              //   idSubChild: 1029,
              // },
              // {
              //   name: 'point-and-click-games',
              //   displayName: 'Point & Click',
              //   url: '/store?productType[]=game&page=1&genre[]=Point+&+click',
              //   queryStr: 'productType=game&genre=Point+&+click',
              //   idSubChild: 1030,
              // },
              // {
              //   name: 'puzzle-games',
              //   displayName: 'Puzzle',
              //   url: '/store?genre[]=Puzzle&productType[]=game&page=1',
              //   queryStr: 'genre=Puzzle&productType=game',
              //   idSubChild: 1031,
              // },
              // {
              //   name: 'racing-games',
              //   displayName: 'Racing',
              //   url: '/store?productType[]=game&page=1&genre[]=Racing',
              //   queryStr: 'productType=game&genre=Racing',
              //   idSubChild: 1032,
              // },
            ],
          },
          // {
          //   name: genre_msg ?? '',
          //   displayName: 'Gaming Gift Cards',
          //   idSub: 102,
          //   children: [
          //     {
          //       name: 'psn-gift-cards',
          //       displayName: 'PSN Gift Card' ?? '',
          //       url: '/store?genre[]=Action&page=1&worksIn=false',
          //       queryStr: 'productType=giftcard&platform=PSN&worksIn=false',
          //       idSubChild: 1015,
          //       navbar: true,
          //     },
          //     {
          //       name: 'steam-gift-cards',
          //       displayName: 'Steam Wallet' ?? '',
          //       url: '/store?page=1&genre[]=Adventure&worksIn=false',
          //       queryStr: 'productType=giftcard&platform=Steam&worksIn=false',
          //       idSubChild: 1016,
          //       navbar: true,
          //     },
          //     {
          //       name: 'xbox-gift-cards',
          //       displayName: 'Xbox Gift Card' ?? '',
          //       url: '/store?page=1&genre[]=RPG&worksIn=false',
          //       queryStr:
          //         'productType=giftcard&platform=Xbox+Live&worksIn=false',
          //       idSubChild: 1017,
          //       navbar: true,
          //     },

          //     {
          //       name: 'roblox-gift-cards',
          //       displayName: 'Roblox Gift Card' ?? '',
          //       url: '/store?productType[]=game&page=1&genre[]=Indie&worksIn=false',
          //       queryStr: 'productType=giftcard&platform=Roblox&worksIn=false',
          //       idSubChild: 1018,
          //       navbar: true,
          //     },

          //     {
          //       name: 'nintendo-gift-cards',
          //       displayName: 'Nintendo eShop Gift Card' ?? '',
          //       url: '/store?productType[]=game&page=1&genre[]=Strategy&worksIn=false',
          //       queryStr:
          //         'productType=giftcard&platform=Nintendo&worksIn=false',
          //       idSubChild: 1019,
          //       navbar: true,
          //     },
          //     {
          //       name: 'valorant-gift-cards',
          //       displayName: 'Valorant Gift Card' ?? '',
          //       url: '/store?productType[]=giftcard&page=1&q=valorant&worksIn=false',
          //       queryStr: 'productType=giftcard&q=valorant&worksIn=false',
          //       idSubChild: 1020,
          //       navbar: true,
          //     },
          //     {
          //       name: 'meta-quest-gift-cards',
          //       displayName: 'Meta Quest' ?? '',
          //       url: '/store?productType[]=giftcard&page=1&q=meta+quest&worksIn=false',
          //       queryStr: 'productType=giftcard&q=meta+quest&worksIn=false',
          //       idSubChild: 1021,
          //       navbar: true,
          //     },
          //     {
          //       name: 'world-of-warcraft-gift-cards',
          //       displayName: 'World of Warcraft Gift Card' ?? '',
          //       url: '/store?productType[]=giftcard&page=1&q=world+of+warcraft&worksIn=false',
          //       queryStr:
          //         'productType=giftcard&q=world+of+warcraft&worksIn=false',
          //       idSubChild: 1022,
          //       navbar: true,
          //     },

          //     {
          //       name: 'blizzard-gift-cards',
          //       displayName: 'Blizzard Gift Card',
          //       url: '/store?productType=giftcard&q=blizzard&worksIn=false',
          //       queryStr: 'productType=giftcard&q=blizzard&worksIn=false',
          //       idSubChild: 1023,
          //       navbar: true,
          //     },
          //     {
          //       name: 'league-of-legends-gift-cards',
          //       displayName: 'League of Legends Gift Card' ?? '',
          //       url: '/store?productType=giftcard&q=league+of+legends&worksIn=false',
          //       queryStr:
          //         'productType=giftcard&q=league+of+legends&worksIn=false',
          //       idSubChild: 1024,
          //       navbar: true,
          //     },

          //     // {
          //     //   name: 'fighting-games',
          //     //   displayName: 'Fighting',
          //     //   url: '/store?productType[]=game&page=1&genre[]=Fighting',
          //     //   queryStr: 'productType=game&genre=Fighting',
          //     //   idSubChild: 1024,
          //     // },
          //     {
          //       name: 'gamestop-gift-cards',
          //       displayName: 'GameStop Gift Card',
          //       url: '/store?productType=giftcard&platform=GameStop&worksIn=false',
          //       queryStr:
          //         'productType=giftcard&platform=GameStop&worksIn=false',
          //       idSubChild: 1025,
          //       navbar: true,
          //     },
          //   ],
          // },
          {
            name: game_point_msg ?? '',
            displayName: 'Game Points',
            idSub: 104,
            children: [
              {
                name: 'ea-sports-fc-24-gamepoints',
                displayName: 'FC 24 POINTS',
                url: '/store?q=ea+sports+fc+24&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=ea+sports+fc+24&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1036,
                navbar: true,
              },
              {
                name: 'pubg-mobile',
                displayName: 'PUBG Mobile UC' ?? '',
                url: '/store?q=pubg+mobile&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=pubg+mobile&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1033,
                navbar: true,
              },

              {
                name: 'garena-free-fire-diamonds',
                displayName: 'Gareena Free Fire Diamonds' ?? '',
                url: '/store?q=gareena+free+fire+diamonds&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=gareena+free+fire+diamonds&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1034,
                navbar: true,
              },
              {
                name: 'fortnite-gamepoints',
                displayName: 'Fortnite V-Bucks',
                url: '/store?q=fortnite&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=fortnite&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1035,
                navbar: true,
              },
              {
                name: 'minecraft-gamepoints',
                displayName: 'Minecraft: Minecoins Pack' ?? '',
                url: '/store?q=minecraft&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=minecraft&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1037,
                navbar: true,
              },
              // {
              //   name: 'fifa-fut-points',
              //   displayName: 'FIFA FUT Points',
              //   url: '/store?q=fifa+fut+points&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=fifa+fut+points&productType%5B%5D=game+point',
              //   idSubChild: 1039,
              // },
              // {
              //   name: 'pubg-new-state-nc-gamepoints',
              //   displayName: pubg_new_state_nc_msg ?? '',
              //   url: '/store?q=pubg+new+state+nc&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=pubg+new+state+nc&productType%5B%5D=game+point',
              //   idSubChild: 1037,
              //   navbar: true,
              // },
              // {
              //   name: 'gta-online-cash',
              //   displayName: 'GTA Online Cash',
              //   url: '/store?q=gta+online+cash&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=gta+online+cash&productType%5B%5D=game+point',
              //   idSubChild: 1041,
              // },
              {
                name: 'gta-online-gamepoints',
                displayName: 'GTA Cards' ?? '',
                url: '/store?q=grand+theft+auto+online&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=grand+theft+auto+online&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1038,
                navbar: true,
              },
              {
                name: 'valorant-gamepoints',
                displayName: 'Valorant Points' ?? '',
                url: '/store?q=valorant&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=valorant&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1038,
                navbar: true,
              },
              {
                name: 'mobile-legends-gamepoints',
                displayName: 'Mobile Legends' ?? '',
                url: '/store?q=mobile+legends&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=mobile+legends&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1038,
                navbar: true,
              },
              {
                name: 'overwatch-gamepoints',
                displayName: 'Overwatch Coins' ?? '',
                url: '/store?q=overwatch&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=overwatch&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1038,
                navbar: true,
              },
              // {
              //   name: 'pubg-mobile-uc',
              //   displayName: 'PUBG Mobile UC',
              //   url: '/store?q=pubg+mobile+uc&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=pubg+mobile+uc&productType%5B%5D=game+point',
              //   idSubChild: 1042,
              // },
              // {
              //   name: 'riot-points',
              //   displayName: riot_points_msg ?? '',
              //   url: '/store?q=riot+points&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=riot+points&productType%5B%5D=game+point',
              //   idSubChild: 1039,
              //   navbar: true,
              // },
              // {
              //   name: 'apex-legends-gamepoints',
              //   displayName: 'Apex Legends',
              //   url: '/store?q=apex+legends&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=apex+legends&productType%5B%5D=game+point',
              //   idSubChild: 1040,
              //   navbar: true,
              // },
              {
                name: 'gamepoints',
                displayName: view_all_msg ?? '',
                url: '/store?productType=game+point&page=1&worksIn=false',
                queryStr: 'productType=game+point&worksIn=false',
                idSubChild: 1041,
                navbar: true,
                viewAll: true,
              },
              // {
              //   name: 'fifa-23-gamepoints',
              //   displayName: 'FIFA 23',
              //   url: '/store?q=fifa+23&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=fifa+23&productType%5B%5D=game+point',
              //   idSubChild: 1052,
              // },
              // {
              //   name: 'valorant-gamepoints',
              //   displayName: 'Valorant',
              //   url: '/store?q=valorant&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=valorant&productType%5B%5D=game+point',
              //   idSubChild: 1053,
              // },
              // {
              //   name: 'mobile-legends-gamepoints',
              //   displayName: 'Mobile Legends',
              //   url: '/store?q=mobile+legends&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=mobile+legends&productType%5B%5D=game+point',
              //   idSubChild: 1054,
              // },
              // {
              //   name: 'minecraft-gamepoints',
              //   displayName: 'Minecraft',
              //   url: '/store?q=minecraft&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=minecraft&productType%5B%5D=game+point',
              //   idSubChild: 1055,
              // },
              // {
              //   name: 'league-of-legends-gamepoints',
              //   displayName: 'League Of Legends',
              //   url: '/store?q=league+of+legends&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=league+of+legends&productType%5B%5D=game+point',
              //   idSubChild: 1056,
              // },
            ],
          },
          {
            name: game_point_msg ?? '',
            displayName: 'Subscriptions',
            idSub: 104,
            children: [
              {
                name: 'xbox-subscriptions',
                displayName: 'Xbox Live' ?? '',
                url: '/store?platform=Xbox+Live&productType%5B%5D=subscription&page=1&worksIn=false',
                queryStr:
                  'platform=Xbox+Live&productType%5B%5D=subscription&worksIn=false',
                idSubChild: 1033,
                navbar: true,
              },

              {
                name: 'nintendo-subscriptions',
                displayName: 'Nintendo' ?? '',
                url: '/store?platform=nintendo&productType%5B%5D=subscription&page=1&worksIn=false',
                queryStr:
                  'platform=nintendo&productType%5B%5D=subscription&worksIn=false',
                idSubChild: 1034,
                navbar: true,
              },
              {
                name: 'psn-subscriptions',
                displayName: 'PSN',
                url: '/store?platform=psn&productType%5B%5D=subscription&page=1&worksIn=false',
                queryStr:
                  'platform=psn&productType%5B%5D=subscription&worksIn=false',
                idSubChild: 1035,
                navbar: true,
              },
              {
                name: 'ubisoft-connect-subscriptions',
                displayName: 'Ubisoft Connect',
                url: '/store?platform=Ubisoft+Connect&productType%5B%5D=subscription&page=1&worksIn=false',
                queryStr:
                  'platform=Ubisoft+Connect&productType%5B%5D=subscription&worksIn=false',
                idSubChild: 1036,
                navbar: true,
              },
              {
                name: 'ea-play-subscriptions',
                displayName: 'EA Play' ?? '',
                url: '/store?platform=EA+Play&productType%5B%5D=subscription&page=1&worksIn=false',
                queryStr:
                  'platform=EA+Play&productType%5B%5D=subscription&worksIn=false',
                idSubChild: 1037,
                navbar: true,
              },
              // {
              //   name: 'final-fantasy-subscriptions',
              //   displayName: 'Final Fantasy XIV' ?? '',
              //   url: '/store?q=grand+theft+auto+online&productType%5B%5D=subscription&page=1',
              //   queryStr:
              //     'q=grand+theft+auto+online&productType%5B%5D=subscription',
              //   idSubChild: 1038,
              //   navbar: true,
              // },
              // {
              //   name: 'riot-points',
              //   displayName: riot_points_msg ?? '',
              //   url: '/store?q=riot+points&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=riot+points&productType%5B%5D=game+point',
              //   idSubChild: 1039,
              //   navbar: true,
              // },
              // {
              //   name: 'apex-legends-gamepoints',
              //   displayName: 'Apex Legends',
              //   url: '/store?q=apex+legends&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=apex+legends&productType%5B%5D=game+point',
              //   idSubChild: 1040,
              //   navbar: true,
              // },
              {
                name: 'subscriptions',
                displayName: view_all_msg ?? '',
                url: '/store?productType=subscription&page=1&worksIn=false',
                queryStr: 'productType=subscription&worksIn=false',
                idSubChild: 1041,
                viewAll: true,
                navbar: true,
              },
            ],
          },
          {
            name: dlcs_msg ?? '',
            displayName: 'DLCs',
            idSub: 103,
            children: [
              {
                name: 'call-of-duty-dlc',
                displayName: 'Call of Duty',
                url: '/store?q=call+of+duty&productType%5B%5D=dlc&page=1&worksIn=false',
                queryStr: 'q=call+of+duty&productType%5B%5D=dlc&worksIn=false',
                idSubChild: 10301,
                navbar: true,
              },
              {
                name: 'fortnite-dlc',
                displayName: 'Fortnite',
                url: '/store?q=fortnite&productType%5B%5D=dlc&page=1&worksIn=false',
                queryStr: 'q=fortnite&productType%5B%5D=dlc&worksIn=false',
                idSubChild: 10302,
                navbar: true,
              },
              {
                name: 'the-sims-dlc',
                displayName: 'The Sims',
                url: '/store?q=the+sims&productType%5B%5D=dlc&page=1&worksIn=false',
                queryStr: 'q=the+sims&productType%5B%5D=dlc&worksIn=false',
                idSubChild: 10303,
                navbar: true,
              },
              {
                name: 'destiny-2-dlc',
                displayName: 'Destiny 2',
                url: '/store?q=destiny+2&productType%5B%5D=dlc&page=1&worksIn=false',
                queryStr: 'q=destiny+2&productType%5B%5D=dlc&worksIn=false',
                idSubChild: 10304,
                navbar: true,
              },
              {
                name: 'monster-hunter-dlc',
                displayName: 'Monster Hunter',
                url: '/store?q=monster+hunter&productType%5B%5D=dlc&page=1&worksIn=false',
                queryStr:
                  'q=monster+hunter&productType%5B%5D=dlc&worksIn=false',
                idSubChild: 10305,
                navbar: true,
              },
              {
                name: 'house-flipper',
                displayName: 'House Flipper',
                url: '/store?q=house+flipper&productType%5B%5D=dlc&page=1&worksIn=false',
                queryStr: 'q=house+flipper&productType%5B%5D=dlc&worksIn=false',
                idSubChild: 10306,
                navbar: true,
              },
              {
                name: 'planet-zoo-dlc',
                displayName: 'Planet Zoo',
                url: '/store?q=planet+zoo&productType%5B%5D=dlc&page=1&worksIn=false',
                queryStr: 'q=planet+zoo&productType%5B%5D=dlc&worksIn=false',
                idSubChild: 10307,
                navbar: true,
              },
              {
                name: 'age-of-empires-dlc',
                displayName: 'Age of Empires',
                url: '/store?q=age+of+empires&productType%5B%5D=dlc&page=1&worksIn=false',
                queryStr:
                  'q=age+of+empires&productType%5B%5D=dlc&worksIn=false',
                idSubChild: 10308,
                navbar: true,
              },
              {
                name: 'dlc',
                displayName: view_all_msg ?? '',
                url: '/store?productType=dlc&page=1&worksIn=false',
                queryStr: 'productType=dlc&worksIn=false',
                idSubChild: 10309,
                navbar: true,
                viewAll: true,
              },
              // {
              //   name: 'warhammer-dlc',
              //   displayName: 'Warhammer',
              //   url: '/store?q=warhammer&productType%5B%5D=dlc&page=1',
              //   queryStr: 'q=warhammer&productType%5B%5D=dlc',
              //   idSubChild: 1041,
              // },
              // {
              //   name: 'risk-of-rain-dlc',
              //   displayName: 'Risk of rain',
              //   url: '/store?q=risk+of+rain&productType%5B%5D=dlc&page=1',
              //   queryStr: 'q=risk+of+rain&productType%5B%5D=dlc',
              //   idSubChild: 1042,
              // },
              // {
              //   name: 'the-hunter-dlc',
              //   displayName: 'The Hunter',
              //   url: '/store?q=the+hunter&productType%5B%5D=dlc&page=1',
              //   queryStr: 'q=the+hunter&productType%5B%5D=dlc',
              //   idSubChild: 1043,
              // },
            ],
          },
          // {
          //   name: 'COLLECTIONS',
          //   displayName: 'Collections',
          //   idSub: 102,
          //   children: [
          //     {
          //       name: 'action-games',
          //       displayName: 'Action',
          //       url: '/store?genre[]=Action&page=1',
          //       queryStr: 'genre=Action',
          //       idSubChild: 1015,
          //     },

          //   ],
          // },

          // {
          //   name: 'PRICE RANGE',
          //   displayName: 'Price Range',
          //   idSub: 102,
          //   children: [
          //     {
          //       name: 'action-games',
          //       displayName: 'Action',
          //       url: '/store?genre[]=Action&page=1',
          //       queryStr: 'genre=Action',
          //       idSubChild: 1015,
          //     },

          //   ],
          // }
        ],
      },
      {
        name: 'gift-cards',
        displayName: gift_card_msg ?? '',
        url: '/store?page=1&productType[]=giftcard',
        queryStr: 'productType=giftcard',
        id: 3,
        subDivision: [
          // {
          //   name: platform_msg ?? '',
          //   displayName: 'Gaming Gift Cards',
          //   idSub: 105,
          //   children: [
          //     {
          //       name: 'nintendo-gift-cards',
          //       displayName: 'Nintendo',
          //       url: '/store?productType[]=giftcard&page=1&platform[]=Ninetendo&worksIn=false',
          //       queryStr:
          //         'productType=giftcard&platform=Nintendo&worksIn=false',
          //       idSubChild: 1045,
          //       navbar: true,
          //     },
          //     {
          //       name: 'roblox-gift-cards',
          //       displayName: 'Roblox',
          //       url: '/store?productType[]=giftcard&page=1&platform[]=Roblox&worksIn=false',
          //       queryStr: 'productType=giftcard&platform=Roblox&worksIn=false',
          //       idSubChild: 1044,
          //       navbar: true,
          //     },
          //     {
          //       name: 'psn-gift-cards',
          //       displayName: 'PSN',
          //       url: '/store?productType[]=giftcard&page=1&platform[]=PSN&worksIn=false',
          //       queryStr: 'productType=giftcard&platform=PSN&worksIn=false',
          //       idSubChild: 1042,
          //       navbar: true,
          //     },
          //     // {
          //     //   name: 'steam-giftcards',
          //     //   displayName: 'Steam',
          //     //   url: '/store?productType[]=giftcard&page=1&platform[]=Steam',
          //     //   queryStr: 'productType=giftcard&platform=Steam',
          //     //   idSubChild: 1043,
          //     //   navbar: true,
          //     // },
          //     {
          //       name: 'steam-gift-cards',
          //       displayName: 'Steam',
          //       url: '/store?productType[]=giftcard&page=1&platform[]=Steam&worksIn=false',
          //       queryStr: 'productType=giftcard&platform=Steam&worksIn=false',
          //       idSubChild: 1043,
          //       navbar: true,
          //     },
          //     {
          //       name: 'google-play-cards',
          //       displayName: 'Google Play',
          //       url: '/store?productType[]=giftcard&page=1&platform[]=Google+Play&worksIn=false',
          //       queryStr:
          //         'productType=giftcard&platform=Google+Play&worksIn=false',
          //       idSubChild: 1043,
          //       navbar: true,
          //     },

          //     // {
          //     //   name: 'nintendo-giftcards',
          //     //   displayName: 'Nintendo',
          //     //   url: '/store?productType[]=giftcard&page=1&platform[]=Ninetendo',
          //     //   queryStr: 'productType=giftcard&platform=Nintendo',
          //     //   idSubChild: 1045,
          //     //   navbar: true,
          //     // },

          //     {
          //       name: 'xbox-gift-cards',
          //       displayName: 'Xbox',
          //       url: '/store?productType[]=giftcard&page=1&platform[]=Xbox+Live&worksIn=false',
          //       queryStr:
          //         'productType=giftcard&platform=Xbox+Live&worksIn=false',
          //       idSubChild: 1046,
          //       navbar: true,
          //     },
          //     // {
          //     //   name: 'blizzard-gift-cards',
          //     //   displayName: 'Blizzard',
          //     //   url: '/store?productType[]=giftcard&page=1&platform[]=Battle.net',
          //     //   queryStr: 'productType=giftcard&platform=Battle.net',
          //     //   idSubChild: 1047,
          //     //   navbar: true,
          //     // },
          //     {
          //       name: 'blizzard-gift-cards',
          //       displayName: 'Blizzard',
          //       url: '/store?productType[]=giftcard&page=1&platform[]=Battle.net&worksIn=false',
          //       queryStr:
          //         'productType=giftcard&platform=Battle.net&worksIn=false',
          //       idSubChild: 1047,
          //       navbar: true,
          //     },
          //     {
          //       name: 'ea-play-gift-cards',
          //       displayName: 'EA Play',
          //       url: '/store?productType[]=giftcard&page=1&platform[]=EA+Play&worksIn=false',
          //       queryStr: 'productType=giftcard&platform=EA+Play&worksIn=false',
          //       idSubChild: 1048,
          //       navbar: true,
          //     },
          //     {
          //       name: 'riot-access-gift-cards',
          //       displayName: 'Riot Access',
          //       url: '/store?productType%5B%5D=giftcard&page=1&platform=Riot+Access&worksIn=false',
          //       queryStr:
          //         'productType%5B%5D=giftcard&platform=Riot+Access&worksIn=false',
          //       idSubChild: 1059,
          //       navbar: true,
          //     },
          //     {
          //       name: 'tq-gift-cards',
          //       displayName: 'TQ Game Card',
          //       url: '/store?productType%5B%5D=giftcard&page=1&platform=TQ+Game+Card&worksIn=false',
          //       queryStr:
          //         'productType%5B%5D=giftcard&platform=TQ+Game+Card&worksIn=false',
          //       idSubChild: 1059,
          //       navbar: true,
          //     },
          //     {
          //       name: 'kingisle-pirate-cards',
          //       displayName: 'KingsIsle Pirate',
          //       url: '/store?productType%5B%5D=giftcard&page=1&platform=KingsIsle+Pirate&worksIn=false',
          //       queryStr:
          //         'productType%5B%5D=giftcard&platform=KingsIsle+Pirate&worksIn=false',
          //       idSubChild: 1059,
          //       navbar: true,
          //     },
          //     {
          //       name: 'kigso-gift-cards',
          //       displayName: 'Kigso',
          //       url: '/store?productType%5B%5D=giftcard&page=1&platform=Kigso&worksIn=false',
          //       queryStr:
          //         'productType%5B%5D=giftcard&platform=Kigso&worksIn=false',
          //       idSubChild: 1059,
          //       navbar: true,
          //     },
          //     // {
          //     //   name: 'razar-gold-giftcards',
          //     //   displayName: 'Razor Gold',
          //     //   url: '/store?q=razor+gold&productType%5B%5D=giftcard&page=1',
          //     //   queryStr: 'q=razor+gold&productType%5B%5D=giftcard',
          //     //   idSubChild: 1049,
          //     // },
          //     // {
          //     //   name: 'blizzard-giftcards',
          //     //   displayName: 'Blizzard',
          //     //   url: '/store?q=blizzard&productType%5B%5D=giftcard&page=1',
          //     //   queryStr: 'q=blizzard&productType%5B%5D=giftcard',
          //     //   idSubChild: 1064,
          //     // },
          //     {
          //       name: 'gift-cards',
          //       displayName: view_all_msg ?? '',
          //       url: '/store?productType=giftcard&worksIn=false',
          //       queryStr: 'productType=giftcard&worksIn=false',
          //       idSubChild: 1050,
          //       navbar: true,
          //       viewAll: true,
          //     },
          //     // {
          //     //   name: 'valorant-giftcards',
          //     //   displayName: 'Valorant',
          //     //   url: '/store?q=valorant&productType%5B%5D=giftcard&page=1',
          //     //   queryStr: 'q=valorant&productType%5B%5D=giftcard',
          //     //   idSubChild: 1065,
          //     // },
          //     // {
          //     //   name: 'world-of-warcraft-giftcards',
          //     //   displayName: 'World of Warcraft',
          //     //   url: '/store?q=world+of+warcraft&productType%5B%5D=giftcard&page=1',
          //     //   queryStr: 'q=world+of+warcraft&productType%5B%5D=giftcard',
          //     //   idSubChild: 1066,
          //     // },

          //     // {
          //     //   name: 'diablo-giftcards',
          //     //   displayName: 'Diablo IV',
          //     //   url: '/store?q=diablo+iv&productType%5B%5D=giftcard&page=1',
          //     //   queryStr: 'q=diablo+iv&productType%5B%5D=giftcard',
          //     //   idSubChild: 1068,
          //     // },
          //     // {
          //     //   name: 'go-cash-giftcards',
          //     //   displayName: 'Go Cash Game Cards',
          //     //   url: '/store?q=go+cash+game+card&productType%5B%5D=giftcard&page=1',
          //     //   queryStr: 'q=go+cash+game+card&productType%5B%5D=giftcard',
          //     //   idSubChild: 1069,
          //     // },
          //     // {
          //     //   name: 'other-cards',
          //     //   displayName: 'Other Cards',
          //     //   url: '/store?productType[]=giftcard&page=1&platform[]=Other',
          //     //   queryStr: 'productType=giftcard&platform=Other',
          //     //   idSubChild: 1051,
          //     // },
          //   ],
          // },
          {
            name: lifestyle_cards_msg ?? '',
            displayName: 'Entertainment',
            idSub: 106,
            children: [
              // {
              //   name: 'binance-gift-cards',
              //   displayName: 'Binance',
              //   url: '/store?q=binance&productType%5B%5D=giftcard&page=1',
              //   queryStr: 'platform=Binance&productType%5B%5D=giftcard',
              //   idSubChild: 1051,
              //   navbar: true,
              // },
              {
                name: 'netflix-gift-cards',
                displayName: 'Netflix',
                url: '/store?q=netflix&productType%5B%5D=giftcard&page=1&worksIn=false',
                queryStr:
                  'platform=Netflix&productType%5B%5D=giftcard&worksIn=false',
                idSubChild: 1052,
                navbar: true,
              },
              {
                name: 'twitch-gift-cards',
                displayName: 'Twitch',
                url: '/store?platform=Twitch&productType%5B%5D=giftcard&page=1&worksIn=false',
                queryStr:
                  'platform=Twitch&productType%5B%5D=giftcard&worksIn=false',
                idSubChild: 1052,
                navbar: true,
              },
              {
                name: 'meta-quest-gift-cards',
                displayName: 'Meta Quest Gift Card' ?? '',
                url: '/store?productType[]=giftcard&page=1&genre[]=Simulation&worksIn=false',
                queryStr: 'productType=giftcard&genre=Simulation&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'sky-wow-gift-cards',
                displayName: 'Sky WOW ' ?? '',
                url: '/store?productType=giftcard&platform=Sky+WOW&worksIn=false',
                queryStr: 'productType=giftcard&platform=Sky+WOW&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'rtl-tv-now-gift-cards',
                displayName: 'RTL TV Now ' ?? '',
                url: '/store?productType=giftcard&platform=RTL+TV+Now&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=RTL+TV+Now&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'game-world-gift-cards',
                displayName: 'Game World ' ?? '',
                url: '/store?productType=giftcard&platform=Game+World&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Game+World&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'thalia-gift-cards',
                displayName: 'Thalia ' ?? '',
                url: '/store?productType=giftcard&platform=Thalia&worksIn=false',
                queryStr: 'productType=giftcard&platform=Thalia&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'jb-hi-fi-gift-cards',
                displayName: 'JB HI-FI ' ?? '',
                url: '/store?productType=giftcard&platform=JB+HI-FI&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=JB+HI-FI&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'imvu-gift-cards',
                displayName: 'IMVU ' ?? '',
                url: '/store?productType=giftcard&platform=IMVU&worksIn=false',
                queryStr: 'productType=giftcard&platform=IMVU&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'rakuten-kobo-gift-cards',
                displayName: 'Rakuten Kobo ' ?? '',
                url: '/store?productType=giftcard&platform=Rakuten+Kobo&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Rakuten+Kobo&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'levelup-gift-cards',
                displayName: 'LevelUp' ?? '',
                url: '/store?productType=giftcard&platform=LevelUp&worksIn=false',
                queryStr: 'productType=giftcard&platform=LevelUp&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'unipin-gift-cards',
                displayName: 'UniPin' ?? '',
                url: '/store?productType=giftcard&platform=UniPin&worksIn=false',
                queryStr: 'productType=giftcard&platform=UniPin&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'pvr-cinemas-gift-cards',
                displayName: 'PVR Cinemas' ?? '',
                url: '/store?productType=giftcard&platform=PVR+Cinemas&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=PVR+Cinemas&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'book-my-show-gift-cards',
                displayName: 'BookMyShow' ?? '',
                url: '/store?productType=giftcard&platform=BookMyShow&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=BookMyShow&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'zee5-gift-cards',
                displayName: 'Zee5' ?? '',
                url: '/store?productType=giftcard&platform=Zee5&worksIn=false',
                queryStr: 'productType=giftcard&platform=Zee5&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'empik-gift-cards',
                displayName: 'Empik' ?? '',
                url: '/store?productType=giftcard&platform=Empik&worksIn=false',
                queryStr: 'productType=giftcard&platform=Empik&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'ticketmaster-gift-cards',
                displayName: 'Ticketmaster' ?? '',
                url: '/store?productType=giftcard&platform=Ticketmaster&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Ticketmaster&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'eventim-gift-cards',
                displayName: 'Eventim' ?? '',
                url: '/store?productType=giftcard&platform=Eventim&worksIn=false',
                queryStr: 'productType=giftcard&platform=Eventim&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'amc-theatres-gift-cards',
                displayName: 'AMC Theatres' ?? '',
                url: '/store?productType=giftcard&platform=AMC+Theatres&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=AMC+Theatres&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'barnes-noble-gift-cards',
                displayName: 'Barnes & Noble' ?? '',
                url: '/store?productType=giftcard&platform=Barnes+&+Noble&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Barnes+&+Noble&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'vudu-gift-cards',
                displayName: 'Vudu' ?? '',
                url: '/store?productType=giftcard&platform=Vudu&worksIn=false',
                queryStr: 'productType=giftcard&platform=Vudu&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'topgolf-gift-cards',
                displayName: 'TopGolf' ?? '',
                url: '/store?productType=giftcard&platform=TopGolf&worksIn=false',
                queryStr: 'productType=giftcard&platform=TopGolf&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'stubhub-gift-cards',
                displayName: 'StubHub' ?? '',
                url: '/store?productType=giftcard&platform=StubHub&worksIn=false',
                queryStr: 'productType=giftcard&platform=StubHub&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'ifly-gift-cards',
                displayName: 'iFLY' ?? '',
                url: '/store?productType=giftcard&platform=iFLY&worksIn=false',
                queryStr: 'productType=giftcard&platform=iFLY&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'cinemark-gift-cards',
                displayName: 'Cinemark' ?? '',
                url: '/store?productType=giftcard&platform=Cinemark&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Cinemark&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'gamestop-gift-cards',
                displayName: 'GameStop' ?? '',
                url: '/store?productType=giftcard&platform=GameStop&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=GameStop&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'crutchfield-gift-cards',
                displayName: 'Crutchfield' ?? '',
                url: '/store?productType=giftcard&platform=Crutchfield&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Crutchfield&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'fandango-gift-cards',
                displayName: 'Fandango' ?? '',
                url: '/store?productType=giftcard&platform=Fandango&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Fandango&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'tidal-gift-cards',
                displayName: 'TIDAL' ?? '',
                url: '/store?productType=giftcard&platform=TIDAL&worksIn=false',
                queryStr: 'productType=giftcard&platform=TIDAL&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'hulu-gift-cards',
                displayName: 'Hulu' ?? '',
                url: '/store?productType=giftcard&platform=Hulu&worksIn=false',
                queryStr: 'productType=giftcard&platform=Hulu&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'kingsisle-wizard-gift-cards',
                displayName: 'KingsIsle Wizard' ?? '',
                url: '/store?productType=giftcard&platform=KingsIsle+Wizard&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=KingsIsle+Wizard&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'atom-tickets-gift-cards',
                displayName: 'Atom Tickets' ?? '',
                url: '/store?productType=giftcard&platform=Atom+Tickets&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Atom+Tickets&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'hmvod-gift-cards',
                displayName: 'Hmvod' ?? '',
                url: '/store?productType=giftcard&platform=Hmvod&worksIn=false',
                queryStr: 'productType=giftcard&platform=Hmvod&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },
              {
                name: 'culture-land-gift-cards',
                displayName: 'Culture Land' ?? '',
                url: '/store?productType=giftcard&platform=Culture+Land&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Culture+Land&worksIn=false',
                idSubChild: 1021,
                navbar: true,
              },

              {
                name: 'one-store-gift-cards',
                displayName: 'One Store',
                url: '/store?productType=giftcard&platform=One+Store&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=One+Store&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'cineplex-gift-cards',
                displayName: 'Cineplex',
                url: '/store?productType=giftcard&platform=Cineplex&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Cineplex&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'tivoli-gift-cards',
                displayName: 'Tivoli',
                url: '/store?productType=giftcard&platform=Tivoli&worksIn=false',
                queryStr: 'productType=giftcard&platform=Tivoli&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'bigo-live-gift-cards',
                displayName: 'Bigo Live',
                url: '/store?productType=giftcard&platform=Bigo+Live&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Bigo+Live&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'liveme-gift-cards',
                displayName: 'LiveME',
                url: '/store?productType=giftcard&platform=LiveME&worksIn=false',
                queryStr: 'productType=giftcard&platform=LiveME&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'hello-yo-gift-cards',
                displayName: 'Hello Yo',
                url: '/store?productType=giftcard&platform=Hello+Yo&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Hello+Yo&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'mixup-gift-cards',
                displayName: 'Mixup',
                url: '/store?productType=giftcard&platform=Mixup&worksIn=false',
                queryStr: 'productType=giftcard&platform=Mixup&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'reel-cinema-gift-cards',
                displayName: 'Reel Cinema',
                url: '/store?productType=giftcard&platform=Reel+Cinema&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Reel+Cinema&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'cultura-gift-cards',
                displayName: 'Cultura',
                url: '/store?productType=giftcard&platform=Cultura&worksIn=false',
                queryStr: 'productType=giftcard&platform=Cultura&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'la-feltrinelli-gift-cards',
                displayName: 'La Feltrinelli',
                url: '/store?productType=giftcard&platform=La+Feltrinelli&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=La+Feltrinelli&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'toys-center-gift-cards',
                displayName: 'TOYS CENTER',
                url: '/store?productType=giftcard&platform=TOYS+CENTER&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=TOYS+CENTER&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'ticket-gift-cards',
                displayName: 'Ticket',
                url: '/store?productType=giftcard&platform=Ticket&worksIn=false',
                queryStr: 'productType=giftcard&platform=Ticket&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'odeon-gift-cards',
                displayName: 'Odeon',
                url: '/store?productType=giftcard&platform=Odeon&worksIn=false',
                queryStr: 'productType=giftcard&platform=Odeon&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },

              // {
              //   name: 'google-play-giftcards',
              //   displayName: 'Google Play',
              //   url: '/store?q=google+play&productType%5B%5D=giftcard&page=1',
              //   queryStr: 'platform=Google+Play&productType%5B%5D=giftcard',
              //   idSubChild: 1053,
              //   navbar: true,
              // },
              // {
              //   name: 'google-play-gift-cards',
              //   displayName: 'Google Play',
              //   url: '/store?q=google+play&productType%5B%5D=giftcard&page=1',
              //   queryStr: 'platform=Google+Play&productType%5B%5D=giftcard',
              //   idSubChild: 1053,
              //   navbar: true,
              // },
              // {
              //   name: 'amazon-gift-cards',
              //   displayName: 'Amazon',
              //   url: '/store?q=amazon&productType%5B%5D=giftcard&page=1',
              //   queryStr: 'platform=Amazon&productType%5B%5D=giftcard',
              //   idSubChild: 1054,
              //   navbar: true,
              // },
              // {
              //   name: 'bigo-live-gift-cards',
              //   displayName: 'Bigo Live',
              //   url: '/store?q=bigo+live&productType%5B%5D=giftcard&page=1',
              //   queryStr: 'platform=Bigo+Live&productType%5B%5D=giftcard',
              //   idSubChild: 1055,
              //   navbar: true,
              // },
              // {
              //   name: 'apple-itunes-gift-cards',
              //   displayName: 'Apple iTunes',
              //   url: '/store?q=apple+itunes&productType%5B%5D=giftcard&page=1',
              //   queryStr: 'platform=Apple&productType%5B%5D=giftcard',
              //   idSubChild: 1056,
              //   navbar: true,
              // },
              // {
              //   name: 'jeton-cash-gift-cards',
              //   displayName: 'Jeton Cash',
              //   url: '/store?q=jeton+cash&productType%5B%5D=giftcard&page=1',
              //   queryStr: 'q=jeton+cash&productType%5B%5D=giftcard',
              //   idSubChild: 1057,
              //   navbar: true,
              // },
              // {
              //   name: 'neosurf-gift-cards',
              //   displayName: 'Neosurf',
              //   url: '/store?q=neosurf&productType%5B%5D=giftcard&page=1',
              //   queryStr: 'platform=Neosurf&productType%5B%5D=giftcard',
              //   idSubChild: 1058,
              //   navbar: true,
              // },
              {
                name: 'gift-cards',
                displayName: view_all_msg ?? '',
                url: '/store?productType=giftcard&worksIn=false',
                queryStr: 'productType=giftcard&worksIn=false',
                idSubChild: 1050,
                navbar: true,
                viewAll: true,
              },
              // {
              //   name: 'cashlib-giftcards',
              //   displayName: 'Cashlib',
              //   url: '/store?q=cashlib&productType%5B%5D=giftcard&page=1',
              //   queryStr: 'q=cashlib&productType%5B%5D=giftcard',
              //   idSubChild: 1059,
              // },
              // {
              //   name: 'astropay-giftcards',
              //   displayName: 'AstroPay',
              //   url: '/store?q=astropay&productType%5B%5D=giftcard&page=1',
              //   queryStr: 'q=astropay&productType%5B%5D=giftcard',
              //   idSubChild: 1080,
              // },
              // {
              //   name: 'bitcoin-crypto-giftcards',
              //   displayName: 'Bitcoin Crypto Voucher',
              //   url: '/store?q=crypto+voucher+bitcoin&productType%5B%5D=giftcard&page=1',
              //   queryStr: 'q=crypto+voucher+bitcoin&productType%5B%5D=giftcard',
              //   idSubChild: 1081,
              // },
            ],
          },
          {
            name: lifestyle_cards_msg ?? '',
            displayName: 'Retail & eCommerce',
            idSub: 106,
            children: [
              {
                name: 'amazon-gift-cards',
                displayName: 'Amazon',
                url: '/store?productType=giftcard&platform=Amazon&worksIn=false',
                queryStr: 'productType=giftcard&platform=Amazon&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'ikea-gift-cards',
                displayName: 'IKEA',
                url: '/store?productType=giftcard&platform=IKEA&worksIn=false',
                queryStr: 'productType=giftcard&platform=IKEA&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'asos-gift-cards',
                displayName: 'ASOS',
                url: '/store?productType=giftcard&platform=ASOS&worksIn=false',
                queryStr: 'productType=giftcard&platform=ASOS&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'primark-gift-cards',
                displayName: 'Primark',
                url: '/store?productType=giftcard&platform=Primark&worksIn=false',
                queryStr: 'productType=giftcard&platform=Primark&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'zalando-gift-cards',
                displayName: 'Zalando',
                url: '/store?productType=giftcard&platform=Zalando&worksIn=false',
                queryStr: 'productType=giftcard&platform=Zalando&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'christ-gift-cards',
                displayName: 'Christ',
                url: '/store?productType=giftcard&platform=Christ&worksIn=false',
                queryStr: 'productType=giftcard&platform=Christ&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'intersport-gift-cards',
                displayName: 'Intersport',
                url: '/store?productType=giftcard&platform=Intersport&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Intersport&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'tchibo-gift-cards',
                displayName: 'Tchibo',
                url: '/store?productType=giftcard&platform=Tchibo&worksIn=false',
                queryStr: 'productType=giftcard&platform=Tchibo&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'otto-gift-cards',
                displayName: 'Otto',
                url: '/store?productType=giftcard&platform=Otto&worksIn=false',
                queryStr: 'productType=giftcard&platform=Otto&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'kaufland-gift-cards',
                displayName: 'Kaufland',
                url: '/store?productType=giftcard&platform=Kaufland&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Kaufland&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'penny-gift-cards',
                displayName: 'Penny',
                url: '/store?productType=giftcard&platform=Penny&worksIn=false',
                queryStr: 'productType=giftcard&platform=Penny&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'rewe-gift-cards',
                displayName: 'REWE',
                url: '/store?productType=giftcard&platform=REWE&worksIn=false',
                queryStr: 'productType=giftcard&platform=REWE&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'poco-gift-cards',
                displayName: 'POCO',
                url: '/store?productType=giftcard&platform=POCO&worksIn=false',
                queryStr: 'productType=giftcard&platform=POCO&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'jotex-gift-cards',
                displayName: 'Jotex',
                url: '/store?productType=giftcard&platform=Jotex&worksIn=false',
                queryStr: 'productType=giftcard&platform=Jotex&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'dehner-gift-cards',
                displayName: 'Dehner',
                url: '/store?productType=giftcard&platform=Dehner&worksIn=false',
                queryStr: 'productType=giftcard&platform=Dehner&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'baur-gift-cards',
                displayName: 'BAUR',
                url: '/store?productType=giftcard&platform=BAUR&worksIn=false',
                queryStr: 'productType=giftcard&platform=BAUR&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'tk-maxx-gift-cards',
                displayName: 'TK Maxx',
                url: '/store?productType=giftcard&platform=TK+Maxx&worksIn=false',
                queryStr: 'productType=giftcard&platform=TK+Maxx&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'big-w-gift-cards',
                displayName: 'Big W',
                url: '/store?productType=giftcard&platform=Big+W&worksIn=false',
                queryStr: 'productType=giftcard&platform=Big+W&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'ebay-gift-cards',
                displayName: 'eBay',
                url: '/store?productType=giftcard&platform=eBay&worksIn=false',
                queryStr: 'productType=giftcard&platform=eBay&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'catch-gift-cards',
                displayName: 'Catch',
                url: '/store?productType=giftcard&platform=Catch&worksIn=false',
                queryStr: 'productType=giftcard&platform=Catch&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'fidira-gift-cards',
                displayName: 'Fidira',
                url: '/store?productType=giftcard&platform=Fidira&worksIn=false',
                queryStr: 'productType=giftcard&platform=Fidira&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'target-gift-cards',
                displayName: 'Target',
                url: '/store?productType=giftcard&platform=Target&worksIn=false',
                queryStr: 'productType=giftcard&platform=Target&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'kmart-gift-cards',
                displayName: 'Kmart',
                url: '/store?productType=giftcard&platform=Kmart&worksIn=false',
                queryStr: 'productType=giftcard&platform=Kmart&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'david-jones-gift-cards',
                displayName: 'David Jones',
                url: '/store?productType=giftcard&platform=David+Jones&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=David+Jones&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'americanas-gift-cards',
                displayName: 'Americanas',
                url: '/store?productType=giftcard&platform=Americanas&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Americanas&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'carrefour-gift-cards',
                displayName: 'Carrefour',
                url: '/store?productType=giftcard&platform=Carrefour&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Carrefour&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'shopee-gift-cards',
                displayName: 'Shopee',
                url: '/store?productType=giftcard&platform=Shopee&worksIn=false',
                queryStr: 'productType=giftcard&platform=Shopee&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'submarino-gift-cards',
                displayName: 'Submarino',
                url: '/store?productType=giftcard&platform=Submarino&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Submarino&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'shop-time-gift-cards',
                displayName: 'Shop Time',
                url: '/store?productType=giftcard&platform=Shop+Time&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Shop+Time&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'casasbahia-gift-cards',
                displayName: 'CasasBahia',
                url: '/store?productType=giftcard&platform=CasasBahia&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=CasasBahia&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'extra-gift-cards',
                displayName: 'Extra',
                url: '/store?productType=giftcard&platform=Extra&worksIn=false',
                queryStr: 'productType=giftcard&platform=Extra&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'croma-gift-cards',
                displayName: 'Croma',
                url: '/store?productType=giftcard&platform=Croma&worksIn=false',
                queryStr: 'productType=giftcard&platform=Croma&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'fabindia-gift-cards',
                displayName: 'FabIndia',
                url: '/store?productType=giftcard&platform=FabIndia&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=FabIndia&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'flipkart-gift-cards',
                displayName: 'Flipkart',
                url: '/store?productType=giftcard&platform=Flipkart&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Flipkart&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'myntra-gift-cards',
                displayName: 'Myntra',
                url: '/store?productType=giftcard&platform=Myntra&worksIn=false',
                queryStr: 'productType=giftcard&platform=Myntra&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'lulu-hypermarket-gift-cards',
                displayName: 'Lulu Hypermarket',
                url: '/store?productType=giftcard&platform=Lulu+Hypermarket&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Lulu+Hypermarket&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'shoppers-stop-gift-cards',
                displayName: 'Shoppers Stop',
                url: '/store?productType=giftcard&platform=Shoppers+Stop&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Shoppers+Stop&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'tata-cliq-gift-cards',
                displayName: 'Tata Cliq',
                url: '/store?productType=giftcard&platform=Tata+Cliq&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Tata+Cliq&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'vijay-sales-gift-cards',
                displayName: 'Vijay Sales',
                url: '/store?productType=giftcard&platform=Vijay+Sales&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Vijay+Sales&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'bigbasket-gift-cards',
                displayName: 'Bigbasket',
                url: '/store?productType=giftcard&platform=Bigbasket&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Bigbasket&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'spar-gift-cards',
                displayName: 'Spar',
                url: '/store?productType=giftcard&platform=Spar&worksIn=false',
                queryStr: 'productType=giftcard&platform=Spar&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'blink-it-gift-cards',
                displayName: 'Blink It',
                url: '/store?productType=giftcard&platform=Blink+It&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Blink+It&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'wakefit-gift-cards',
                displayName: 'Wakefit',
                url: '/store?productType=giftcard&platform=Wakefit&worksIn=false',
                queryStr: 'productType=giftcard&platform=Wakefit&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'ratnadeep-supermarket-gift-cards',
                displayName: 'Ratnadeep Supermarket',
                url: '/store?productType=giftcard&platform=Ratnadeep+Supermarket&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Ratnadeep+Supermarket&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'home-centre-gift-cards',
                displayName: 'Home Centre',
                url: '/store?productType=giftcard&platform=Home+Centre&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Home+Centre&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'danube-home-gift-cards',
                displayName: 'DANUBE HOME',
                url: '/store?productType=giftcard&platform=DANUBE+HOME&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=DANUBE+HOME&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'firstcry-gift-cards',
                displayName: 'FirstCry',
                url: '/store?productType=giftcard&platform=FirstCry&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=FirstCry&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'ajio-gift-cards',
                displayName: 'AJIO',
                url: '/store?productType=giftcard&platform=AJIO&worksIn=false',
                queryStr: 'productType=giftcard&platform=AJIO&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'chumbak-gift-cards',
                displayName: 'Chumbak',
                url: '/store?productType=giftcard&platform=Chumbak&worksIn=false',
                queryStr: 'productType=giftcard&platform=Chumbak&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'smyk-gift-cards',
                displayName: 'SMYK',
                url: '/store?productType=giftcard&platform=SMYK&worksIn=false',
                queryStr: 'productType=giftcard&platform=SMYK&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'biedronka-gift-cards',
                displayName: 'Biedronka',
                url: '/store?productType=giftcard&platform=Biedronka&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Biedronka&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'bee-gift-cards',
                displayName: 'Bee',
                url: '/store?productType=giftcard&platform=Bee&worksIn=false',
                queryStr: 'productType=giftcard&platform=Bee&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'walmart-gift-cards',
                displayName: 'Walmart',
                url: '/store?productType=giftcard&platform=Walmart&worksIn=false',
                queryStr: 'productType=giftcard&platform=Walmart&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'nordstrom-rack-gift-cards',
                displayName: 'Nordstrom Rack',
                url: '/store?productType=giftcard&platform=Nordstrom+Rack&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Nordstrom+Rack&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
            ],
          },
          {
            name: lifestyle_cards_msg ?? '',
            displayName: 'Food & Beverage',
            idSub: 106,
            children: [
              {
                name: 'starbucks-gift-cards',
                displayName: 'Starbucks',
                url: '/store?productType=giftcard&platform=Starbucks&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Starbucks&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'dominos-pizza-gift-cards',
                displayName: 'Dominos Pizza',
                url: '/store?productType=giftcard&platform=Dominos+Pizza&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Dominos+Pizza&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'just-eat-gift-cards',
                displayName: 'Just Eat',
                url: '/store?productType=giftcard&platform=Just+Eat&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Just+Eat&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'doordash-gift-cards',
                displayName: 'DoorDash',
                url: '/store?productType=giftcard&platform=DoorDash&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=DoorDash&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'uber-eats-gift-cards',
                displayName: 'Uber Eats',
                url: '/store?productType=giftcard&platform=Uber+Eats&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Uber+Eats&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'coles-gift-cards',
                displayName: 'Coles',
                url: '/store?productType=giftcard&platform=Coles&worksIn=false',
                queryStr: 'productType=giftcard&platform=Coles&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'bws-gift-cards',
                displayName: 'BWS',
                url: '/store?productType=giftcard&platform=BWS&worksIn=false',
                queryStr: 'productType=giftcard&platform=BWS&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'dan-murphys-gift-cards',
                displayName: "Dan Murphy's",
                url: '/store?productType=giftcard&platform=Dan+Murphy%27s&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Dan+Murphy%27s&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'hey-you-gift-cards',
                displayName: 'Hey You',
                url: '/store?productType=giftcard&platform=Hey+You&worksIn=false',
                queryStr: 'productType=giftcard&platform=Hey+You&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'rappi-gift-cards',
                displayName: 'Rappi',
                url: '/store?productType=giftcard&platform=Rappi&worksIn=false',
                queryStr: 'productType=giftcard&platform=Rappi&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'mcdonalds-gift-cards',
                displayName: "McDonald's",
                url: '/store?productType=giftcard&platform=McDonald%27s&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=McDonald%27s&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'barbeque-nation-gift-cards',
                displayName: 'Barbeque Nation',
                url: '/store?productType=giftcard&platform=Barbeque+Nation&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Barbeque+Nation&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'cafe-coffee-day-gift-cards',
                displayName: 'Cafe Coffee Day',
                url: '/store?productType=giftcard&platform=Cafe+Coffee+Day&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Cafe+Coffee+Day&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'zomato-gift-cards',
                displayName: 'Zomato',
                url: '/store?productType=giftcard&platform=Zomato&worksIn=false',
                queryStr: 'productType=giftcard&platform=Zomato&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'swiggy-gift-cards',
                displayName: 'Swiggy',
                url: '/store?productType=giftcard&platform=Swiggy&worksIn=false',
                queryStr: 'productType=giftcard&platform=Swiggy&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'baskin-robbins-gift-cards',
                displayName: 'Baskin Robbins',
                url: '/store?productType=giftcard&platform=Baskin+Robbins&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Baskin+Robbins&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'costa-coffee-gift-cards',
                displayName: 'Costa Coffee',
                url: '/store?productType=giftcard&platform=Costa+Coffee&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Costa+Coffee&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'kfc-gift-cards',
                displayName: 'KFC',
                url: '/store?productType=giftcard&platform=KFC&worksIn=false',
                queryStr: 'productType=giftcard&platform=KFC&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'beer-cafe-gift-cards',
                displayName: 'Beer Cafe',
                url: '/store?productType=giftcard&platform=Beer+Cafe&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Beer+Cafe&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'instacart-gift-cards',
                displayName: 'Instacart',
                url: '/store?productType=giftcard&platform=Instacart',
                queryStr: 'productType=giftcard&platform=Instacart',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'subway-gift-cards',
                displayName: 'Subway',
                url: '/store?productType=giftcard&platform=Subway&worksIn=false',
                queryStr: 'productType=giftcard&platform=Subway&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'wine.com-gift-cards',
                displayName: 'Wine.com',
                url: '/store?productType=giftcard&platform=Wine.com&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Wine.com&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'kroger-gift-cards',
                displayName: 'Kroger',
                url: '/store?productType=giftcard&platform=Kroger&worksIn=false',
                queryStr: 'productType=giftcard&platform=Kroger&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'jamba-juice-gift-cards',
                displayName: 'Jamba Juice',
                url: '/store?productType=giftcard&platform=Jamba+Juice&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Jamba+Juice&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'home-chef-gift-cards',
                displayName: 'Home Chef',
                url: '/store?productType=giftcard&platform=Home+Chef&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Home+Chef&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'grubhub-gift-cards',
                displayName: 'Grubhub',
                url: '/store?productType=giftcard&platform=Grubhub&worksIn=false',
                queryStr: 'productType=giftcard&platform=Grubhub&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'burger-king-gift-cards',
                displayName: 'Burger King',
                url: '/store?productType=giftcard&platform=Burger+King&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Burger+King&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'red-robin-gift-cards',
                displayName: 'Red Robin',
                url: '/store?productType=giftcard&platform=Red+Robin&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Red+Robin&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'chipotle-gift-cards',
                displayName: 'Chipotle',
                url: '/store?productType=giftcard&platform=Chipotle&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Chipotle&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'deliveroo-gift-cards',
                displayName: 'Deliveroo',
                url: '/store?productType=giftcard&platform=Deliveroo&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Deliveroo&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'foodpanda-gift-cards',
                displayName: 'Foodpanda',
                url: '/store?productType=giftcard&platform=Foodpanda&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Foodpanda&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'gofood-gift-cards',
                displayName: 'GoFood',
                url: '/store?productType=giftcard&platform=GoFood&worksIn=false',
                queryStr: 'productType=giftcard&platform=GoFood&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'eatigo-gift-cards',
                displayName: 'Eatigo',
                url: '/store?productType=giftcard&platform=Eatigo&worksIn=false',
                queryStr: 'productType=giftcard&platform=Eatigo&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'tim-hortons-gift-cards',
                displayName: 'Tim Hortons',
                url: '/store?productType=giftcard&platform=Tim+Hortons&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Tim+Hortons&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'ultimate-dining-gift-cards',
                displayName: 'Ultimate Dining',
                url: '/store?productType=giftcard&platform=Ultimate+Dining&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Ultimate+Dining&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'the-landing-group-gift-cards',
                displayName: 'The Landing Group',
                url: '/store?productType=giftcard&platform=The+Landing+Group&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=The+Landing+Group&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'swiss-chalet-gift-cards',
                displayName: 'Swiss Chalet',
                url: '/store?productType=giftcard&platform=Swiss+Chalet&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Swiss+Chalet&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },

              {
                name: 'original-joes-gift-cards',
                displayName: "Original Joe's",
                url: '/store?productType=giftcard&platform=Original+Joe%27s&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Original+Joe%27s&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'new-york-fries-gift-cards',
                displayName: 'New York Fries',
                url: '/store?productType=giftcard&platform=New+York+Fries&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=New+York+Fries&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'kelseys-gift-cards',
                displayName: "Kelsey's",
                url: '/store?productType=giftcard&platform=Kelsey%27s&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Kelsey%27s&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'east-side-marios-gift-cards',
                displayName: "East Side Mario's",
                url: '/store?productType=giftcard&platform=East+Side+Mario%27s&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=East+Side+Mario%27s&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'bier-markt-gift-cards',
                displayName: 'Bier Markt',
                url: '/store?productType=giftcard&platform=Bier+Markt&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Bier+Markt&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'montanas-gift-cards',
                displayName: "Montana's",
                url: '/store?productType=giftcard&platform=Montana%27s&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Montana%27s&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'espresso-house-gift-cards',
                displayName: 'Espresso House',
                url: '/store?productType=giftcard&platform=Espresso+House&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Espresso+House&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'wing-stop-gift-cards',
                displayName: 'Wing Stop',
                url: '/store?productType=giftcard&platform=Wing+Stop&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Wing+Stop&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'talabat-gift-cards',
                displayName: 'Talabat',
                url: '/store?productType=giftcard&platform=Talabat&worksIn=false',
                queryStr: 'productType=giftcard&platform=Talabat&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'foodline-gift-cards',
                displayName: 'FoodLine',
                url: '/store?productType=giftcard&platform=FoodLine&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=FoodLine&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'asda-gift-cards',
                displayName: 'ASDA',
                url: '/store?productType=giftcard&platform=ASDA&worksIn=false',
                queryStr: 'productType=giftcard&platform=ASDA&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'whsmith-gift-cards',
                displayName: 'WHSmith',
                url: '/store?productType=giftcard&platform=WHSmith&worksIn=false',
                queryStr: 'productType=giftcard&platform=WHSmith&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'pizza-hut-gift-cards',
                displayName: 'Pizza Hut',
                url: '/store?productType=giftcard&platform=Pizza+Hut&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Pizza+Hut&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'harvey-nichols-gift-cards',
                displayName: 'Harvey Nichols',
                url: '/store?productType=giftcard&platform=Harvey+Nichols&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Harvey+Nichols&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'morrisons-gift-cards',
                displayName: 'Morrisons',
                url: '/store?productType=giftcard&platform=Morrisons&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Morrisons&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'pizza-express-gift-cards',
                displayName: 'Pizza Express',
                url: '/store?productType=giftcard&platform=Pizza+Express&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Pizza+Express&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'ask-italian-gift-cards',
                displayName: 'ASK Italian',
                url: '/store?productType=giftcard&platform=ASK+Italian&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=ASK+Italian&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'nandos-gift-cards',
                displayName: "Nando's",
                url: '/store?productType=giftcard&platform=Nando%27s&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Nando%27s&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'zizzi-gift-cards',
                displayName: 'Zizzi',
                url: '/store?productType=giftcard&platform=Zizzi&worksIn=false',
                queryStr: 'productType=giftcard&platform=Zizzi&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'iceland-gift-cards',
                displayName: 'Iceland',
                url: '/store?productType=giftcard&platform=Iceland&worksIn=false',
                queryStr: 'productType=giftcard&platform=Iceland&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
            ],
          },
          {
            name: lifestyle_cards_msg ?? '',
            displayName: 'Travel & Experiences',
            idSub: 106,
            children: [
              {
                name: 'airbnb-gift-cards',
                displayName: 'Airbnb',
                url: '/store?productType=giftcard&platform=Airbnb&worksIn=false',
                queryStr: 'productType=giftcard&platform=Airbnb&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'lastminute.com-gift-cards',
                displayName: 'lastminute.com',
                url: '/store?productType=giftcard&platform=lastminute.com&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=lastminute.com&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'europcar-gift-cards',
                displayName: 'Europcar',
                url: '/store?productType=giftcard&platform=Europcar&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Europcar&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'sixt-rent-gift-cards',
                displayName: 'Sixt Rent',
                url: '/store?productType=giftcard&platform=Sixt+Rent&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Sixt+Rent&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'hotels.com-gift-cards',
                displayName: 'Hotels.com',
                url: '/store?productType=giftcard&platform=Hotels.com&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Hotels.com&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'uber-gift-cards',
                displayName: 'Uber',
                url: '/store?productType=giftcard&platform=Uber&worksIn=false',
                queryStr: 'productType=giftcard&platform=Uber&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'webjet-gift-cards',
                displayName: 'Webjet',
                url: '/store?productType=giftcard&platform=Webjet&worksIn=false',
                queryStr: 'productType=giftcard&platform=Webjet&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'tripgift-gift-cards',
                displayName: 'TripGift',
                url: '/store?productType=giftcard&platform=TripGift&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=TripGift&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'accor-gift-cards',
                displayName: 'Accor',
                url: '/store?productType=giftcard&platform=Accor&worksIn=false',
                queryStr: 'productType=giftcard&platform=Accor&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'flight-centre-gift-cards',
                displayName: 'Flight Centre',
                url: '/store?productType=giftcard&platform=Flight+Centre&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Flight+Centre&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'expedia-group-gift-cards',
                displayName: 'Expedia Group',
                url: '/store?productType=giftcard&platform=Expedia+Group&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Expedia+Group&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'makemytrip-gift-cards',
                displayName: 'MakeMyTrip',
                url: '/store?productType=giftcard&platform=MakeMyTrip&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=MakeMyTrip&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'taj-gift-cards',
                displayName: 'Taj',
                url: '/store?productType=giftcard&platform=Taj&worksIn=false',
                queryStr: 'productType=giftcard&platform=Taj&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'ola-cabs-gift-cards',
                displayName: 'Ola Cabs',
                url: '/store?productType=giftcard&platform=Ola+Cabs&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Ola+Cabs&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'cleartrip-gift-cards',
                displayName: 'Cleartrip',
                url: '/store?productType=giftcard&platform=Cleartrip&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Cleartrip&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'marriott-gift-cards',
                displayName: 'Marriott',
                url: '/store?productType=giftcard&platform=Marriott&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Marriott&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'itc-hotels-gift-cards',
                displayName: 'ITC Hotels',
                url: '/store?productType=giftcard&platform=ITC+Hotels&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=ITC+Hotels&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'american-airlines-gift-cards',
                displayName: 'American Airlines',
                url: '/store?productType=giftcard&platform=American+Airlines&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=American+Airlines&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'turo-gift-cards',
                displayName: 'Turo',
                url: '/store?productType=giftcard&platform=Turo&worksIn=false',
                queryStr: 'productType=giftcard&platform=Turo&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'southwest-airlines-gift-cards',
                displayName: 'Southwest Airlines',
                url: '/store?productType=giftcard&platform=Southwest+Airlines&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Southwest+Airlines&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'royal-caribbean-cruises-gift-cards',
                displayName: 'Royal Caribbean Cruises',
                url: '/store?productType=giftcard&platform=Royal+Caribbean+Cruises&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Royal+Caribbean+Cruises&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'mandarin-oriental-hotel-group-gift-cards',
                displayName: 'Mandarin Oriental Hotel Group',
                url: '/store?productType=giftcard&platform=Mandarin+Oriental+Hotel+Group&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Mandarin+Oriental+Hotel+Group&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'lyft-gift-cards',
                displayName: 'Lyft',
                url: '/store?productType=giftcard&platform=Lyft&worksIn=false',
                queryStr: 'productType=giftcard&platform=Lyft&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'hopper-gift-cards',
                displayName: 'Hopper',
                url: '/store?productType=giftcard&platform=Hopper&worksIn=false',
                queryStr: 'productType=giftcard&platform=Hopper&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'getgo-gift-cards',
                displayName: 'GetGo',
                url: '/store?productType=giftcard&platform=GetGo&worksIn=false',
                queryStr: 'productType=giftcard&platform=GetGo&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'celebrity-cruises-gift-cards',
                displayName: 'Celebrity Cruises',
                url: '/store?productType=giftcard&platform=Celebrity+Cruises&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Celebrity+Cruises&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'golden-nugget-casino-gift-cards',
                displayName: 'Golden Nugget Casino',
                url: '/store?productType=giftcard&platform=Golden+Nugget+Casino&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Golden+Nugget+Casino&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'delta-gift-cards',
                displayName: 'Delta',
                url: '/store?productType=giftcard&platform=Delta&worksIn=false',
                queryStr: 'productType=giftcard&platform=Delta&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'westgate-resorts-gift-cards',
                displayName: 'Westgate Resorts',
                url: '/store?productType=giftcard&platform=Westgate+Resorts&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Westgate+Resorts&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'global-experiences-gift-cards',
                displayName: 'Global Experiences',
                url: '/store?productType=giftcard&platform=Global+Experiences&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Global+Experiences&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'despegar-gift-cards',
                displayName: 'Despegar',
                url: '/store?productType=giftcard&platform=Despegar&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Despegar&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'indigo-gift-cards',
                displayName: 'Indigo',
                url: '/store?productType=giftcard&platform=Indigo&worksIn=false',
                queryStr: 'productType=giftcard&platform=Indigo&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'eurostar-gift-cards',
                displayName: 'Eurostar',
                url: '/store?productType=giftcard&platform=Eurostar&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Eurostar&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'careem-gift-cards',
                displayName: 'Careem',
                url: '/store?productType=giftcard&platform=Careem&worksIn=false',
                queryStr: 'productType=giftcard&platform=Careem&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'rumbo-gift-cards',
                displayName: 'Rumbo',
                url: '/store?productType=giftcard&platform=Rumbo&worksIn=false',
                queryStr: 'productType=giftcard&platform=Rumbo&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
            ],
          },
          {
            name: lifestyle_cards_msg ?? '',
            displayName: 'Fashion & Apparel',
            idSub: 106,
            children: [
              {
                name: 'h&m-gift-cards',
                displayName: 'H&M',
                url: '/store?productType=giftcard&platform=H&M&worksIn=false',
                queryStr: 'productType=giftcard&platform=H&M&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'decathlon-gift-cards',
                displayName: 'Decathlon',
                url: '/store?productType=giftcard&platform=Decathlon&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Decathlon&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'adidas-gift-cards',
                displayName: 'Adidas',
                url: '/store?productType=giftcard&platform=Adidas&worksIn=false',
                queryStr: 'productType=giftcard&platform=Adidas&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'nike-gift-cards',
                displayName: 'Nike',
                url: '/store?productType=giftcard&platform=Nike&worksIn=false',
                queryStr: 'productType=giftcard&platform=Nike&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'swarovski-gift-cards',
                displayName: 'Swarovski',
                url: '/store?productType=giftcard&platform=Swarovski&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Swarovski&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'ernstings-family-gift-cards',
                displayName: 'Ernstings Family',
                url: '/store?productType=giftcard&platform=Ernstings+Family&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Ernstings+Family&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'foot-locker-gift-cards',
                displayName: 'Foot Locker',
                url: '/store?productType=giftcard&platform=Foot+Locker&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Foot+Locker&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'macpac-gift-cards',
                displayName: 'Macpac',
                url: '/store?productType=giftcard&platform=Macpac&worksIn=false',
                queryStr: 'productType=giftcard&platform=Macpac&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'centauro-gift-cards',
                displayName: 'Centauro',
                url: '/store?productType=giftcard&platform=Centauro&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Centauro&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'netshoes-gift-cards',
                displayName: 'Netshoes',
                url: '/store?productType=giftcard&platform=Netshoes&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Netshoes&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'gap-gift-cards',
                displayName: 'Gap',
                url: '/store?productType=giftcard&platform=Gap&worksIn=false',
                queryStr: 'productType=giftcard&platform=Gap&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'fastrack-gift-cards',
                displayName: 'Fastrack',
                url: '/store?productType=giftcard&platform=Fastrack&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Fastrack&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'joyalukkas-gift-cards',
                displayName: 'Joyalukkas',
                url: '/store?productType=giftcard&platform=Joyalukkas&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Joyalukkas&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'kalyan-diamond-jewellery-gift-cards',
                displayName: 'Kalyan Diamond Jewellery',
                url: '/store?productType=giftcard&platform=Kalyan+Diamond+Jewellery&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Kalyan+Diamond+Jewellery&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'levis-gift-cards',
                displayName: "Levi's",
                url: '/store?productType=giftcard&platform=Levi%27s&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Levi%27s&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'pantaloons-gift-cards',
                displayName: 'Pantaloons',
                url: '/store?productType=giftcard&platform=Pantaloons&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Pantaloons&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'ray-ban-gift-cards',
                displayName: 'Ray-Ban',
                url: '/store?productType=giftcard&platform=Ray-Ban&worksIn=false',
                queryStr: 'productType=giftcard&platform=Ray-Ban&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'titan-eye-plus-gift-cards',
                displayName: 'Titan Eye Plus',
                url: '/store?productType=giftcard&platform=Titan+Eye+Plus&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Titan+Eye+Plus&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'jockey-gift-cards',
                displayName: 'Jockey',
                url: '/store?productType=giftcard&platform=Jockey&worksIn=false',
                queryStr: 'productType=giftcard&platform=Jockey&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'woodland-gift-cards',
                displayName: 'Woodland',
                url: '/store?productType=giftcard&platform=Woodland&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Woodland&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'bewakoof-gift-cards',
                displayName: 'Bewakoof',
                url: '/store?productType=giftcard&platform=Bewakoof&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Bewakoof&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'blackberrys-gift-cards',
                displayName: 'Blackberrys',
                url: '/store?productType=giftcard&platform=Blackberrys&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Blackberrys&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'hush-puppies-gift-cards',
                displayName: 'Hush Puppies',
                url: '/store?productType=giftcard&platform=Hush+Puppies&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Hush+Puppies&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'lee-gift-cards',
                displayName: 'Lee',
                url: '/store?productType=giftcard&platform=Lee&worksIn=false',
                queryStr: 'productType=giftcard&platform=Lee&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'raymond-gift-cards',
                displayName: 'Raymond',
                url: '/store?productType=giftcard&platform=Raymond&worksIn=false',
                queryStr: 'productType=giftcard&platform=Raymond&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'arrow-gift-cards',
                displayName: 'Arrow',
                url: '/store?productType=giftcard&platform=Arrow&worksIn=false',
                queryStr: 'productType=giftcard&platform=Arrow&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'aeropostale-gift-cards',
                displayName: 'Aeropostale',
                url: '/store?productType=giftcard&platform=Aeropostale&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Aeropostale&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'aurelia-gift-cards',
                displayName: 'Aurelia',
                url: '/store?productType=giftcard&platform=Aurelia&worksIn=false',
                queryStr: 'productType=giftcard&platform=Aurelia&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'titan-gift-cards',
                displayName: 'Titan',
                url: '/store?productType=giftcard&platform=Titan&worksIn=false',
                queryStr: 'productType=giftcard&platform=Titan&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'vero-moda-gift-cards',
                displayName: 'Vero Moda',
                url: '/store?productType=giftcard&platform=Vero+Moda&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Vero+Moda&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'blissclub-gift-cards',
                displayName: 'BlissClub',
                url: '/store?productType=giftcard&platform=BlissClub&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=BlissClub&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'global-desi-gift-cards',
                displayName: 'Global Desi',
                url: '/store?productType=giftcard&platform=Global+Desi&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Global+Desi&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'mango-gift-cards',
                displayName: 'Mango',
                url: '/store?productType=giftcard&platform=Mango&worksIn=false',
                queryStr: 'productType=giftcard&platform=Mango&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'old-navy-gift-cards',
                displayName: 'Old Navy',
                url: '/store?productType=giftcard&platform=Old+Navy&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Old+Navy&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'columbia-sportswear-gift-cards',
                displayName: 'Columbia Sportswear',
                url: '/store?productType=giftcard&platform=Columbia+Sportswear&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Columbia+Sportswear&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'lululemon-gift-cards',
                displayName: 'Lululemon',
                url: '/store?productType=giftcard&platform=Lululemon&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Lululemon&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'under-armour-gift-cards',
                displayName: 'Under Armour',
                url: '/store?productType=giftcard&platform=Under+Armour&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Under+Armour&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'victorias-secret-gift-cards',
                displayName: "Victoria's Secret",
                url: '/store?productType=giftcard&platform=Victoria%27s+Secret&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Victoria%27s+Secret&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'dsw-gift-cards',
                displayName: 'DSW',
                url: '/store?productType=giftcard&platform=DSW&worksIn=false',
                queryStr: 'productType=giftcard&platform=DSW&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'zalora-gift-cards',
                displayName: 'Zalora',
                url: '/store?productType=giftcard&platform=Zalora&worksIn=false',
                queryStr: 'productType=giftcard&platform=Zalora&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'aral-sports-gift-cards',
                displayName: 'Aral Sports',
                url: '/store?productType=giftcard&platform=Aral+Sports&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Aral+Sports&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'ardene-gift-cards',
                displayName: 'Ardene',
                url: '/store?productType=giftcard&platform=Ardene&worksIn=false',
                queryStr: 'productType=giftcard&platform=Ardene&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'american-eagle-gift-cards',
                displayName: 'American Eagle',
                url: '/store?productType=giftcard&platform=American+Eagle&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=American+Eagle&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'nelly-gift-cards',
                displayName: 'Nelly',
                url: '/store?productType=giftcard&platform=Nelly&worksIn=false',
                queryStr: 'productType=giftcard&platform=Nelly&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'sport-24-gift-cards',
                displayName: 'Sport 24',
                url: '/store?productType=giftcard&platform=Sport+24&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Sport+24&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'sportmaster-gift-cards',
                displayName: 'Sportmaster',
                url: '/store?productType=giftcard&platform=Sportmaster&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Sportmaster&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'dinsko-gift-cards',
                displayName: 'DinSko',
                url: '/store?productType=giftcard&platform=DinSko&worksIn=false',
                queryStr: 'productType=giftcard&platform=DinSko&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'stadium-gift-cards',
                displayName: 'Stadium',
                url: '/store?productType=giftcard&platform=Stadium&worksIn=false',
                queryStr: 'productType=giftcard&platform=Stadium&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'innovasport-gift-cards',
                displayName: 'Innovasport',
                url: '/store?productType=giftcard&platform=Innovasport&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Innovasport&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'calzedonia-gift-cards',
                displayName: 'Calzedonia',
                url: '/store?productType=giftcard&platform=Calzedonia&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Calzedonia&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'blacksocks-gift-cards',
                displayName: 'Blacksocks',
                url: '/store?productType=giftcard&platform=Blacksocks&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Blacksocks&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'river-island-gift-cards',
                displayName: 'River Island',
                url: '/store?productType=giftcard&platform=River+Island&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=River+Island&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'aldi-gift-cards',
                displayName: 'Aldi',
                url: '/store?productType=giftcard&platform=Aldi&worksIn=false',
                queryStr: 'productType=giftcard&platform=Aldi&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'astratex-gift-cards',
                displayName: 'ASTRATEX',
                url: '/store?productType=giftcard&platform=ASTRATEX&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=ASTRATEX&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'marks-and-spencer-gift-cards',
                displayName: 'Marks and Spencer',
                url: '/store?productType=giftcard&platform=Marks+and+Spencer&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Marks+and+Spencer&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'new-look-gift-cards',
                displayName: 'New Look',
                url: '/store?productType=giftcard&platform=New+Look&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=New+Look&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'vipshop-gift-cards',
                displayName: 'Vipshop',
                url: '/store?productType=giftcard&platform=Vipshop&worksIn=false',
                queryStr: 'productType=giftcard&platform=Vipshop&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
            ],
          },
          {
            name: lifestyle_cards_msg ?? '',
            displayName: 'Health & Wellness',
            idSub: 106,
            children: [
              {
                name: 'douglas-gift-cards',
                displayName: 'Douglas',
                url: '/store?productType=giftcard&platform=Douglas&worksIn=false',
                queryStr: 'productType=giftcard&platform=Douglas&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'rossmann-gift-cards',
                displayName: 'Rossmann',
                url: '/store?productType=giftcard&platform=Rossmann&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Rossmann&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'shop-apotheke-gift-cards',
                displayName: 'Shop Apotheke',
                url: '/store?productType=giftcard&platform=Shop+Apotheke&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Shop+Apotheke&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'apollo-optik-gift-cards',
                displayName: 'Apollo-Optik',
                url: '/store?productType=giftcard&platform=Apollo-Optik&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Apollo-Optik&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'sephora-gift-cards',
                displayName: 'Sephora',
                url: '/store?productType=giftcard&platform=Sephora&worksIn=false',
                queryStr: 'productType=giftcard&platform=Sephora&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'blys-gift-cards',
                displayName: 'Blys',
                url: '/store?productType=giftcard&platform=Blys&worksIn=false',
                queryStr: 'productType=giftcard&platform=Blys&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'endota-gift-cards',
                displayName: 'Endota',
                url: '/store?productType=giftcard&platform=Endota&worksIn=false',
                queryStr: 'productType=giftcard&platform=Endota&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'nykaa-gift-cards',
                displayName: 'Nykaa',
                url: '/store?productType=giftcard&platform=Nykaa&worksIn=false',
                queryStr: 'productType=giftcard&platform=Nykaa&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'the-body-shop-gift-cards',
                displayName: 'The Body Shop',
                url: '/store?productType=giftcard&platform=The+Body+Shop&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=The+Body+Shop&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'apollo-pharmacy-gift-cards',
                displayName: 'Apollo Pharmacy',
                url: '/store?productType=giftcard&platform=Apollo+Pharmacy&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Apollo+Pharmacy&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'kama-ayurveda-gift-cards',
                displayName: 'Kama Ayurveda',
                url: '/store?productType=giftcard&platform=Kama+Ayurveda&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Kama+Ayurveda&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'body-craft-gift-cards',
                displayName: 'Body Craft',
                url: '/store?productType=giftcard&platform=Body+Craft&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Body+Craft&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'cult.fit-gift-cards',
                displayName: 'cult.fit',
                url: '/store?productType=giftcard&platform=cult.fit&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=cult.fit&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'himalaya-gift-cards',
                displayName: 'Himalaya',
                url: '/store?productType=giftcard&platform=Himalaya&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Himalaya&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'walgreens-gift-cards',
                displayName: 'Walgreens',
                url: '/store?productType=giftcard&platform=Walgreens&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Walgreens&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'ulta-beauty-gift-cards',
                displayName: 'Ulta Beauty',
                url: '/store?productType=giftcard&platform=Ulta+Beauty&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Ulta+Beauty&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'petco-gift-cards',
                displayName: 'Petco',
                url: '/store?productType=giftcard&platform=Petco&worksIn=false',
                queryStr: 'productType=giftcard&platform=Petco&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'cvs-pharmacy-gift-cards',
                displayName: 'CVS Pharmacy',
                url: '/store?productType=giftcard&platform=CVS+Pharmacy&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=CVS+Pharmacy&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'dollar-shave-club-gift-cards',
                displayName: 'Dollar Shave Club',
                url: '/store?productType=giftcard&platform=Dollar+Shave+Club&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Dollar+Shave+Club&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'watsons-gift-cards',
                displayName: 'Watsons',
                url: '/store?productType=giftcard&platform=Watsons&worksIn=false',
                queryStr: 'productType=giftcard&platform=Watsons&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'rituals-gift-cards',
                displayName: 'Rituals',
                url: '/store?productType=giftcard&platform=Rituals&worksIn=false',
                queryStr: 'productType=giftcard&platform=Rituals&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'mediterranean-cosmetics-gift-cards',
                displayName: 'Mediterranean Cosmetics',
                url: '/store?productType=giftcard&platform=Mediterranean+Cosmetics&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Mediterranean+Cosmetics&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'guardian-gift-cards',
                displayName: 'Guardian',
                url: '/store?productType=giftcard&platform=Guardian&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Guardian&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'aibi-gift-cards',
                displayName: 'AIBI',
                url: '/store?productType=giftcard&platform=AIBI&worksIn=false',
                queryStr: 'productType=giftcard&platform=AIBI&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'b-tech-gift-cards',
                displayName: 'B-Tech',
                url: '/store?productType=giftcard&platform=B-Tech&worksIn=false',
                queryStr: 'productType=giftcard&platform=B-Tech&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'farmatodo-gift-cards',
                displayName: 'Farmatodo',
                url: '/store?productType=giftcard&platform=Farmatodo&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Farmatodo&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
            ],
          },
          {
            name: lifestyle_cards_msg ?? '',
            displayName: 'Digital Wallets & Payments',
            idSub: 106,
            children: [
              {
                name: 'neosurf-gift-cards',
                displayName: 'Neosurf',
                url: '/store?productType=giftcard&platform=Neosurf&worksIn=false',
                queryStr: 'productType=giftcard&platform=Neosurf&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'astropay-gift-cards',
                displayName: 'AstroPay',
                url: '/store?productType=giftcard&platform=AstroPay&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=AstroPay&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'cashlib-gift-cards',
                displayName: 'CASHlib',
                url: '/store?productType=giftcard&platform=CASHlib&worksIn=false',
                queryStr: 'productType=giftcard&platform=CASHlib&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'flexepin-gift-cards',
                displayName: 'Flexepin',
                url: '/store?productType=giftcard&platform=Flexepin&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Flexepin&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'rewarble-gift-cards',
                displayName: 'Rewarble',
                url: '/store?productType=giftcard&platform=Rewarble&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Rewarble&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'cashtocode-gift-cards',
                displayName: 'CashtoCode',
                url: '/store?productType=giftcard&platform=CashtoCode&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=CashtoCode&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'jcb-premo-gift-cards',
                displayName: 'JCB Premo',
                url: '/store?productType=giftcard&platform=JCB+Premo&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=JCB+Premo&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'gocash-gift-cards',
                displayName: 'GoCash',
                url: '/store?productType=giftcard&platform=GoCash&worksIn=false',
                queryStr: 'productType=giftcard&platform=GoCash&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'obucks-gift-cards',
                displayName: 'Obucks',
                url: '/store?productType=giftcard&platform=Obucks&worksIn=false',
                queryStr: 'productType=giftcard&platform=Obucks&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
            ],
          },
          {
            name: lifestyle_cards_msg ?? '',
            displayName: 'Crypto Currencies',
            idSub: 106,
            children: [
              {
                name: 'bitnovo-gift-cards',
                displayName: 'Bitnovo',
                url: '/store?productType=giftcard&platform=Bitnovo&worksIn=false',
                queryStr: 'productType=giftcard&platform=Bitnovo&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'crypto-voucher-gift-cards',
                displayName: 'Crypto Voucher',
                url: '/store?productType=giftcard&platform=Crypto+Voucher&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Crypto+Voucher&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'bitcard-gift-cards',
                displayName: 'BitCard',
                url: '/store?productType=giftcard&platform=BitCard&worksIn=false',
                queryStr: 'productType=giftcard&platform=BitCard&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'binance-gift-cards',
                displayName: 'Binance',
                url: '/store?productType=giftcard&platform=Binance&worksIn=false',
                queryStr: 'productType=giftcard&platform=Binance&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'gift-me-crypto-gift-cards',
                displayName: 'Gift Me Crypto',
                url: '/store?productType=giftcard&platform=Gift+Me+Crypto&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Gift+Me+Crypto&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'azte.co-gift-cards',
                displayName: 'Azte.co',
                url: '/store?productType=giftcard&platform=Azte.co&worksIn=false',
                queryStr: 'productType=giftcard&platform=Azte.co&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
            ],
          },
          {
            name: lifestyle_cards_msg ?? '',
            displayName: 'Electronics & Gadgets',
            idSub: 106,
            children: [
              {
                name: 'cyberport-gift-cards',
                displayName: 'Cyberport',
                url: '/store?productType=giftcard&platform=Cyberport&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Cyberport&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'skullcandy-gift-cards',
                displayName: 'Skullcandy',
                url: '/store?productType=giftcard&platform=Skullcandy&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Skullcandy&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'imagine-gift-cards',
                displayName: 'Imagine',
                url: '/store?productType=giftcard&platform=Imagine&worksIn=false',
                queryStr: 'productType=giftcard&platform=Imagine&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'allegro-gift-cards',
                displayName: 'Allegro',
                url: '/store?productType=giftcard&platform=Allegro&worksIn=false',
                queryStr: 'productType=giftcard&platform=Allegro&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'morele.net-gift-cards',
                displayName: 'Morele.net',
                url: '/store?productType=giftcard&platform=Morele.net&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Morele.net&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'media-expert-gift-cards',
                displayName: 'Media Expert',
                url: '/store?productType=giftcard&platform=Media+Expert&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Media+Expert&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'home-depot-gift-cards',
                displayName: 'Home Depot',
                url: '/store?productType=giftcard&platform=Home+Depot&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Home+Depot&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'best-buy-gift-cards',
                displayName: 'Best Buy',
                url: '/store?productType=giftcard&platform=Best+Buy&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Best+Buy&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'teknosa-gift-cards',
                displayName: 'Teknosa',
                url: '/store?productType=giftcard&platform=Teknosa&worksIn=false',
                queryStr: 'productType=giftcard&platform=Teknosa&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'huawei-gift-cards',
                displayName: 'Huawei',
                url: '/store?productType=giftcard&platform=Huawei&worksIn=false',
                queryStr: 'productType=giftcard&platform=Huawei&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'sharaf-dg-gift-cards',
                displayName: 'Sharaf DG',
                url: '/store?productType=giftcard&platform=Sharaf+DG&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Sharaf+DG&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'fnac-gift-cards',
                displayName: 'FNAC',
                url: '/store?productType=giftcard&platform=FNAC&worksIn=false',
                queryStr: 'productType=giftcard&platform=FNAC&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'media-markt-gift-cards',
                displayName: 'Media Markt',
                url: '/store?productType=giftcard&platform=Media+Markt&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Media+Markt&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'media-world-gift-cards',
                displayName: 'Media World',
                url: '/store?productType=giftcard&platform=Media+World&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Media+World&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'expert-gift-cards',
                displayName: 'Expert',
                url: '/store?productType=giftcard&platform=Expert&worksIn=false',
                queryStr: 'productType=giftcard&platform=Expert&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'trony-gift-cards',
                displayName: 'Trony',
                url: '/store?productType=giftcard&platform=Trony&worksIn=false',
                queryStr: 'productType=giftcard&platform=Trony&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'best-denki-gift-cards',
                displayName: 'Best Denki',
                url: '/store?productType=giftcard&platform=Best+Denki&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Best+Denki&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'netonnet-gift-cards',
                displayName: 'NetonNet',
                url: '/store?productType=giftcard&platform=NetonNet&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=NetonNet&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'power-gift-cards',
                displayName: 'Power',
                url: '/store?productType=giftcard&platform=Power&worksIn=false',
                queryStr: 'productType=giftcard&platform=Power&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'electroholic-gift-cards',
                displayName: 'Electroholic',
                url: '/store?productType=giftcard&platform=Electroholic&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Electroholic&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'plaisio-gift-cards',
                displayName: 'PLAISIO',
                url: '/store?productType=giftcard&platform=PLAISIO&worksIn=false',
                queryStr: 'productType=giftcard&platform=PLAISIO&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'vivantis-gift-cards',
                displayName: 'Vivantis',
                url: '/store?productType=giftcard&platform=Vivantis&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Vivantis&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
            ],
          },
          {
            name: lifestyle_cards_msg ?? '',
            displayName: 'Other',
            idSub: 106,
            children: [
              {
                name: 'adler-gift-cards',
                displayName: 'Adler',
                url: '/store?productType=giftcard&platform=Adler&worksIn=false',
                queryStr: 'productType=giftcard&platform=Adler&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'apple-gift-cards',
                displayName: 'Apple',
                url: '/store?productType=giftcard&platform=Apple&worksIn=false',
                queryStr: 'productType=giftcard&platform=Apple&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'aral-gift-cards',
                displayName: 'Aral',
                url: '/store?productType=giftcard&platform=Aral&worksIn=false',
                queryStr: 'productType=giftcard&platform=Aral&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'zooplus-gift-cards',
                displayName: 'Zooplus',
                url: '/store?productType=giftcard&platform=Zooplus&worksIn=false',
                queryStr: 'productType=giftcard&platform=Zooplus&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'obi-gift-cards',
                displayName: 'OBI',
                url: '/store?productType=giftcard&platform=OBI&worksIn=false',
                queryStr: 'productType=giftcard&platform=OBI&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'jet-gift-cards',
                displayName: 'Jet',
                url: '/store?productType=giftcard&platform=Jet&worksIn=false',
                queryStr: 'productType=giftcard&platform=Jet&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'total-energies-gift-cards',
                displayName: 'Total Energies',
                url: '/store?productType=giftcard&platform=Total+Energies&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Total+Energies&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'futterhaus-gift-cards',
                displayName: 'Futterhaus',
                url: '/store?productType=giftcard&platform=Futterhaus&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Futterhaus&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'bcf-gift-cards',
                displayName: 'BCF',
                url: '/store?productType=giftcard&platform=BCF&worksIn=false',
                queryStr: 'productType=giftcard&platform=BCF&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'supercheap-auto-gift-cards',
                displayName: 'Supercheap Auto',
                url: '/store?productType=giftcard&platform=Supercheap+Auto&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Supercheap+Auto&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'elearngift-gift-cards',
                displayName: 'eLearnGift',
                url: '/store?productType=giftcard&platform=eLearnGift&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=eLearnGift&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'skype-gift-cards',
                displayName: 'Skype',
                url: '/store?productType=giftcard&platform=Skype&worksIn=false',
                queryStr: 'productType=giftcard&platform=Skype&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'bunnings-warehouse-gift-cards',
                displayName: 'Bunnings Warehouse',
                url: '/store?productType=giftcard&platform=Bunnings+Warehouse&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Bunnings+Warehouse&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'barbeques-galore-gift-cards',
                displayName: 'Barbeques Galore',
                url: '/store?productType=giftcard&platform=Barbeques+Galore&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Barbeques+Galore&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'duka-gift-cards',
                displayName: 'Duka',
                url: '/store?productType=giftcard&platform=Duka&worksIn=false',
                queryStr: 'productType=giftcard&platform=Duka&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'groupon-gift-cards',
                displayName: 'Groupon',
                url: '/store?productType=giftcard&platform=Groupon&worksIn=false',
                queryStr: 'productType=giftcard&platform=Groupon&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'build-a-bear-gift-cards',
                displayName: 'Build A Bear',
                url: '/store?productType=giftcard&platform=Build+A+Bear&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Build+A+Bear&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'express-gift-cards',
                displayName: 'Express',
                url: '/store?productType=giftcard&platform=Express&worksIn=false',
                queryStr: 'productType=giftcard&platform=Express&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'gcodes-gift-cards',
                displayName: 'GCodes',
                url: '/store?productType=giftcard&platform=GCodes&worksIn=false',
                queryStr: 'productType=giftcard&platform=GCodes&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'conoco-gift-cards',
                displayName: 'Conoco',
                url: '/store?productType=giftcard&platform=Conoco&worksIn=false',
                queryStr: 'productType=giftcard&platform=Conoco&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'petrol-ofisi-gift-cards',
                displayName: 'Petrol Ofisi',
                url: '/store?productType=giftcard&platform=Petrol+Ofisi&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Petrol+Ofisi&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'vinid-gift-cards',
                displayName: 'VinID',
                url: '/store?productType=giftcard&platform=VinID&worksIn=false',
                queryStr: 'productType=giftcard&platform=VinID&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'gosend-gift-cards',
                displayName: 'GoSend',
                url: '/store?productType=giftcard&platform=GoSend&worksIn=false',
                queryStr:
                  'productType=gift-cards&platform=GoSend&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'grab-gift-cards',
                displayName: 'Grab',
                url: '/store?productType=giftcard&platform=Grab&worksIn=false',
                queryStr: 'productType=giftcard&platform=Grab&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'gojek-gift-cards',
                displayName: 'Gojek',
                url: '/store?productType=giftcard&platform=Gojek&worksIn=false',
                queryStr: 'productType=giftcard&platform=Gojek&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'cabelas-gift-cards',
                displayName: "Cabela's",
                url: '/store?productType=giftcard&platform=Cabela%27s&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Cabela%27s&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'petro-gift-cards',
                displayName: 'Petro',
                url: '/store?productType=giftcard&platform=Petro&worksIn=false',
                queryStr: 'productType=giftcard&platform=Petro&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'sodexo-gift-cards',
                displayName: 'Sodexo',
                url: '/store?productType=giftcard&platform=Sodexo&worksIn=false',
                queryStr: 'productType=giftcard&platform=Sodexo&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'shell-gift-cards',
                displayName: 'Shell',
                url: '/store?productType=giftcard&platform=Shell&worksIn=false',
                queryStr: 'productType=giftcard&platform=Shell&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'aubert-gift-cards',
                displayName: 'Aubert',
                url: '/store?productType=giftcard&platform=Aubert&worksIn=false',
                queryStr: 'productType=giftcard&platform=Aubert&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'tamoil-gift-cards',
                displayName: 'Tamoil',
                url: '/store?productType=giftcard&platform=Tamoil&worksIn=false',
                queryStr: 'productType=giftcard&platform=Tamoil&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'q8-gift-cards',
                displayName: 'Q8',
                url: '/store?productType=giftcard&platform=Q8&worksIn=false',
                queryStr: 'productType=giftcard&platform=Q8&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'saco-gift-cards',
                displayName: 'SACO',
                url: '/store?productType=giftcard&platform=SACO&worksIn=false',
                queryStr: 'productType=giftcard&platform=SACO&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'jarir-bookstore-gift-cards',
                displayName: 'Jarir Bookstore',
                url: '/store?productType=giftcard&platform=Jarir+Bookstore&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Jarir+Bookstore&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'intertoys-gift-cards',
                displayName: 'Intertoys',
                url: '/store?productType=giftcard&platform=Intertoys&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Intertoys&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'cepsa-gift-cards',
                displayName: 'Cepsa',
                url: '/store?productType=giftcard&platform=Cepsa&worksIn=false',
                queryStr: 'productType=giftcard&platform=Cepsa&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'galp-gift-cards',
                displayName: 'Galp',
                url: '/store?productType=giftcard&platform=Galp&worksIn=false',
                queryStr: 'productType=giftcard&platform=Galp&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'sportina-gift-cards',
                displayName: 'SPORTINA',
                url: '/store?productType=giftcard&platform=SPORTINA&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=SPORTINA&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'oazis-gift-cards',
                displayName: 'OAZIS',
                url: '/store?productType=giftcard&platform=OAZIS&worksIn=false',
                queryStr: 'productType=giftcard&platform=OAZIS&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'national-trust-gift-cards',
                displayName: 'National Trust',
                url: '/store?productType=giftcard&platform=National+Trust&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=National+Trust&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'mix-it-up-gift-cards',
                displayName: 'Mix It Up',
                url: '/store?productType=giftcard&platform=Mix+It+Up&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Mix+It+Up&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'halfords-gift-cards',
                displayName: 'Halfords',
                url: '/store?productType=giftcard&platform=Halfords&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Halfords&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'sinopec-gift-cards',
                displayName: 'Sinopec',
                url: '/store?productType=giftcard&platform=Sinopec&worksIn=false',
                queryStr: 'productType=giftcard&platform=Sinopec&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
              {
                name: 'petrochina-gift-cards',
                displayName: 'PetroChina',
                url: '/store?productType=giftcard&platform=PetroChina&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=PetroChina&worksIn=false',
                idSubChild: 1076,
                navbar: true,
              },
            ],
          },
          // {
          //   name: egift_cards_msg ?? '',
          //   displayName: 'eGift Cards',
          //   idSub: 107,
          //   children: [
          //     {
          //       name: 'nike-gift-cards',
          //       displayName: 'Nike',
          //       url: '/store?q=nike&productType%5B%5D=giftcard&page=1',
          //       queryStr: 'platform=Nike&productType%5B%5D=giftcard',
          //       idSubChild: 1060,
          //       navbar: true,
          //     },
          //     {
          //       name: 'adidas-gift-cards',
          //       displayName: 'Adidas',
          //       url: '/store?q=adidas&productType%5B%5D=giftcard&page=1',
          //       queryStr: 'q=adidas&productType%5B%5D=giftcard',
          //       idSubChild: 1061,
          //       navbar: true,
          //     },
          //     {
          //       name: 'h&m-gift-cards',
          //       displayName: 'H&M',
          //       url: '/store?q=h&m&productType%5B%5D=giftcard&page=1',
          //       queryStr: 'q=h%26m&productType%5B%5D=giftcard',
          //       idSubChild: 1062,
          //       navbar: true,
          //     },
          //     {
          //       name: 'old-navy-gift-cards',
          //       displayName: 'Old Navy',
          //       url: '/store?q=old+navy&productType%5B%5D=giftcard&page=1',
          //       queryStr: 'q=old+navy&productType%5B%5D=giftcard',
          //       idSubChild: 1063,
          //       navbar: true,
          //     },
          //     {
          //       name: 'columbia-sportswear-gift-cards',
          //       displayName: 'Columbia Sportswear',
          //       url: '/store?q=columbia+sportswear&productType%5B%5D=giftcard&page=1',
          //       queryStr: 'q=columbia+sportswear&productType%5B%5D=giftcard',
          //       idSubChild: 1064,
          //       navbar: true,
          //     },
          //     {
          //       name: 'ikea-gift-cards',
          //       displayName: 'Ikea',
          //       url: '/store?q=ikea&productType%5B%5D=giftcard&page=1',
          //       queryStr: 'q=ikea&productType%5B%5D=giftcard',
          //       idSubChild: 1065,
          //       navbar: true,
          //     },
          //     {
          //       name: 'zalando-gift-cards',
          //       displayName: 'Zalando',
          //       url: '/store?q=zalando&productType%5B%5D=giftcard&page=1',
          //       queryStr: 'q=zalando&productType%5B%5D=giftcard',
          //       idSubChild: 1066,
          //       navbar: true,
          //     },
          //     // {
          //     //   name: 'zalando-giftcards',
          //     //   displayName: 'Zalando',
          //     //   url: '/store?q=zalando&productType%5B%5D=giftcard&page=1',
          //     //   queryStr: 'q=zalando&productType%5B%5D=giftcard',
          //     //   idSubChild: 1066,
          //     //   navbar: true,
          //     // },
          //     {
          //       name: 'airbnb-gift-cards',
          //       displayName: 'Airbnb',
          //       url: '/store?q=airbnb&productType%5B%5D=giftcard&page=1',
          //       queryStr: 'q=airbnb&productType%5B%5D=giftcard',
          //       idSubChild: 1067,
          //       navbar: true,
          //     },
          //     {
          //       name: 'gift-cards',
          //       displayName: view_all_msg ?? '',
          //       url: '/store?productType=giftcard',
          //       queryStr: 'productType=giftcard',
          //       idSubChild: 1050,
          //       navbar: true,
          //     },
          //     // {
          //     //   name: 'walmart-giftcards',
          //     //   displayName: 'Walmart',
          //     //   url: '/store?q=walmart&productType%5B%5D=giftcard&page=1',
          //     //   queryStr: 'q=walmart&productType%5B%5D=giftcard',
          //     //   idSubChild: 1068,
          //     // },
          //     // {
          //     //   name: 'decathlon-giftcards',
          //     //   displayName: 'Decathlon Gift Cards',
          //     //   url: '/store?q=decathlon&productType%5B%5D=giftcard&page=1',
          //     //   queryStr: 'q=decathlon&productType%5B%5D=giftcard',
          //     //   idSubChild: 1091,
          //     // },
          //     // {
          //     //   name: 'starbucks-giftcards',
          //     //   displayName: 'Starbucks Gift Cards',
          //     //   url: '/store?q=starbucks&productType%5B%5D=giftcard&page=1',
          //     //   queryStr: 'q=starbucks&productType%5B%5D=giftcard',
          //     //   idSubChild: 1092,
          //     // },
          //   ],
          // },
          // {
          //   name: subscriptions_msg ?? '',
          //   displayName: 'Subscriptions',
          //   idSub: 108,
          //   children: [
          //     {
          //       name: 'xbox-game-pass',
          //       displayName: xbox_game_pass_msg ?? '',
          //       url: '/store?q=xbox+game+pass&productType%5B%5D=subscription&page=1',
          //       queryStr: 'q=xbox+game+pass&productType%5B%5D=subscription',
          //       idSubChild: 1069,
          //       navbar: true,
          //     },
          //     {
          //       name: 'ea-play',
          //       displayName: 'EA Play',
          //       url: '/store?q=ea+play&productType%5B%5D=subscription&page=1',
          //       queryStr: 'platform=EA+Play&productType%5B%5D=subscription',
          //       idSubChild: 1070,
          //       navbar: true,
          //     },
          //     {
          //       name: 'Nintendo Switch Online',
          //       displayName: 'Nintendo',
          //       url: '/store?q=nitendo+switch+online&productType%5B%5D=subscription&page=1',
          //       queryStr:
          //         'q=nitendo+switch+online&productType%5B%5D=subscription',
          //       idSubChild: 1071,
          //       navbar: true,
          //     },
          //     {
          //       name: 'discord-nitro',
          //       displayName: 'Discord Nitro',
          //       url: '/store?q=discord+nitro&productType%5B%5D=subscription&page=1',
          //       queryStr: 'q=discord+nitro&productType%5B%5D=subscription',
          //       idSubChild: 1072,
          //       navbar: true,
          //     },
          //     {
          //       name: 'tinder-gold',
          //       displayName: 'Tinder Gold',
          //       url: '/store?q=tinder+gold&productType%5B%5D=subscription&page=1',
          //       queryStr: 'q=tinder+gold&productType%5B%5D=subscription',
          //       idSubChild: 1073,
          //       navbar: true,
          //     },
          //     {
          //       name: 'spotify',
          //       displayName: 'Spotify',
          //       url: '/store?q=spotify&productType%5B%5D=subscription&page=1',
          //       queryStr: 'q=spotify&productType%5B%5D=subscription',
          //       idSubChild: 1074,
          //       navbar: true,
          //     },
          //     {
          //       name: 'playstation-plus',
          //       displayName: 'Playstation Plus',
          //       url: '/store?q=playstation+plus&productType%5B%5D=subscription&page=1',
          //       queryStr: 'q=playstation+plus&productType%5B%5D=subscription',
          //       idSubChild: 1075,
          //       navbar: true,
          //     },

          //     {
          //       name: 'subscription',
          //       displayName: view_all_msg ?? '',
          //       url: '/store?productType=subscription&page=1',
          //       queryStr: 'productType=subscription',
          //       idSubChild: 1076,
          //       navbar: true,
          //     },

          //     // {
          //     //   name: 'canva-pro',
          //     //   displayName: 'Canva Pro',
          //     //   url: '/store?q=canva+pro&productType%5B%5D=subscription&page=1',
          //     //   queryStr: 'q=canva+pro&productType%5B%5D=subscription',
          //     //   idSubChild: 1100,
          //     // },
          //     // {
          //     //   name: 'noping+game+tunnel',
          //     //   displayName: 'NoPing Game Tunnel',
          //     //   url: '/store?q=noping+game+tunnel&productType%5B%5D=subscription&page=1',
          //     //   queryStr: 'q=noping+game+tunnel&productType%5B%5D=subscription',
          //     //   idSubChild: 1101,
          //     // },
          //     // {
          //     //   name: 'nord+vpn',
          //     //   displayName: 'Nord Vpn',
          //     //   url: '/store?q=nord+vpn&productType%5B%5D=subscription&page=1',
          //     //   queryStr: 'q=nord+vpn&productType%5B%5D=subscription',
          //     //   idSubChild: 1102,
          //     // },
          //     // {
          //     //   name: 'youtube+premium+trial',
          //     //   displayName: 'Youtube Premium Trial',
          //     //   url: '/store?q=youtube+premium+trial&productType%5B%5D=subscription&page=1',
          //     //   queryStr:
          //     //     'q=youtube+premium+trial&productType%5B%5D=subscription',
          //     //   idSubChild: 1103,
          //     // },
          //     // {
          //     //   name: 'crunchyroll+premium',
          //     //   displayName: 'Crunchyroll Premium',
          //     //   url: '/store?q=crunchyroll+premium&productType%5B%5D=subscription&page=1',
          //     //   queryStr: 'q=crunchyroll+premium&productType%5B%5D=subscription',
          //     //   idSubChild: 1104,
          //     // },
          //     // {
          //     //   name: 'midjourney',
          //     //   displayName: 'Midjourney',
          //     //   url: '/store?q=midjourney&productType%5B%5D=subscription&page=1',
          //     //   queryStr: 'q=midjourney&productType%5B%5D=subscription',
          //     //   idSubChild: 1105,
          //     // },
          //   ],
          // },

          // {
          //   name: by_region_msg ?? '',
          //   displayName: 'By Region',
          //   idSub: 109,
          //   children: [
          //     {
          //       name: 'gift-cards-global',
          //       displayName: global_msg ?? '',
          //       url: '/store?productType=giftcard&page=1&region=3&worksIn=false',
          //       queryStr: 'productType=giftcard&region=3&worksIn=false',
          //       idSubChild: 1077,
          //       navbar: true,
          //     },
          //     {
          //       name: 'gift-cards-europe',
          //       displayName: europe_msg ?? '',
          //       url: '/store?productType=giftcard&page=1&region=1&worksIn=false',
          //       queryStr: 'productType=giftcard&region=1&worksIn=false',
          //       idSubChild: 1078,
          //       navbar: true,
          //     },
          //     {
          //       name: 'gift-cards-united-states',
          //       displayName: united_states_msg ?? '',
          //       url: '/store?productType=giftcard&page=1&region=2&worksIn=false',
          //       queryStr: 'productType=giftcard&region=2&worksIn=false',
          //       idSubChild: 1079,
          //       navbar: true,
          //     },
          //     {
          //       name: 'gift-cards-turkey',
          //       displayName: 'Turkey',
          //       url: '/store?productType=giftcard&page=1&region=21&worksIn=false',
          //       queryStr: 'productType=giftcard&region=21&worksIn=false',
          //       idSubChild: 1080,
          //       navbar: true,
          //     },
          //     {
          //       name: 'gift-cards-poland',
          //       displayName: 'Poland',
          //       url: '/store?productType=giftcard&page=1&region=19&worksIn=false',
          //       queryStr: 'productType=giftcard&region=19&worksIn=false',
          //       idSubChild: 1081,
          //       navbar: true,
          //     },
          //     {
          //       name: 'gift-cards-germany',
          //       displayName: 'Germany',
          //       url: '/store?productType=giftcard&page=1&region=13&worksIn=false',
          //       queryStr: 'productType=giftcard&region=13&worksIn=false',
          //       idSubChild: 1082,
          //       navbar: true,
          //     },
          //     {
          //       name: 'gift-cards-united-kingdom',
          //       displayName: united_kingdom_msg ?? '',
          //       url: '/store?productType=giftcard&page=1&region=8&worksIn=false',
          //       queryStr: 'productType=giftcard&region=8&worksIn=false',
          //       idSubChild: 1083,
          //       navbar: true,
          //     },
          //     {
          //       name: 'gift-cards-india',
          //       displayName: 'India',
          //       url: '/store?productType=giftcard&page=1&region=16&worksIn=false',
          //       queryStr: 'productType=giftcard&region=16&worksIn=false',
          //       idSubChild: 1084,
          //       navbar: true,
          //     },
          //     {
          //       name: 'gift-cards',
          //       displayName: view_all_msg ?? '',
          //       url: '/store?productType=giftcard&page=1',
          //       queryStr: 'productType=giftcard&page=1',
          //       idSubChild: 1023,
          //       navbar: true,
          //     },
          //     // {
          //     //   name: 'gift-cards-brazil',
          //     //   displayName: 'Brazil',
          //     //   url: '/store?productType[]=giftcard&page=1&region[]=15',
          //     //   queryStr: 'productType=giftcard&region=15',
          //     //   idSubChild: 1085,
          //     // },
          //     // {
          //     //   name: 'gift-cards-france',
          //     //   displayName: 'France',
          //     //   url: '/store?productType[]=giftcard&page=1&region[]=34',
          //     //   queryStr: 'productType=giftcard&region=34',
          //     //   idSubChild: 1037,
          //     // },
          //     // {
          //     //   name: 'gift-cards-japan',
          //     //   displayName: 'Japan',
          //     //   url: '/store?productType[]=giftcard&page=1&region[]=17',
          //     //   queryStr: 'productType=giftcard&region=17',
          //     //   idSubChild: 1037,
          //     // },
          //     // {
          //     //   name: 'gift-cards-italy',
          //     //   displayName: 'Italy',
          //     //   url: '/store?productType[]=giftcard&page=1&region[]=36',
          //     //   queryStr: 'productType=giftcard&region=36',
          //     //   idSubChild: 1037,
          //     // },
          //   ],
          // },

          // {
          //   name: 'priceRange',
          //   displayName: 'Price Range',
          //   idSub: 103,
          //   children: [
          //     {
          //       name: 'garena-free-fire-diamonds',
          //       displayName: 'Garena Free Fire Diamonds',
          //       url: '/store?q=gareena+free+fire+diamonds&productType%5B%5D=game+point&page=1',
          //       queryStr:
          //         'q=gareena+free+fire+diamonds&productType%5B%5D=game+point',
          //       idSubChild: 1038,
          //     }
          //   ]
          // }
        ],
        navbar: true,
      },
      {
        name: 'gaming-gift-cards',
        displayName: 'Gaming Gift Cards' ?? '',
        url: '/store?productType[]=game&page=1',
        queryStr: 'productType=giftcard',
        id: 2,
        navbar: true,
        subDivision: [
          {
            name: 'pc-gift-cards' ?? '',
            displayName: 'PC Gift Cards',
            idSub: 102,
            children: [
              {
                name: 'steam-gift-cards',
                displayName: 'Steam' ?? '',
                url: '/store?productType=giftcard&platform=Steam&worksIn=false',
                queryStr: 'productType=giftcard&platform=Steam&worksIn=false',
                idSubChild: 1015,
                navbar: true,
              },
              {
                name: 'roblox-gift-cards',
                displayName: 'Roblox' ?? '',
                url: '/store?productType=giftcard&platform=Roblox&worksIn=false',
                queryStr: 'productType=giftcard&platform=Roblox&worksIn=false',
                idSubChild: 1015,
                navbar: true,
              },
              {
                name: 'valorant-gift-cards',
                displayName: 'Valorant' ?? '',
                url: '/store?productType=giftcard&q=valorant&worksIn=false',
                queryStr: 'productType=giftcard&q=valorant&worksIn=false',
                idSubChild: 1015,
                navbar: true,
              },
              {
                name: 'meta-quest-gift-cards',
                displayName: 'Meta Quest' ?? '',
                url: '/store?productType=giftcard&q=meta+quest&worksIn=false',
                queryStr: 'productType=giftcard&q=meta+quest&worksIn=false',
                idSubChild: 1015,
                navbar: true,
              },
              {
                name: 'world-of-warcraft-gift-cards',
                displayName: 'World of Warcraft' ?? '',
                url: '/store?productType=giftcard&q=world+of+warcraft&worksIn=false',
                queryStr:
                  'productType=giftcard&q=world+of+warcraft&worksIn=false',
                idSubChild: 1015,
                navbar: true,
              },
              {
                name: 'blizzard-gift-cards',
                displayName: 'Blizzard' ?? '',
                url: '/store?productType=giftcard&q=blizzard&worksIn=false',
                queryStr: 'productType=giftcard&q=blizzard&worksIn=false',
                idSubChild: 1015,
                navbar: true,
              },
              {
                name: 'league-of-legends-gift-cards',
                displayName: 'League of Legends' ?? '',
                url: '/store?productType=giftcard&q=league-of-legends&worksIn=false',
                queryStr:
                  'productType=giftcard&q=league-of-legends&worksIn=false',
                idSubChild: 1015,
                navbar: true,
              },
              {
                name: 'gamestop-gift-cards',
                displayName: 'GameStop' ?? '',
                url: '/store?productType=giftcard&platform=GameStop&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=GameStop&worksIn=false',
                idSubChild: 1015,
                navbar: true,
              },
            ],
          },
          {
            name: 'console-gift-cards' ?? '',
            displayName: 'Console Gift Cards',
            idSub: 102,
            children: [
              {
                name: 'psn-gift-cards',
                displayName: 'PSN Gift Cards' ?? '',
                url: '/store?productType=giftcard&platform=PSN&worksIn=false',
                queryStr: 'productType=giftcard&platform=PSN&worksIn=false',
                idSubChild: 1015,
                navbar: true,
              },
              {
                name: 'xbox-gift-cards',
                displayName: 'Xbox Gift Cards' ?? '',
                url: '/store?productType=giftcard&platform=Xbox+Live&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Xbox+Live&worksIn=false',
                idSubChild: 1015,
                navbar: true,
              },
              {
                name: 'nintendo-gift-cards',
                displayName: 'Nintendo Gift Cards' ?? '',
                url: '/store?productType=giftcard&platform=Nintendo&worksIn=false',
                queryStr:
                  'productType=giftcard&platform=Nintendo&worksIn=false',
                idSubChild: 1015,
                navbar: true,
              },
            ],
          },
          {
            name: game_point_msg ?? '',
            displayName: 'Game Points',
            idSub: 104,
            children: [
              {
                name: 'ea-sports-fc-24-gamepoints',
                displayName: 'FC 24 POINTS',
                url: '/store?q=ea+sports+fc+24&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=ea+sports+fc+24&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1036,
                navbar: true,
              },
              {
                name: 'pubg-mobile',
                displayName: 'PUBG Mobile UC' ?? '',
                url: '/store?q=pubg+mobile&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=pubg+mobile&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1033,
                navbar: true,
              },

              {
                name: 'garena-free-fire-diamonds',
                displayName: 'Gareena Free Fire Diamonds' ?? '',
                url: '/store?q=gareena+free+fire+diamonds&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=gareena+free+fire+diamonds&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1034,
                navbar: true,
              },
              {
                name: 'fortnite-gamepoints',
                displayName: 'Fortnite V-Bucks',
                url: '/store?q=fortnite&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=fortnite&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1035,
                navbar: true,
              },
              {
                name: 'minecraft-gamepoints',
                displayName: 'Minecraft: Minecoins Pack' ?? '',
                url: '/store?q=minecraft&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=minecraft&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1037,
                navbar: true,
              },
              // {
              //   name: 'fifa-fut-points',
              //   displayName: 'FIFA FUT Points',
              //   url: '/store?q=fifa+fut+points&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=fifa+fut+points&productType%5B%5D=game+point',
              //   idSubChild: 1039,
              // },
              // {
              //   name: 'pubg-new-state-nc-gamepoints',
              //   displayName: pubg_new_state_nc_msg ?? '',
              //   url: '/store?q=pubg+new+state+nc&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=pubg+new+state+nc&productType%5B%5D=game+point',
              //   idSubChild: 1037,
              //   navbar: true,
              // },
              // {
              //   name: 'gta-online-cash',
              //   displayName: 'GTA Online Cash',
              //   url: '/store?q=gta+online+cash&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=gta+online+cash&productType%5B%5D=game+point',
              //   idSubChild: 1041,
              // },
              {
                name: 'gta-online-gamepoints',
                displayName: 'GTA Cards' ?? '',
                url: '/store?q=grand+theft+auto+online&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=grand+theft+auto+online&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1038,
                navbar: true,
              },
              {
                name: 'valorant-gamepoints',
                displayName: 'Valorant Points' ?? '',
                url: '/store?q=valorant&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=valorant&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1038,
                navbar: true,
              },
              {
                name: 'mobile-legends-gamepoints',
                displayName: 'Mobile Legends' ?? '',
                url: '/store?q=mobile+legends&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=mobile+legends&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1038,
                navbar: true,
              },
              {
                name: 'overwatch-gamepoints',
                displayName: 'Overwatch Coins' ?? '',
                url: '/store?q=overwatch&productType%5B%5D=game+point&page=1&worksIn=false',
                queryStr:
                  'q=overwatch&productType%5B%5D=game+point&worksIn=false',
                idSubChild: 1038,
                navbar: true,
              },
              // {
              //   name: 'pubg-mobile-uc',
              //   displayName: 'PUBG Mobile UC',
              //   url: '/store?q=pubg+mobile+uc&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=pubg+mobile+uc&productType%5B%5D=game+point',
              //   idSubChild: 1042,
              // },
              // {
              //   name: 'riot-points',
              //   displayName: riot_points_msg ?? '',
              //   url: '/store?q=riot+points&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=riot+points&productType%5B%5D=game+point',
              //   idSubChild: 1039,
              //   navbar: true,
              // },
              // {
              //   name: 'apex-legends-gamepoints',
              //   displayName: 'Apex Legends',
              //   url: '/store?q=apex+legends&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=apex+legends&productType%5B%5D=game+point',
              //   idSubChild: 1040,
              //   navbar: true,
              // },
              {
                name: 'gamepoints',
                displayName: view_all_msg ?? '',
                url: '/store?productType=game+point&page=1&worksIn=false',
                queryStr: 'productType=game+point&worksIn=false',
                idSubChild: 1041,
                navbar: true,
                viewAll: true,
              },
              // {
              //   name: 'fifa-23-gamepoints',
              //   displayName: 'FIFA 23',
              //   url: '/store?q=fifa+23&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=fifa+23&productType%5B%5D=game+point',
              //   idSubChild: 1052,
              // },
              // {
              //   name: 'valorant-gamepoints',
              //   displayName: 'Valorant',
              //   url: '/store?q=valorant&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=valorant&productType%5B%5D=game+point',
              //   idSubChild: 1053,
              // },
              // {
              //   name: 'mobile-legends-gamepoints',
              //   displayName: 'Mobile Legends',
              //   url: '/store?q=mobile+legends&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=mobile+legends&productType%5B%5D=game+point',
              //   idSubChild: 1054,
              // },
              // {
              //   name: 'minecraft-gamepoints',
              //   displayName: 'Minecraft',
              //   url: '/store?q=minecraft&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=minecraft&productType%5B%5D=game+point',
              //   idSubChild: 1055,
              // },
              // {
              //   name: 'league-of-legends-gamepoints',
              //   displayName: 'League Of Legends',
              //   url: '/store?q=league+of+legends&productType%5B%5D=game+point&page=1',
              //   queryStr: 'q=league+of+legends&productType%5B%5D=game+point',
              //   idSubChild: 1056,
              // },
            ],
          },
        ],
      },
      {
        name: 'subscriptions',
        displayName: 'Subscriptions' ?? '',
        url: '/store?page=1&productType[]=subscription',
        queryStr: 'productType=subscription',
        id: 3,
        subDivision: [
          {
            name: 'gaming-subscriptions' ?? '',
            displayName: 'Gaming Subscriptions',
            idSub: 105,
            children: [
              {
                name: 'xbox-subscriptions',
                displayName: 'Xbox Live',
                url: '/store?productType[]=subscription&page=1&platform[]=Xbox+Live&worksIn=false',
                queryStr:
                  'productType=subscription&platform=Xbox+Live&worksIn=false',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'nintendo-subscriptions',
                displayName: 'Nintendo',
                url: '/store?productType[]=subscription&page=1&platform[]=Nintendo&worksIn=false',
                queryStr:
                  'productType=subscription&platform=Nintendo&worksIn=false',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'psn-subscriptions',
                displayName: 'PSN',
                url: '/store?productType[]=subscription&page=1&platform[]=PSN&worksIn=false',
                queryStr: 'productType=subscription&platform=PSN&worksIn=false',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'ubisoft-connect-subscriptions',
                displayName: 'Ubisoft Connect',
                url: '/store?productType[]=subscription&page=1&platform[]=Ubisoft+Connect&worksIn=false',
                queryStr:
                  'productType=subscription&platform=Ubisoft+Connect&worksIn=false',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'ea-play-subscriptions',
                displayName: 'EA Play',
                url: '/store?productType[]=subscription&page=1&platform[]=EA+Play&worksIn=false',
                queryStr:
                  'productType=subscription&platform=EA+Play&worksIn=false',
                idSubChild: 1045,
                navbar: true,
              },
            ],
          },
          {
            name: 'entertainment-subscriptions' ?? '',
            displayName: 'Entertainment',
            idSub: 105,
            children: [
              {
                name: 'crunchyroll-subscriptions',
                displayName: 'Crunchyroll',
                url: '/store?productType[]=subscription&page=1&platform[]=Crunchyroll&worksIn=false',
                queryStr:
                  'productType=subscription&platform=Crunchyroll&worksIn=false',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'amazon-subscriptions',
                displayName: 'Amazon',
                url: '/store?productType[]=subscription&page=1&platform[]=Amazon&worksIn=false',
                queryStr:
                  'productType=subscription&platform=Amazon&worksIn=false',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'youtube-subscriptions',
                displayName: 'Youtube',
                url: '/store?productType[]=subscription&page=1&platform[]=Youtube&worksIn=false',
                queryStr:
                  'productType=subscription&platform=Youtube&worksIn=false',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'discord-subscriptions',
                displayName: 'Discord',
                url: '/store?productType[]=subscription&page=1&platform[]=Discord&worksIn=false',
                queryStr:
                  'productType=subscription&platform=Discord&worksIn=false',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'waipu-tv-subscriptions',
                displayName: 'Waipu.tv',
                url: '/store?productType[]=subscription&page=1&platform[]=Waipu.tv&worksIn=false',
                queryStr:
                  'productType=subscription&platform=Waipu.tv&worksIn=false',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'ea-play-subscriptions',
                displayName: 'Disney+',
                url: '/store?productType[]=subscription&page=1&platform[]=Disney+&worksIn=false',
                queryStr:
                  'productType=subscription&platform=Disney+&worksIn=false',
                idSubChild: 1045,
                navbar: true,
              },
            ],
          },
          {
            name: 'more-subscriptions' ?? '',
            displayName: 'More Subscriptions',
            idSub: 105,
            children: [
              {
                name: 'tinder-subscriptions',
                displayName: 'Tinder',
                url: '/store?productType[]=subscription&page=1&platform[]=Tinder&worksIn=false',
                queryStr:
                  'productType=subscription&platform=Tinder&worksIn=false',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'nordvpn-subscriptions',
                displayName: 'NordVPN',
                url: '/store?productType[]=subscription&page=1&platform[]=NordVPN&worksIn=false',
                queryStr:
                  'productType=subscription&platform=NordVPN&worksIn=false',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'apple-subscriptions',
                displayName: 'Apple',
                url: '/store?productType[]=subscription&page=1&platform[]=Apple&worksIn=false',
                queryStr:
                  'productType=subscription&platform=Apple&worksIn=false',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'doordash-subscriptions',
                displayName: 'DoorDash',
                url: '/store?productType[]=subscription&page=1&platform[]=DoorDash&worksIn=false',
                queryStr:
                  'productType=subscription&platform=DoorDash&worksIn=false',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'grubhub-subscriptions',
                displayName: 'Grubhub',
                url: '/store?productType[]=subscription&page=1&platform[]=Grubhub&worksIn=false',
                queryStr:
                  'productType=subscription&platform=Grubhub&worksIn=false',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'tibia-subscriptions',
                displayName: 'Tibia',
                url: '/store?productType[]=subscription&page=1&platform[]=Tibia&worksIn=false',
                queryStr:
                  'productType=subscription&platform=Tibia&worksIn=false',
                idSubChild: 1045,
                navbar: true,
              },
            ],
          },
        ],
        navbar: true,
      },
      {
        name: 'softwares',
        displayName: 'Softwares' ?? '',
        url: '/store?page=1&productType[]=software',
        queryStr: 'productType=software',
        id: 3,
        subDivision: [
          {
            name: 'security-softwares' ?? '',
            displayName: 'Security and Antivirus',
            idSub: 105,
            children: [
              {
                name: 'avast-ultimate',
                displayName: 'Avast Ultimate',
                url: '/store?q=avast+ultimate&worksIn=false&productType=software',
                queryStr: 'q=avast+ultimate&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'norton',
                displayName: 'Norton',
                url: '/store?q=norton&worksIn=false&productType=software',
                queryStr: 'q=norton&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'avast-premium-security',
                displayName: 'Avast Premium Security',
                url: '/store?q=avast+premium+security&worksIn=false&productType=software',
                queryStr:
                  'q=avast+premium+security&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'avg-ultimate',
                displayName: 'AVG Ultimate',
                url: '/store?q=avg+ultimate&worksIn=false&productType=software',
                queryStr: 'q=avg+ultimate&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'mcafee-livesafe',
                displayName: 'McAfee LiveSafe',
                url: '/store?q=McAfee+LiveSafe&worksIn=false&productType=software',
                queryStr:
                  'q=McAfee+LiveSafe&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'panda-dome-essential',
                displayName: 'Panda Dome Essential',
                url: '/store?q=Panda+Dome+Essential&worksIn=false&productType=software',
                queryStr:
                  'q=Panda+Dome+Essential&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'mcafee-total-protection',
                displayName: 'McAfee Total Protection',
                url: '/store?q=McAfee+Total+Protection&worksIn=false&productType=software',
                queryStr:
                  'q=McAfee+Total+Protection&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'mcafee-antivirus',
                displayName: 'McAfee AntiVirus',
                url: '/store?q=McAfee+AntiVirus&worksIn=false&productType=software',
                queryStr:
                  'q=McAfee+AntiVirus&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'norton-360',
                displayName: 'Norton 360',
                url: '/store?q=Norton+360&worksIn=false&productType=software',
                queryStr: 'q=Norton+360&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'bitdefender-internet-security',
                displayName: 'Bitdefender Internet Security',
                url: '/store?q=Bitdefender+Internet+Security&worksIn=false&productType=software',
                queryStr:
                  'q=Bitdefender+Internet+Security&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'trend-micro-maximum-security',
                displayName: 'Trend Micro Maximum Security',
                url: '/store?q=Trend+Micro+Maximum+Security&worksIn=false&productType=software',
                queryStr:
                  'q=Trend+Micro+Maximum+Security&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
            ],
          },
          {
            name: 'vpn-softwares' ?? '',
            displayName: 'VPN',
            idSub: 105,
            children: [
              {
                name: 'exitlag-softwares',
                displayName: 'ExitLag',
                url: '/store?q=exitlag&worksIn=false&productType=software',
                queryStr: 'q=exitlag&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'avg-secure-vpn',
                displayName: 'AVG Secure VPN',
                url: '/store?q=avg+secure+vpn&worksIn=false&productType=software',
                queryStr: 'q=avg+secure+vpn&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'surfshark-vpn',
                displayName: 'Surfshark VPN',
                url: '/store?q=surfshark+vpn&worksIn=false&productType=software',
                queryStr: 'q=surfshark+vpn&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'avast-secureline-vpn',
                displayName: 'Avast SecureLine VPN',
                url: '/store?q=avast+secureline+vpn&worksIn=false&productType=software',
                queryStr:
                  'q=avast+secureline+vpn&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'f-secure-freedome-vpn',
                displayName: 'F-Secure Freedome VPN',
                url: '/store?q=f+secure+freedome+vpn&worksIn=false&productType=software',
                queryStr:
                  'q=f+secure+freedome+vpn&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
            ],
          },
          {
            name: 'system-optimization-softwares' ?? '',
            displayName: 'System Optimization',
            idSub: 105,
            children: [
              {
                name: 'avast-driver-updater',
                displayName: 'Avast Driver Updater',
                url: '/store?q=avast+driver+updater&worksIn=false&productType=software',
                queryStr:
                  'q=avast+driver+updater&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'avast-cleanup-premium',
                displayName: 'Avast Cleanup Premium',
                url: '/store?q=avast+leanup+premium&worksIn=false&productType=software',
                queryStr:
                  'q=avast+cleanup+premium&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'ccleaner-professional-plus',
                displayName: 'CCleaner Professional Plus',
                url: '/store?q=ccleaner+professional+plus&worksIn=false&productType=software',
                queryStr:
                  'q=ccleaner+professional+plus&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'avg-driver-updater',
                displayName: 'AVG Driver Updater',
                url: '/store?q=avg+driver+updater&worksIn=false&productType=software',
                queryStr:
                  'q=avg+driver+updater&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'driver-booster-10',
                displayName: 'DRIVER BOOSTER 10',
                url: '/store?q=driver-booster-10&worksIn=false&productType=software',
                queryStr:
                  'q=driver-booster-10&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
            ],
          },
          {
            name: 'backup-recovery-softwares' ?? '',
            displayName: 'Backup Recovery',
            idSub: 105,
            children: [
              {
                name: 'aomei-backupper-professional',
                displayName: 'AOMEI Backupper Professional',
                url: '/store?q=aomei+backupper+professional&worksIn=false&productType=software',
                queryStr:
                  'q=aomei+backupper+professional&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'aomei-partition-assistant-pro',
                displayName: 'AOMEI Partition Assistant Pro',
                url: '/store?q=aomei+partition+assistant+pro&worksIn=false&productType=software',
                queryStr:
                  'q=aomei+partition+assistant+pro&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'aomei-partition-assistant',
                displayName: 'AOMEI Partition Assistant',
                url: '/store?q=aomei+partition+assistant&worksIn=false&productType=software',
                queryStr:
                  'q=aomei+partition+assistant&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'aomei-backupper-workstation',
                displayName: 'AOMEI Backupper Workstation',
                url: '/store?q=aomei+backupper+workstation&worksIn=false&productType=software',
                queryStr:
                  'q=aomei+backupper+workstation&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'easeus-partition-master',
                displayName: 'EaseUS Partition Master',
                url: '/store?q=easeus+partition+master&worksIn=false&productType=software',
                queryStr:
                  'q=easeus+partition+master&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'easeus-todo-backup-home',
                displayName: 'EaseUs Todo Backup Home',
                url: '/store?q=easeus+todo+backup+home&worksIn=false&productType=software',
                queryStr:
                  'q=easeus+todo+backup+home&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'easeus-data-recovery-wizard',
                displayName: 'EaseUs Data Recovery Wizard',
                url: '/store?q=easeus+data+recovery+wizard&worksIn=false&productType=software',
                queryStr:
                  'q=easeus+data+recovery+wizard&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'fone-tool-professional-edition',
                displayName: 'Fone Tool Professional Edition',
                url: '/store?q=fone-tool-professional-edition&worksIn=false&productType=software',
                queryStr:
                  'q=fone-tool-professional-edition&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
            ],
          },
          {
            name: 'more-softwares' ?? '',
            displayName: 'More Softwares',
            idSub: 105,
            children: [
              {
                name: 'windows-11',
                displayName: 'Windows 11',
                url: '/store?q=windows+11&worksIn=false&productType=software',
                queryStr: 'q=windows+11&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'ashampoo-pdf-pro',
                displayName: 'Ashampoo PDF Pro 3 - 1 Device Lifetime',
                url: '/store?q=ashampoo+pdf+pro&worksIn=false&productType=software',
                queryStr:
                  'q=ashampoo+pdf+pro&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'dolby-atmos-for-headphones',
                displayName: 'Dolby Atmos for Headphones',
                url: '/store?q=dolby+atmos+for+headphones&worksIn=false&productType=software',
                queryStr:
                  'q=dolby+atmos+for+headphones&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'movavi-video-suite',
                displayName: 'Movavi Video Suite 2024',
                url: '/store?q=movavi+video+suite&worksIn=false&productType=software',
                queryStr:
                  'q=movavi+video+suite&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: '3dmark',
                displayName: '3DMark',
                url: '/store?q=3DMark&worksIn=false&productType=software',
                queryStr: 'q=3DMark&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'adgaurd-premium',
                displayName: 'AdGuard Premium',
                url: '/store?q=adgaurd-premium&worksIn=false&productType=software',
                queryStr:
                  'q=adgaurd-premium&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
              {
                name: 'adgaurd-family',
                displayName: 'AdGuard Family',
                url: '/store?q=adgaurd-family&worksIn=false&productType=software',
                queryStr: 'q=adgaurd-family&worksIn=false&productType=software',
                idSubChild: 1045,
                navbar: true,
              },
            ],
          },
          // {
          //   name: 'entertainment-softwares' ?? '',
          //   displayName: 'Entertainment',
          //   idSub: 105,
          //   children: [
          //     {
          //       name: 'crunchyroll-softwares',
          //       displayName: 'Crunchyroll',
          //       url: '/store?productType[]=software&page=1&platform[]=Crunchyroll&worksIn=false',
          //       queryStr:
          //         'productType=software&platform=Crunchyroll&worksIn=false',
          //       idSubChild: 1045,
          //       navbar: true,
          //     },
          //     {
          //       name: 'amazon-softwares',
          //       displayName: 'Amazon',
          //       url: '/store?productType[]=software&page=1&platform[]=Amazon&worksIn=false',
          //       queryStr: 'productType=software&platform=Amazon&worksIn=false',
          //       idSubChild: 1045,
          //       navbar: true,
          //     },
          //     {
          //       name: 'youtube-softwares',
          //       displayName: 'Youtube',
          //       url: '/store?productType[]=software&page=1&platform[]=Youtube&worksIn=false',
          //       queryStr: 'productType=software&platform=Youtube&worksIn=false',
          //       idSubChild: 1045,
          //       navbar: true,
          //     },
          //     {
          //       name: 'discord-softwares',
          //       displayName: 'Discord',
          //       url: '/store?productType[]=software&page=1&platform[]=Discord&worksIn=false',
          //       queryStr: 'productType=software&platform=Discord&worksIn=false',
          //       idSubChild: 1045,
          //       navbar: true,
          //     },
          //     {
          //       name: 'waipu-tv-softwares',
          //       displayName: 'Waipu.tv',
          //       url: '/store?productType[]=software&page=1&platform[]=Waipu.tv&worksIn=false',
          //       queryStr:
          //         'productType=software&platform=Waipu.tv&worksIn=false',
          //       idSubChild: 1045,
          //       navbar: true,
          //     },
          //     {
          //       name: 'ea-play-softwares',
          //       displayName: 'Disney+',
          //       url: '/store?productType[]=software&page=1&platform[]=Disney+&worksIn=false',
          //       queryStr: 'productType=software&platform=Disney+&worksIn=false',
          //       idSubChild: 1045,
          //       navbar: true,
          //     },
          //     {
          //       name: 'softwares',
          //       displayName: 'View all',
          //       url: '/store?productType[]=software&page=1&worksIn=false',
          //       queryStr: 'productType=software&worksIn=false',
          //       idSubChild: 1045,
          //       navbar: true,
          //       viewAll: true,
          //     },
          //   ],
          // },
          // {
          //   name: 'more-softwares' ?? '',
          //   displayName: 'More softwares',
          //   idSub: 105,
          //   children: [
          //     {
          //       name: 'tinder-software',
          //       displayName: 'Tinder',
          //       url: '/store?productType[]=software&page=1&platform[]=Tinder&worksIn=false',
          //       queryStr: 'productType=software&platform=Tinder&worksIn=false',
          //       idSubChild: 1045,
          //       navbar: true,
          //     },
          //     {
          //       name: 'nordvpn-softwares',
          //       displayName: 'NordVPN',
          //       url: '/store?productType[]=software&page=1&platform[]=NordVPN&worksIn=false',
          //       queryStr: 'productType=software&platform=NordVPN&worksIn=false',
          //       idSubChild: 1045,
          //       navbar: true,
          //     },
          //     {
          //       name: 'apple-softwares',
          //       displayName: 'Apple',
          //       url: '/store?productType[]=software&page=1&platform[]=Apple&worksIn=false',
          //       queryStr: 'productType=software&platform=Apple&worksIn=false',
          //       idSubChild: 1045,
          //       navbar: true,
          //     },
          //     {
          //       name: 'doordash-softwares',
          //       displayName: 'DoorDash',
          //       url: '/store?productType[]=software&page=1&platform[]=DoorDash&worksIn=false',
          //       queryStr:
          //         'productType=software&platform=DoorDash&worksIn=false',
          //       idSubChild: 1045,
          //       navbar: true,
          //     },
          //     {
          //       name: 'grubhub-softwares',
          //       displayName: 'Grubhub',
          //       url: '/store?productType[]=software&page=1&platform[]=Grubhub&worksIn=false',
          //       queryStr: 'productType=software&platform=Grubhub&worksIn=false',
          //       idSubChild: 1045,
          //       navbar: true,
          //     },
          //     {
          //       name: 'tibia-softwares',
          //       displayName: 'Tibia',
          //       url: '/store?productType[]=software&page=1&platform[]=Tibia&worksIn=false',
          //       queryStr: 'productType=software&platform=Tibia&worksIn=false',
          //       idSubChild: 1045,
          //       navbar: true,
          //     },

          //     {
          //       name: 'softwares',
          //       displayName: 'View all',
          //       url: '/store?productType[]=software&page=1&worksIn=false',
          //       queryStr: 'productType=software&worksIn=false',
          //       idSubChild: 1045,
          //       navbar: true,
          //       viewAll: true,
          //     },
          //   ],
          // },
        ],
        navbar: true,
      },
      // {
      //   name: 'game-points',
      //   displayName: 'Game Points',
      //   url: '/store?page=1&productType[]=game+point',
      //   queryStr: 'productType=game+point',
      //   id: 4,

      //   subDivision: [
      //     {
      //       name: 'by platform',
      //       displayName: 'By Game',
      //       idSub: 105,
      //       children: [
      //         {
      //           name: 'garena-free-fire-diamonds',
      //           displayName: 'Garena Free Fire Diamonds',
      //           url: '/store?q=gareena+free+fire+diamonds&productType%5B%5D=game+point&page=1',
      //           queryStr:
      //             'q=gareena+free+fire+diamonds&productType%5B%5D=game+point',
      //           idSubChild: 1038,
      //         },
      //         {
      //           name: 'fifa-fut-points',
      //           displayName: 'FIFA FUT Points',
      //           url: '/store?q=fifa+fut+points&productType%5B%5D=game+point&page=1',
      //           queryStr: 'q=fifa+fut+points&productType%5B%5D=game+point',
      //           idSubChild: 1039,
      //         },
      //         {
      //           name: 'pubg-new-state-nc',
      //           displayName: 'PUBG New State NC',
      //           url: '/store?q=pubg+new+state+nc&productType%5B%5D=game+point&page=1',
      //           queryStr: 'q=pubg+new+state+nc&productType%5B%5D=game+point',
      //           idSubChild: 1040,
      //         },
      //         {
      //           name: 'gta-online-cash',
      //           displayName: 'GTA Online Cash',
      //           url: '/store?q=gta+online+cash&productType%5B%5D=game+point&page=1',
      //           queryStr: 'q=gta+online+cash&productType%5B%5D=game+point',
      //           idSubChild: 1041,
      //         },
      //         {
      //           name: 'pubg-mobile-uc',
      //           displayName: 'PUBG Mobile UC',
      //           url: '/store?q=pubg+mobile+uc&productType%5B%5D=game+point&page=1',
      //           queryStr: 'q=pubg+mobile+uc&productType%5B%5D=game+point',
      //           idSubChild: 1042,
      //         },
      //         {
      //           name: 'riot-points',
      //           displayName: 'Riot Points',
      //           url: '/store?q=riot+points&productType%5B%5D=game+point&page=1',
      //           queryStr: 'q=riot+points&productType%5B%5D=game+point',
      //           idSubChild: 1043,
      //         },
      //       ],
      //     },
      //   ],
      // },
      // {
      //   name: 'steam',
      //   displayName: 'Steam',
      //   url: '/store?page=1&platform=Steam',
      //   queryStr: 'platform=Steam',
      //   id: 11,
      //   subDivision: [
      //     {
      //       name: platform_products_msg?.replace('[_PLATFORM_]', 'Steam') ?? '',
      //       displayName: 'Steam Products',
      //       idSub: 125,
      //       children: [
      //         {
      //           name: 'steam-games',
      //           displayName: steam_games_msg ?? '',
      //           url: '/store?platform[]=Steam&page=1&productType[]=game',
      //           queryStr: 'platform=Steam&productType=game',
      //           idSubChild: 12501,
      //           navbar: true,
      //         },
      //         {
      //           name: 'steam-dlc',
      //           displayName: steam_dlcs_msg ?? '',
      //           url: '/store?platform[]=Steam&page=1&productType[]=dlc',
      //           queryStr: 'platform=Steam&productType=dlc',
      //           idSubChild: 12502,
      //           navbar: true,
      //         },
      //         {
      //           name: 'steam-gift-cards',
      //           displayName: steam_giftcard_msg ?? '',
      //           url: '/store?platform[]=Steam&page=1&productType[]=giftcard',
      //           queryStr: 'platform=Steam&productType=giftcard',
      //           idSubChild: 12403,
      //           navbar: true,
      //         },
      //         {
      //           name: 'steam',
      //           displayName: view_all_msg ?? '',
      //           url: '/store?platform=Steam',
      //           queryStr: 'platform=Steam',
      //           idSubChild: 12504,
      //           navbar: true,
      //         },
      //       ],
      //     },
      //     // {
      //     //   name: 'PSN Gift Cards',
      //     //   displayName: 'PSN Gift Cards',
      //     //   idSub: 116,
      //     //   children: [
      //     //     {
      //     //       name: '[pdp]playstation-store-50-eur-gift-card-germany-digital-code-p9885504',
      //     //       displayName: 'PlayStation Store €50 (DE)',
      //     //       url: '/store?q=playstation+store+€50&platform[]=PSN&productType[]=giftcard&page=1&region=13',
      //     //       queryStr:
      //     //         'q=playstation+store+€50&platform=PSN&productType=giftcard&region=13',
      //     //       idSubChild: 1236,
      //     //     },
      //     //     {
      //     //       name: '[pdp]playstation-store-z100-pln-gift-card-poland-digital-code-p9884648',
      //     //       displayName: 'PlayStation Store zł100 (PL)',
      //     //       url: '/store?q=playstation+store+zł100&platform[]=PSN&productType[]=giftcard&page=1&region=13',
      //     //       queryStr:
      //     //         'q=playstation+store+zł100&platform=PSN&productType=giftcard&region=13',
      //     //       idSubChild: 1237,
      //     //     },
      //     //     {
      //     //       name: '[pdp]playstation-network-card-10-us-p217573',
      //     //       displayName: 'PlayStation Store $10 (US)',
      //     //       url: '/store?q=playstation+store+$10&platform[]=PSN&productType[]=giftcard&page=1&region=13',
      //     //       queryStr:
      //     //         'q=playstation+store+$10&platform=PSN&productType=giftcard&region=13',
      //     //       idSubChild: 1238,
      //     //     },
      //     //     {
      //     //       name: '[pdp]playstation-store-100-eur-gift-card-spain-digital-code-p9885789',
      //     //       displayName: 'PlayStation Store €100 (ES)',
      //     //       url: '/store?q=playstation+store+€100&platform[]=PSN&productType[]=giftcard&page=1&region=13',
      //     //       queryStr:
      //     //         'q=playstation+store+€100&platform=PSN&productType=giftcard&region=13',
      //     //       idSubChild: 1239,
      //     //     },
      //     //     {
      //     //       name: '[pdp]playstation-store-15-eur-gift-card-France-digital-code-p9885524',
      //     //       displayName: 'PlayStation Store $15 (FR)',
      //     //       url: '/store?q=playstation+store+$15&platform[]=PSN&productType[]=giftcard&page=1&region=13',
      //     //       queryStr:
      //     //         'q=playstation+store+$15&platform=PSN&productType=giftcard&region=13',
      //     //       idSubChild: 1240,
      //     //     },
      //     //     {
      //     //       name: '[pdp]playstation-store-4000-inr-gift-card-india-digital-code-p9885517',
      //     //       displayName: 'PlayStation Store ₹4000 (IN)',
      //     //       url: '/store?q=playstation+store+€25&platform[]=PSN&productType[]=giftcard&page=1&region=13',
      //     //       queryStr:
      //     //         'q=playstation+store+€25&platform=PSN&productType=giftcard&region=13',
      //     //       idSubChild: 1241,
      //     //     },
      //     //     {
      //     //       name: '[pdp]playstation-store-40-eur-gift-card-netherlands-digital-code-p9885805',
      //     //       displayName: 'PlayStation Store €40 (NL))',
      //     //       url: '/store?q=playstation+store+€15&platform[]=PSN&productType[]=giftcard&page=1&region=13',
      //     //       queryStr:
      //     //         'q=playstation+store+€15&platform=PSN&productType=giftcard&region=13',
      //     //       idSubChild: 1242,
      //     //     },
      //     //     {
      //     //       name: 'psn-giftcards',
      //     //       displayName: 'View All',
      //     //       url: '/store?platform[]=PSN&page=1&productType[]=giftcard',
      //     //       queryStr: 'platform=PSN&productType=giftcard',
      //     //       idSubChild: 1243,
      //     //     },
      //     //   ],
      //     // },
      //     // {
      //     //   name: 'PS Plus',
      //     //   displayName: 'PS Plus',
      //     //   idSub: 117,
      //     //   children: [
      //     //     {
      //     //       name: '[pdp]playstation-plus-3-months-membership-us-digital-code-p9887554',
      //     //       displayName: 'PlayStation Plus 3 Months (US)',
      //     //       url: '/store?q=playstation+plus+3+months&platform[]=PSN&productType[]=subscription&page=1&region=1',
      //     //       queryStr:
      //     //         'q=playstation+plus+3+months&platform=PSN&productType=subscription&region=1',
      //     //       idSubChild: 1244,
      //     //     },
      //     //     {
      //     //       name: '[pdp]playstation-plus-3-months-membership-uk-digital-code-p9887558',
      //     //       displayName: 'PlayStation Plus 3 Months (UK)',
      //     //       url: '/store?q=playstation+plus+3+months&platform[]=PSN&productType[]=subscription&page=1&region=2',
      //     //       queryStr:
      //     //         'q=playstation+plus+3+months&platform=PSN&productType=subscription&region=2',
      //     //       idSubChild: 1245,
      //     //     },
      //     //     {
      //     //       name: '[pdp]playstation-plus-1-month-membership-uk-digital-code-p9887557',
      //     //       displayName: 'PlayStation Plus 1 Month (UK)',
      //     //       url: '/store?q=playstation+plus+3+months&platform[]=PSN&productType[]=subscription&page=1&region=2',
      //     //       queryStr:
      //     //         'q=playstation+plus+3+months&platform=PSN&productType=subscription&region=2',
      //     //       idSubChild: 1246,
      //     //     },
      //     //     {
      //     //       name: '[pdp]playstation-plus-essential-12-months-membership-br-digital-code-p9893381',
      //     //       displayName: 'PlayStation Plus 12 Months (BR)',
      //     //       url: '/store?q=playstation+plus+12+months&platform[]=PSN&productType[]=subscription&page=1&region=15',
      //     //       queryStr:
      //     //         'q=playstation+plus+12+months&platform=PSN&productType=subscription&region=15',
      //     //       idSubChild: 1247,
      //     //     },
      //     //     {
      //     //       name: '[pdp]playstation-plus-1-month-membership-us-digital-code-p9887553',
      //     //       displayName: 'PlayStation Plus 1 Month (US)',
      //     //       url: '/store?q=playstation+plus+1+month&platform[]=PSN&productType[]=subscription&page=1&region=1',
      //     //       queryStr:
      //     //         'q=playstation+plus+1+month&platform=PSN&productType=subscription&region=1',
      //     //       idSubChild: 1248,
      //     //     },
      //     //     {
      //     //       name: '[pdp]playstation-plus-1-month-membership-de-digital-code-p9887555',
      //     //       displayName: 'PlayStation Plus 1 Month (DE)',
      //     //       url: '/store?q=playstation+plus+1+month&platform[]=PSN&productType[]=subscription&page=1&region=13',
      //     //       queryStr:
      //     //         'q=playstation+plus+1+month&platform=PSN&productType=subscription&region=13',
      //     //       idSubChild: 1249,
      //     //     },
      //     //     {
      //     //       name: '[pdp]playstation-plus-3-months-membership-de-digital-code-p9887556',
      //     //       displayName: 'PlayStation Plus 3 Months (DE)',
      //     //       url: '/store?q=playstation+plus+3+months&platform[]=PSN&productType[]=subscription&page=1&region=13',
      //     //       queryStr:
      //     //         'q=playstation+plus+3+months&platform=PSN&productType=subscription&region=13',
      //     //       idSubChild: 1250,
      //     //     },
      //     //     {
      //     //       name: 'psn-plus',
      //     //       displayName: 'View All',
      //     //       url: '/store?platform[]=PSN&page=1&productType[]=subscription',
      //     //       queryStr: 'platform=PSN&productType=subscription',
      //     //       idSubChild: 1251,
      //     //     },
      //     //   ],
      //     // },
      //     {
      //       name: by_region_msg ?? '',
      //       displayName: 'by Region',
      //       idSub: 126,
      //       children: [
      //         {
      //           name: 'steam-global',
      //           displayName: 'Steam Global',
      //           url: '/store?platform[]=Steam&page=1&region=3&worksIn=false',
      //           queryStr: 'platform=Steam&region=3&worksIn=false',
      //           idSubChild: 12601,
      //           navbar: true,
      //         },
      //         {
      //           name: 'steam-europe',
      //           displayName: 'Steam Europe',
      //           url: '/store?platform[]=Steam&page=1&region=1&worksIn=false',
      //           queryStr: 'platform=Steam&region=1&worksIn=false',
      //           idSubChild: 12602,
      //           navbar: true,
      //         },
      //         {
      //           name: 'steam-united-states',
      //           displayName: 'Steam United States',
      //           url: '/store?platform[]=Steam&page=1&region=2&worksIn=false',
      //           queryStr: 'platform=Steam&region=2&worksIn=false',
      //           idSubChild: 12603,
      //           navbar: true,
      //         },
      //         {
      //           name: 'steam-turkey',
      //           displayName: 'Steam Turkey',
      //           url: '/store?platform=Steam&page=1&region=21&worksIn=false',
      //           queryStr: 'platform=Steam&region=21&worksIn=false',
      //           idSubChild: 12604,
      //           navbar: true,
      //         },
      //         {
      //           name: 'steam-row',
      //           displayName: 'Steam ROW',
      //           url: '/store?platform[]=Steam&page=1&region=10&worksIn=false',
      //           queryStr: 'platform=Steam&region=10',
      //           idSubChild: 12605,
      //           navbar: true,
      //         },
      //         {
      //           name: 'steam-india',
      //           displayName: 'Steam India',
      //           url: '/store?platform[]=Steam&page=1&region=16&worksIn=false',
      //           queryStr: 'platform=Steam&region=16&worksIn=false',
      //           idSubChild: 12607,
      //           navbar: true,
      //         },

      //         {
      //           name: 'steam-hong-kong',
      //           displayName: 'Steam Hong Kong',
      //           url: '/store?platform[]=Steam&page=1&region=22&worksIn=false',
      //           queryStr: 'platform=Steam&region=22&worksIn=false',
      //           idSubChild: 12608,
      //           navbar: true,
      //         },
      //         {
      //           name: 'steam-united-kingdom',
      //           displayName: 'Steam United Kingdom',
      //           url: '/store?platform[]=Steam&page=1&region=8&worksIn=false',
      //           queryStr: 'platform=Steam&&region=8&worksIn=false',
      //           idSubChild: 12609,
      //           navbar: true,
      //         },

      //         {
      //           name: 'steam',
      //           displayName: view_all_msg ?? '',
      //           url: '/store?platform=Steam',
      //           queryStr: 'platform=Steam',
      //           idSubChild: 12610,
      //           navbar: true,
      //         },

      //         // {
      //         //   name: 'steam-greece',
      //         //   displayName: 'Greece',
      //         //   url: '/store?platform[]=Steam&productType[]=giftcard&page=1&region=49',
      //         //   queryStr: 'platform=Steam&productType=giftcard&region=49',
      //         //   idSubChild: 1259,
      //         // },

      //         // {
      //         //   name: 'steam-taiwan',
      //         //   displayName: 'Taiwan',
      //         //   url: '/store?platform[]=PSN&productType[]=giftcard&page=1&region=23',
      //         //   queryStr: 'platform=PSN&productType=giftcard&region=23',
      //         //   idSubChild: 1260,
      //         // },
      //       ],
      //     },
      //     {
      //       name: by_genre_msg ?? '',
      //       displayName: 'by Genre',
      //       idSub: 127,
      //       children: [
      //         {
      //           name: 'steam-action-games',
      //           displayName: action_msg ?? '',
      //           url: '/store?genre[]=Action&page=1',
      //           queryStr: 'platform=Steam&genre=Action',
      //           idSubChild: 12701,
      //           navbar: true,
      //         },
      //         {
      //           name: 'steam-adventure-games',
      //           displayName: adventure_msg ?? '',
      //           url: '/store?page=1&genre[]=Adventure',
      //           queryStr: 'platform=Steam&genre=Adventure',
      //           idSubChild: 12702,
      //           navbar: true,
      //         },
      //         {
      //           name: 'steam-rpg-games',
      //           displayName: rpg_msg ?? '',
      //           url: '/store?page=1&genre[]=Rpg',
      //           queryStr: 'platform=Steam&genre=RPG',
      //           idSubChild: 12703,
      //           navbar: true,
      //         },

      //         {
      //           name: 'steam-indie-games',
      //           displayName: indie_msg ?? '',
      //           url: '/store?productType[]=game&page=1&genre[]=Indie',
      //           queryStr: 'platform=Steam&genre=Indie',
      //           idSubChild: 12704,
      //           navbar: true,
      //         },

      //         {
      //           name: 'steam-strategy-games',
      //           displayName: strategy_msg ?? '',
      //           url: '/store?productType[]=game&page=1&genre[]=Strategy',
      //           queryStr: 'platform=Steam&genre=Strategy',
      //           idSubChild: 12704,
      //           navbar: true,
      //         },
      //         {
      //           name: 'steam-tps-games',
      //           displayName: tps_msg ?? '',
      //           url: '/store?productType[]=game&page=1&genre[]=TPS',
      //           queryStr: 'platform=Steam&genre=TPS',
      //           idSubChild: 12705,
      //           navbar: true,
      //         },
      //         {
      //           name: 'steam-simulation-games',
      //           displayName: simulation_msg ?? '',
      //           url: '/store?productType[]=game&page=1&genre[]=Simulation',
      //           queryStr: 'platform=Steam&genre=Simulation',
      //           idSubChild: 12706,
      //           navbar: true,
      //         },
      //         {
      //           name: 'steam-sports-games',
      //           displayName: sports_msg ?? '',
      //           url: '/store?productType[]=game&page=1&genre[]=Sports',
      //           queryStr: 'platform=Steam&genre=Sports',
      //           idSubChild: 12707,
      //           navbar: true,
      //         },

      //         {
      //           name: 'steam-multiplayer-games',
      //           displayName: 'Massively Multiplayer',
      //           url: '/store?productType[]=game&page=1&genre[]=Massively+Multiplayer',
      //           queryStr: 'platform=Steam&genre=Massively+Multiplayer',
      //           idSubChild: 12708,
      //           navbar: true,
      //         },
      //         {
      //           name: 'steam-games',
      //           displayName: view_all_msg ?? '',
      //           url: '/store?platform=Steam&productType=game',
      //           queryStr: 'platform=Steam&productType=game',
      //           idSubChild: 12709,
      //           navbar: true,
      //         },
      //       ],
      //     },
      //   ],
      //   navbar: true,
      // },
      // {
      //   name: 'psn',
      //   displayName: 'PSN',
      //   url: '/store?page=1&platform=PSN',
      //   queryStr: 'platform=PSN',
      //   id: 5,
      //   subDivision: [
      //     {
      //       name: platform_products_msg?.replace('[_PLATFORM_]', 'PSN') ?? '',
      //       displayName: 'PSN Products',
      //       idSub: 115,
      //       children: [
      //         {
      //           name: 'psn-games',
      //           displayName: psn_games_msg ?? '',
      //           url: '/store?platform[]=PSN&page=1&productType[]=game',
      //           queryStr: 'platform=PSN&productType=game',
      //           idSubChild: 1228,
      //           navbar: true,
      //         },
      //         {
      //           name: 'psn-dlc',
      //           displayName: 'PSN DLCs',
      //           url: '/store?platform[]=PSN&page=1&productType[]=dlc',
      //           queryStr: 'platform=PSN&productType=dlc',
      //           idSubChild: 1228,
      //           navbar: true,
      //         },
      //         {
      //           name: 'psn-gift-cards',
      //           displayName: psn_gift_cards_msg ?? '',
      //           url: '/store?platform[]=PSN&page=1&productType[]=giftcard',
      //           queryStr: 'platform=PSN&productType=giftcard',
      //           idSubChild: 1228,
      //           navbar: true,
      //         },
      //         {
      //           name: 'psn-subsciption',
      //           displayName: 'PSN Subscription',
      //           url: '/store?platform[]=PSN&page=1&productType[]=subscription',
      //           queryStr: 'platform=PSN&productType=subscription',
      //           idSubChild: 1228,
      //           navbar: true,
      //         },
      //         {
      //           name: 'psn',
      //           displayName: view_all_msg ?? '',
      //           url: '/store?platform=PSN',
      //           queryStr: 'platform=PSN',
      //           idSubChild: 1111,
      //           navbar: true,
      //         },
      //         // {
      //         //   name: 'marvels-spider-man',
      //         //   displayName: "Marvel's Spider-Man",
      //         //   url: '/store?q=marvels+spider+man&platform[]=PSNe&page=1&productType[]=game',
      //         //   queryStr: 'q=marvels+spider+man&platform=PSN&productType=game',
      //         //   idSubChild: 1044,
      //         // },
      //         // {
      //         //   name: '[pdp]wall-world-eu-ps5-psn-digital-code-p9891104',
      //         //   displayName: 'Wall World',
      //         //   url: '/store?q=wall+world&platform[]=PSNe&page=1&productType[]=game',
      //         //   queryStr: 'q=wall+world&platform=PSN&productType=game',
      //         //   idSubChild: 1229,
      //         // },
      //         // {
      //         //   name: '[pdp]ea-sports-fc-24-eu-ps5-psn-digital-code-p9887017',
      //         //   displayName: 'EA SPORTS FC 24',
      //         //   url: '/store?q=ea+sports+fc+24&platform[]=PSNe&page=1&productType[]=game',
      //         //   queryStr: 'q=ea+sports+fc+24&platform=PSN&productType=game',
      //         //   idSubChild: 1230,
      //         // },
      //         // {
      //         //   name: '[pdp]final-fantasy-xvi-ps5-psn-digital-code-p9884554',
      //         //   displayName: 'Final Fantasy XVI',
      //         //   url: '/store?q=final+fantasy+xvi&platform[]=PSNe&page=1&productType[]=game',
      //         //   queryStr: 'q=final+fantasy+xvi&platform=PSN&productType=game',
      //         //   idSubChild: 1231,
      //         // },
      //         // {
      //         //   name: '[pdp]payday-3-eu-ps5-psn-digital-code-p9892532',
      //         //   displayName: 'Payday 3',
      //         //   url: '/store?q=payday+3&platform[]=PSNe&page=1&productType[]=game',
      //         //   queryStr: 'q=payday+3&platform=PSN&productType=game',
      //         //   idSubChild: 1232,
      //         // },
      //         // {
      //         //   name: '[pdp]prince-of-persia-the-lost-crown-deluxe-edition-eu-ps5-psn-digital-code-p9893760',
      //         //   displayName: 'Prince of Persia: The Lost Crown',
      //         //   url: '/store?q=prince+of+persia+the+lost+crown&platform[]=PSNe&page=1&productType[]=game',
      //         //   queryStr:
      //         //     'q=prince+of+persia+the+lost+crown&platform=PSN&productType=game',
      //         //   idSubChild: 1233,
      //         // },
      //         // {
      //         //   name: '[pdp]just-dance-2024-eu-ps5-psn-digital-code-p9887906',
      //         //   displayName: 'Just Dance 2024',
      //         //   url: '/store?q=just+dance+2024&platform[]=PSNe&page=1&productType[]=game',
      //         //   queryStr: 'q=just+dance+2024&platform=PSN&productType=game',
      //         //   idSubChild: 1234,
      //         // },
      //         // {
      //         //   name: 'psn-games',
      //         //   displayName: 'View All',
      //         //   url: '/store?platform[]=PSN&page=1&productType[]=game',
      //         //   queryStr: 'platform=PSN&productType=game',
      //         //   idSubChild: 1235,
      //         // },
      //       ],
      //     },
      //     // {
      //     //   name: 'PSN Gift Cards',
      //     //   displayName: 'PSN Gift Cards',
      //     //   idSub: 116,
      //     //   children: [
      //     //     {
      //     //       name: '[pdp]playstation-store-50-eur-gift-card-germany-digital-code-p9885504',
      //     //       displayName: 'PlayStation Store €50 (DE)',
      //     //       url: '/store?q=playstation+store+€50&platform[]=PSN&productType[]=giftcard&page=1&region=13',
      //     //       queryStr:
      //     //         'q=playstation+store+€50&platform=PSN&productType=giftcard&region=13',
      //     //       idSubChild: 1236,
      //     //     },
      //     //     {
      //     //       name: '[pdp]playstation-store-z100-pln-gift-card-poland-digital-code-p9884648',
      //     //       displayName: 'PlayStation Store zł100 (PL)',
      //     //       url: '/store?q=playstation+store+zł100&platform[]=PSN&productType[]=giftcard&page=1&region=13',
      //     //       queryStr:
      //     //         'q=playstation+store+zł100&platform=PSN&productType=giftcard&region=13',
      //     //       idSubChild: 1237,
      //     //     },
      //     //     {
      //     //       name: '[pdp]playstation-network-card-10-us-p217573',
      //     //       displayName: 'PlayStation Store $10 (US)',
      //     //       url: '/store?q=playstation+store+$10&platform[]=PSN&productType[]=giftcard&page=1&region=13',
      //     //       queryStr:
      //     //         'q=playstation+store+$10&platform=PSN&productType=giftcard&region=13',
      //     //       idSubChild: 1238,
      //     //     },
      //     //     {
      //     //       name: '[pdp]playstation-store-100-eur-gift-card-spain-digital-code-p9885789',
      //     //       displayName: 'PlayStation Store €100 (ES)',
      //     //       url: '/store?q=playstation+store+€100&platform[]=PSN&productType[]=giftcard&page=1&region=13',
      //     //       queryStr:
      //     //         'q=playstation+store+€100&platform=PSN&productType=giftcard&region=13',
      //     //       idSubChild: 1239,
      //     //     },
      //     //     {
      //     //       name: '[pdp]playstation-store-15-eur-gift-card-France-digital-code-p9885524',
      //     //       displayName: 'PlayStation Store $15 (FR)',
      //     //       url: '/store?q=playstation+store+$15&platform[]=PSN&productType[]=giftcard&page=1&region=13',
      //     //       queryStr:
      //     //         'q=playstation+store+$15&platform=PSN&productType=giftcard&region=13',
      //     //       idSubChild: 1240,
      //     //     },
      //     //     {
      //     //       name: '[pdp]playstation-store-4000-inr-gift-card-india-digital-code-p9885517',
      //     //       displayName: 'PlayStation Store ₹4000 (IN)',
      //     //       url: '/store?q=playstation+store+€25&platform[]=PSN&productType[]=giftcard&page=1&region=13',
      //     //       queryStr:
      //     //         'q=playstation+store+€25&platform=PSN&productType=giftcard&region=13',
      //     //       idSubChild: 1241,
      //     //     },
      //     //     {
      //     //       name: '[pdp]playstation-store-40-eur-gift-card-netherlands-digital-code-p9885805',
      //     //       displayName: 'PlayStation Store €40 (NL))',
      //     //       url: '/store?q=playstation+store+€15&platform[]=PSN&productType[]=giftcard&page=1&region=13',
      //     //       queryStr:
      //     //         'q=playstation+store+€15&platform=PSN&productType=giftcard&region=13',
      //     //       idSubChild: 1242,
      //     //     },
      //     //     {
      //     //       name: 'psn-giftcards',
      //     //       displayName: 'View All',
      //     //       url: '/store?platform[]=PSN&page=1&productType[]=giftcard',
      //     //       queryStr: 'platform=PSN&productType=giftcard',
      //     //       idSubChild: 1243,
      //     //     },
      //     //   ],
      //     // },
      //     // {
      //     //   name: 'PS Plus',
      //     //   displayName: 'PS Plus',
      //     //   idSub: 117,
      //     //   children: [
      //     //     {
      //     //       name: '[pdp]playstation-plus-3-months-membership-us-digital-code-p9887554',
      //     //       displayName: 'PlayStation Plus 3 Months (US)',
      //     //       url: '/store?q=playstation+plus+3+months&platform[]=PSN&productType[]=subscription&page=1&region=1',
      //     //       queryStr:
      //     //         'q=playstation+plus+3+months&platform=PSN&productType=subscription&region=1',
      //     //       idSubChild: 1244,
      //     //     },
      //     //     {
      //     //       name: '[pdp]playstation-plus-3-months-membership-uk-digital-code-p9887558',
      //     //       displayName: 'PlayStation Plus 3 Months (UK)',
      //     //       url: '/store?q=playstation+plus+3+months&platform[]=PSN&productType[]=subscription&page=1&region=2',
      //     //       queryStr:
      //     //         'q=playstation+plus+3+months&platform=PSN&productType=subscription&region=2',
      //     //       idSubChild: 1245,
      //     //     },
      //     //     {
      //     //       name: '[pdp]playstation-plus-1-month-membership-uk-digital-code-p9887557',
      //     //       displayName: 'PlayStation Plus 1 Month (UK)',
      //     //       url: '/store?q=playstation+plus+3+months&platform[]=PSN&productType[]=subscription&page=1&region=2',
      //     //       queryStr:
      //     //         'q=playstation+plus+3+months&platform=PSN&productType=subscription&region=2',
      //     //       idSubChild: 1246,
      //     //     },
      //     //     {
      //     //       name: '[pdp]playstation-plus-essential-12-months-membership-br-digital-code-p9893381',
      //     //       displayName: 'PlayStation Plus 12 Months (BR)',
      //     //       url: '/store?q=playstation+plus+12+months&platform[]=PSN&productType[]=subscription&page=1&region=15',
      //     //       queryStr:
      //     //         'q=playstation+plus+12+months&platform=PSN&productType=subscription&region=15',
      //     //       idSubChild: 1247,
      //     //     },
      //     //     {
      //     //       name: '[pdp]playstation-plus-1-month-membership-us-digital-code-p9887553',
      //     //       displayName: 'PlayStation Plus 1 Month (US)',
      //     //       url: '/store?q=playstation+plus+1+month&platform[]=PSN&productType[]=subscription&page=1&region=1',
      //     //       queryStr:
      //     //         'q=playstation+plus+1+month&platform=PSN&productType=subscription&region=1',
      //     //       idSubChild: 1248,
      //     //     },
      //     //     {
      //     //       name: '[pdp]playstation-plus-1-month-membership-de-digital-code-p9887555',
      //     //       displayName: 'PlayStation Plus 1 Month (DE)',
      //     //       url: '/store?q=playstation+plus+1+month&platform[]=PSN&productType[]=subscription&page=1&region=13',
      //     //       queryStr:
      //     //         'q=playstation+plus+1+month&platform=PSN&productType=subscription&region=13',
      //     //       idSubChild: 1249,
      //     //     },
      //     //     {
      //     //       name: '[pdp]playstation-plus-3-months-membership-de-digital-code-p9887556',
      //     //       displayName: 'PlayStation Plus 3 Months (DE)',
      //     //       url: '/store?q=playstation+plus+3+months&platform[]=PSN&productType[]=subscription&page=1&region=13',
      //     //       queryStr:
      //     //         'q=playstation+plus+3+months&platform=PSN&productType=subscription&region=13',
      //     //       idSubChild: 1250,
      //     //     },
      //     //     {
      //     //       name: 'psn-plus',
      //     //       displayName: 'View All',
      //     //       url: '/store?platform[]=PSN&page=1&productType[]=subscription',
      //     //       queryStr: 'platform=PSN&productType=subscription',
      //     //       idSubChild: 1251,
      //     //     },
      //     //   ],
      //     // },
      //     {
      //       name: gift_cards_by_region_msg ?? '',
      //       displayName: 'Gift Cards by Region',
      //       idSub: 118,
      //       children: [
      //         {
      //           name: 'psn-gift-cards-germany',
      //           displayName: 'PSN Giftcard Germany',
      //           url: '/store?platform[]=PSN&productType[]=giftcard&page=1&region=13&worksIn=false',
      //           queryStr:
      //             'platform=PSN&productType=giftcard&region=13&worksIn=false',
      //           idSubChild: 1252,
      //           navbar: true,
      //         },
      //         {
      //           name: 'psn-gift-cards-poland',
      //           displayName: 'PSN Giftcard Poland',
      //           url: '/store?platform[]=PSN&productType[]=giftcard&page=1&region=19&worksIn=false',
      //           queryStr:
      //             'platform=PSN&productType=giftcard&region=19&worksIn=false',
      //           idSubChild: 1253,
      //           navbar: true,
      //         },
      //         {
      //           name: 'psn-gift-cards-united-states',
      //           displayName: 'PSN Giftcard United States',
      //           url: '/store?platform[]=PSN&productType[]=giftcard&page=1&region=2&worksIn=false',
      //           queryStr:
      //             'platform=PSN&productType=giftcard&region=2&worksIn=false',
      //           idSubChild: 1254,
      //           navbar: true,
      //         },
      //         {
      //           name: 'psn-gift-cards-spain',
      //           displayName: 'PSN Giftcard Spain',
      //           url: '/store?platform[]=PSN&productType[]=giftcard&page=1&region=56&worksIn=false',
      //           queryStr:
      //             'platform=PSN&productType=giftcard&region=56&worksIn=false',
      //           idSubChild: 1255,
      //           navbar: true,
      //         },
      //         {
      //           name: 'psn-gift-cards-france',
      //           displayName: 'PSN Giftcard France',
      //           url: '/store?platform[]=PSN&productType[]=giftcard&page=1&region=34&worksIn=false',
      //           queryStr:
      //             'platform=PSN&productType=giftcard&region=34&worksIn=false',
      //           idSubChild: 1256,
      //           navbar: true,
      //         },
      //         {
      //           name: 'psn-gift-cards-india',
      //           displayName: 'PSN Giftcard India',
      //           url: '/store?platform[]=PSN&productType[]=giftcard&page=1&region=16&worksIn=false',
      //           queryStr:
      //             'platform=PSN&productType=giftcard&region=16&worksIn=false',
      //           idSubChild: 1257,
      //           navbar: true,
      //         },
      //         {
      //           name: 'psn-gift-cards-italy',
      //           displayName: 'PSN Giftcard Italy',
      //           url: '/store?platform[]=PSN&productType[]=giftcard&page=1&region=36&worksIn=false',
      //           queryStr:
      //             'platform=PSN&productType=giftcard&region=36&worksIn=false',
      //           idSubChild: 1258,
      //           navbar: true,
      //         },
      //         // {
      //         //   name: 'psn-gift-cards-greece',
      //         //   displayName: 'Greece',
      //         //   url: '/store?platform[]=PSN&productType[]=giftcard&page=1&region=49',
      //         //   queryStr: 'platform=PSN&productType=giftcard&region=49',
      //         //   idSubChild: 1259,
      //         // },
      //         {
      //           name: 'psn-gift-cards',
      //           displayName: view_all_msg ?? '',
      //           url: '/store?platform[]=PSN&page=1&productType[]=giftcard',
      //           queryStr: 'platform=PSN&productType=giftcard',
      //           idSubChild: 1235,
      //           navbar: true,
      //         },
      //         // {
      //         //   name: 'psn-gift-cards-taiwan',
      //         //   displayName: 'Taiwan',
      //         //   url: '/store?platform[]=PSN&productType[]=giftcard&page=1&region=23',
      //         //   queryStr: 'platform=PSN&productType=giftcard&region=23',
      //         //   idSubChild: 1260,
      //         // },
      //       ],
      //     },
      //     {
      //       name: ps_plus_by_region_msg ?? '',
      //       displayName: 'PS Plus by Region',
      //       idSub: 119,
      //       children: [
      //         {
      //           name: 'psn-plus-membership-united-states',
      //           displayName: 'PS Plus United States',
      //           url: '/store?platform[]=PSN&productType[]=subscription&page=1&region=2&worksIn=false',
      //           queryStr:
      //             'platform=PSN&productType=subscription&region=2&worksIn=false',
      //           idSubChild: 1261,
      //           navbar: true,
      //         },
      //         {
      //           name: 'psn-plus-membership-united-kingdom',
      //           displayName: 'PS Plus United Kingdom',
      //           url: '/store?platform[]=PSN&productType[]=subscription&page=1&region=8&worksIn=false',
      //           queryStr:
      //             'platform=PSN&productType=subscription&region=8&worksIn=false',
      //           idSubChild: 1262,
      //           navbar: true,
      //         },
      //         {
      //           name: 'psn-plus-membership-brazil',
      //           displayName: 'PS Plus Brazil',
      //           url: '/store?platform[]=PSN&productType[]=subscription&page=1&region=15&worksIn=false',
      //           queryStr:
      //             'platform=PSN&productType=subscription&region=15&worksIn=false',
      //           idSubChild: 1263,
      //           navbar: true,
      //         },
      //         {
      //           name: 'psn-plus-membership-germany',
      //           displayName: 'PS Plus Germany',
      //           url: '/store?platform[]=PSN&productType[]=subscription&page=1&region=13&worksIn=false',
      //           queryStr:
      //             'platform=PSN&productType=subscription&region=13&worksIn=false',
      //           idSubChild: 1264,
      //           navbar: true,
      //         },
      //         {
      //           name: 'psn-plus-membership',
      //           displayName: view_all_msg ?? '',
      //           url: '/store?platform[]=PSN&productType[]=subscription&page=1',
      //           queryStr: 'platform=PSN&productType=subscription',
      //           idSubChild: 1264,
      //           navbar: true,
      //         },
      //         // {
      //         //   name: 'playStation-gift-cards-europe',
      //         //   displayName: 'PlayStation Gift Cards Europe',
      //         //   url: '/store?platform[]=PSN&productType[]=giftcard&region[]=1&page=1',
      //         //   queryStr: 'platform=PSN&productType=giftcard&region=1',
      //         // },
      //       ],
      //     },
      //     // {
      //     //   name: 'Price Range',
      //     //   displayName: 'Price Range',
      //     //   idSub: 108,
      //     //   children: [
      //     //     {
      //     //       name: 'psn-gift-cards',
      //     //       displayName: 'PSN Gift Cards',
      //     //       url: '/store?platform[]=PSN&productType[]=giftcard&page=1',
      //     //       queryStr: 'platform=PSN&productType=giftcard',
      //     //       idSubChild: 1050,
      //     //     },
      //     //     {
      //     //       name: 'psn-plus-membership',
      //     //       displayName: 'PSN Plus Membership',
      //     //       url: '/store?platform[]=PSN&productType[]=subscription&page=1',
      //     //       queryStr: 'platform=PSN&productType=subscription',
      //     //       idSubChild: 1051,
      //     //     },
      //     //   ],
      //     // },
      //     // {
      //     //   name: 'Memberships by country',
      //     //   displayName: 'Memberships by country',
      //     //   children: [
      //     //     {
      //     //       name: 'PS Plus Membership US',
      //     //       displayName: 'PS Plus Membership US',
      //     //       url: '',
      //     //     },
      //     //   ],
      //     // },
      //   ],
      //   navbar: true,
      // },
      // {
      //   name: 'xbox',
      //   displayName: 'Xbox',
      //   url: '/store?page=1&platform[]=Xbox+Live',
      //   queryStr: 'platform=Xbox+Live',
      //   id: 4,
      //   subDivision: [
      //     {
      //       name: platform_products_msg?.replace('[_PLATFORM_]', 'XBOX') ?? '',
      //       displayName: 'XBOX Products',
      //       idSub: 110,
      //       children: [
      //         {
      //           name: 'xbox-games',
      //           displayName: xbox_games_msg ?? '',
      //           url: '/store?platform=Xbox+Live&page=1&productType=game',
      //           queryStr: 'platform=Xbox+Live&page=1&productType=game',
      //           idSubChild: 1085,
      //           navbar: true,
      //         },
      //         {
      //           name: 'xbox-dlc',
      //           displayName: 'Xbox DLCs',
      //           url: '/store?platform=Xbox+Live&page=1&productType=dlc',
      //           queryStr: 'platform=Xbox+Live&page=1&productType=dlc',
      //           idSubChild: 1085,
      //           navbar: true,
      //         },
      //         {
      //           name: 'xbox-gamepoints',
      //           displayName: 'Xbox Gamepoints',
      //           url: '/store?platform=Xbox+Live&page=1&productType=game+point',
      //           queryStr: 'platform=Xbox+Live&page=1&productType=game+point',
      //           idSubChild: 1085,
      //           navbar: true,
      //         },
      //         {
      //           name: 'xbox-gift-cards',
      //           displayName: 'Xbox Giftcards',
      //           url: '/store?platform=Xbox+Live&productType=giftcard',
      //           queryStr: 'platform=Xbox+Live&productType=giftcard',
      //           idSubChild: 1090,
      //           navbar: true,
      //         },
      //         {
      //           name: 'xbox-subscription',
      //           displayName: 'Xbox Subscriptions',
      //           url: '/store?platform=Xbox+Live&page=1&productType=subscription',
      //           queryStr: 'platform=Xbox+Live&page=1&productType=subscription',
      //           idSubChild: 1085,
      //           navbar: true,
      //         },
      //         {
      //           name: 'xbox',
      //           displayName: view_all_msg ?? '',
      //           url: '/store?platform=Xbox+Live',
      //           queryStr: 'platform=Xbox+Live',
      //           idSubChild: 1111,
      //           navbar: true,
      //         },
      //         // {
      //         //   name: '[pdp]forza-motorsport-eg-pc-xbox-series-xs-xbox-live-digital-code-p9888288',
      //         //   displayName: 'Forza Motorspot',
      //         //   url: '/store?q=forza+motorspot&platform[]=Xbox+Live&page=1&productType[]=game',
      //         //   queryStr: 'q=forza+motorspot&platform=Xbox+Live&productType=game',
      //         //   idSubChild: 1085,
      //         // },
      //         // {
      //         //   name: '[pdp]mortal-kombat-1-xbox-series-x-s-xbox-live-digital-code-p9887002',
      //         //   displayName: 'Mortal Kombat 1',
      //         //   url: '/store?q=mortal+kombat+1&platform[]=Xbox+Live&page=1&productType[]=game',
      //         //   queryStr: 'q=mortal+kombat+1&platform=Xbox+Live&productType=game',
      //         //   idSubChild: 1086,
      //         // },
      //         // {
      //         //   name: '[pdp]mortal-kombat-xl-ar-xbox-one-xbox-series-xs-xbox-live-digital-code-p9884822',
      //         //   displayName: 'Mortal Kombat XL',
      //         //   url: '/store?q=mortal+kombat+xl&platform[]=Xbox+Live&page=1&productType[]=game',
      //         //   queryStr:
      //         //     'q=mortal+kombat+xl&platform=Xbox+Live&productType=game',
      //         //   idSubChild: 1087,
      //         // },
      //         // {
      //         //   name: '[pdp]ea-sports-fc-24-xbox-one-xbox-series-xs-xbox-live-digital-code-p9886842',
      //         //   displayName: 'EA SPORTS FC 24',
      //         //   url: '/store?q=ea+sports+fc+24&platform[]=Xbox+Live&page=1&productType[]=game',
      //         //   queryStr: 'q=ea+sports+fc+24&platform=Xbox+Live&productType=game',
      //         //   idSubChild: 1088,
      //         // },
      //         // {
      //         //   name: '[pdp]cuphead-ar-xbox-one-cd-key-p587404',
      //         //   displayName: 'Cuphead',
      //         //   url: '/store?q=cuphead&platform[]=Xbox+Live&page=1&productType[]=game',
      //         //   queryStr: 'q=cuphead&platform=Xbox+Live&productType=game',
      //         //   idSubChild: 1089,
      //         // },
      //         // {
      //         //   name: '[pdp]assassins-creed-mirage-xbox-ar-xbox-live-digital-code-p9886933',
      //         //   displayName: "Assassin's Creed",
      //         //   url: '/store?q=assassins+creed&platform[]=Xbox+Live&page=1&productType[]=game',
      //         //   queryStr: 'q=assassins+creed&platform=Xbox+Live&productType=game',
      //         //   idSubChild: 1090,
      //         // },
      //         // {
      //         //   name: '[pdp]palworld-eu-pc-xbox-one-xbox-series-xs-xbox-live-digital-code',
      //         //   displayName: 'Palworld',
      //         //   url: '/store?q=palworld&platform[]=Xbox+Live&page=1&productType[]=game',
      //         //   queryStr: 'q=palworld&platform=Xbox+Live&productType=game',
      //         //   idSubChild: 1091,
      //         // },
      //         // {
      //         //   name: '[pdp]elden-ring-xbox-one-xbox-series-xs-xbox-live-digital-code-p9884430',
      //         //   displayName: 'Elden Ring',
      //         //   url: '/store?q=elden+ring&platform[]=Xbox+Live&page=1&productType[]=game',
      //         //   queryStr: 'q=elden+ring&platform=Xbox+Live&productType=game',
      //         //   idSubChild: 1092,
      //         // },
      //         // {
      //         //   name: 'xbox-games',
      //         //   displayName: 'View All',
      //         //   url: 'store?platform[]=Xbox+Live&page=1&productType[]=game',
      //         //   queryStr: 'platform=Xbox+Live&productType=game',
      //         //   idSubChild: 1093,
      //         // },
      //         // {
      //         //   name: '[pdp]red-dead-redemption-2-xbox-one-cd-key-p671161',
      //         //   displayName: 'Red Dead Redemption 2',
      //         //   url: '/q=red+dead+redemption+2&store?platform[]=Xbox+Live&page=1&productType[]=game',
      //         //   queryStr:
      //         //     'q=red+dead+redemption+2&platform=Xbox+Live&productType=game',
      //         //   idSubChild: 1044,
      //         // },
      //         // {
      //         //   name: '[pdp]starfield-premium-edition-pc-xbox-series-xs-xbox-live-digital-code-p9886574',
      //         //   displayName: 'Starfield',
      //         //   url: '/q=starfield&store?platform[]=Xbox+Live&page=1&productType[]=game',
      //         //   queryStr: 'q=starfield&platform=Xbox+Live&productType=game',
      //         //   idSubChild: 1044,
      //         // },
      //         // {
      //         //   name: '[pdp]diablo-iv-eu-xbox-one-xbox-series-xs-xbox-live-digital-code',
      //         //   displayName: 'Diablo IV',
      //         //   url: '/q=diablo+iv&store?platform[]=Xbox+Live&page=1&productType[]=game',
      //         //   queryStr: 'q=diablo+iv&platform=Xbox+Live&productType=game',
      //         //   idSubChild: 1044,
      //         // },
      //         // {
      //         //   name: 'far-cry-6',
      //         //   displayName: 'Far Cry 6',
      //         //   url: '/q=far+cry+6&store?platform[]=Xbox+Live&page=1&productType[]=game',
      //         //   queryStr: 'q=far+cry+6&platform=Xbox+Live&productType=game',
      //         //   idSubChild: 1044,
      //         // },
      //         // {
      //         //   name: 'call-of-duty',
      //         //   displayName: 'Call Of Duty',
      //         //   url: '/q=call+of+duty&store?platform[]=Xbox+Live&page=1&productType[]=game',
      //         //   queryStr: 'q=call+of+duty&platform=Xbox+Live&productType=game',
      //         //   idSubChild: 1044,
      //         // },
      //         // {
      //         //   name: 'spyro-reignited-triology',
      //         //   displayName: 'Spyro: Reignited Triology',
      //         //   url: '/q=spyro+reignited+triology&store?platform[]=Xbox+Live&page=1&productType[]=game',
      //         //   queryStr:
      //         //     'q=spyro+reignited+triology&platform=Xbox+Live&productType=game',
      //         //   idSubChild: 1044,
      //         // },
      //         // {
      //         //   name: 'doom',
      //         //   displayName: 'Doom',
      //         //   url: '/q=doom&store?platform[]=Xbox+Live&page=1&productType[]=game',
      //         //   queryStr: 'q=doom&platform=Xbox+Live&productType=game',
      //         //   idSubChild: 1044,
      //         // },
      //       ],
      //     },
      //     // {
      //     //   name: 'Xbox Gift Cards',
      //     //   displayName: 'XBOX Gift Cards',
      //     //   idSub: 111,
      //     //   children: [
      //     //     // {
      //     //     //   name: 'xbox-games',
      //     //     //   displayName: 'Xbox Games',
      //     //     //   url: '/store?platform[]=Xbox+Live&page=1&productType[]=game',
      //     //     //   queryStr: 'platform=Xbox+Live&productType=game',
      //     //     //   idSubChild: 1044,
      //     //     // },
      //     //     {
      //     //       name: '[pdp]xbox-100-try-gift-card-tr-digital-card-p9888293',
      //     //       displayName: 'Xbox ₺100 TRY Gift Card (TR)',
      //     //       url: '/store?q=xbox+₺100+try+giftcard&platform[]=Xbox+Live&page=1&productType[]=giftcard&region=21',
      //     //       queryStr:
      //     //         'q=xbox+₺100+try+giftcard&platform=Xbox+Live&productType=giftcard&region=21',
      //     //       idSubChild: 1094,
      //     //     },
      //     //     {
      //     //       name: '[pdp]xbox-5-usd-gift-card-us-digital-code',
      //     //       displayName: 'Xbox $5 USD Gift Card (US)',
      //     //       url: '/store?q=xbox+$5+usd+giftcard&platform[]=Xbox+Live&page=1&productType[]=giftcard&region=1',
      //     //       queryStr:
      //     //         'q=xbox+$5+usd+giftcard&platform=Xbox+Live&productType=giftcard&region=1',
      //     //       idSubChild: 1095,
      //     //     },
      //     //     {
      //     //       name: '[pdp]xbox-10-usd-gift-card-us-digital-code',
      //     //       displayName: 'Xbox $10 USD Gift Card (US)',
      //     //       url: '/store?q=xbox+$10+usd+giftcard&platform[]=Xbox+Live&page=1&productType[]=giftcard&region=1',
      //     //       queryStr:
      //     //         'q=xbox+$10+usd+giftcard&platform=Xbox+Live&productType=giftcard&region=1',
      //     //       idSubChild: 1096,
      //     //     },
      //     //     {
      //     //       name: '[pdp]xbox-r25-brl-gift-card-br-digital-code-p9883991',
      //     //       displayName: 'Xbox R$25 BRL Gift Card (BR)',
      //     //       url: '/store?q=xbox+R$25+brl+giftcard&platform[]=Xbox+Live&page=1&productType[]=giftcard&region=15',
      //     //       queryStr:
      //     //         'q=xbox+R$25+brl+giftcard&platform=Xbox+Live&productType=giftcard&region=15',
      //     //       idSubChild: 1097,
      //     //     },
      //     //     {
      //     //       name: '[pdp]xbox-300-try-gift-card-tr-digital-card-p9891564',
      //     //       displayName: 'Xbox ₺300 TRY Gift Card (TR)',
      //     //       url: '/store?q=xbox+₺300+try+giftcard&platform[]=Xbox+Live&page=1&productType[]=giftcard&region=21',
      //     //       queryStr:
      //     //         'q=xbox+₺300+try+giftcard&platform=Xbox+Live&productType=giftcard&region=21',
      //     //       idSubChild: 1098,
      //     //     },
      //     //     {
      //     //       name: '[pdp]xbox-600-zar-gift-card-South-Africa-digital-code-p9886343',
      //     //       displayName: 'Xbox 600 ZAR Gift Card (ZA) ',
      //     //       url: '/store?q=xbox+600+zar+giftcard&platform[]=Xbox+Live&page=1&productType[]=giftcard&region=57',
      //     //       queryStr:
      //     //         'q=xbox+600+zar+giftcard&platform=Xbox+Live&productType=giftcard&region=57',
      //     //       idSubChild: 1099,
      //     //     },
      //     //     {
      //     //       name: '[pdp]xbox-r5-brl-gift-card-br-digital-code-p9883962',
      //     //       displayName: 'Xbox R$5 BRL Gift Card (BR) ',
      //     //       url: '/store?q=xbox+$5+brl+giftcard&platform[]=Xbox+Live&page=1&productType[]=giftcard&region=15',
      //     //       queryStr:
      //     //         'q=xbox+$5+brl+giftcard&platform=Xbox+Live&productType=giftcard&region=15',
      //     //       idSubChild: 1100,
      //     //     },
      //     //     {
      //     //       name: '[pdp]xbox-15-nzd-gift-card-new-zealand-digital-card-p9886320',
      //     //       displayName: 'Xbox $15 NZD Gift Card (NZ)',
      //     //       url: '/store?q=xbox+$15+nzd+giftcard&platform[]=Xbox+Live&page=1&productType[]=giftcard&region=41',
      //     //       queryStr:
      //     //         'q=xbox+$15+nzd+giftcard&platform=Xbox+Live&productType=giftcard&region=41',
      //     //       idSubChild: 1101,
      //     //     },
      //     //     {
      //     //       name: 'xbox-giftcards',
      //     //       displayName: 'View All',
      //     //       url: '/store?platform=Xbox+Live&productType=giftcard',
      //     //       queryStr: 'platform=Xbox+Live&productType=giftcard',
      //     //       idSubChild: 1102,
      //     //     },
      //     //     // {
      //     //     //   name: '[pdp]xbox-50-eur-gift-card-de-digital-code',
      //     //     //   displayName: 'Xbox €50 EUR Gift Card (DE)  ',
      //     //     //   url: '/q=xbox+€50+eur+giftcard&store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=13',
      //     //     //   queryStr:
      //     //     //     'q=xbox+€50+eur+giftcard&platform=Xbox+Live&productType=giftcard&region=13',
      //     //     //   idSubChild: 1044,
      //     //     // },
      //     //     // {
      //     //     //   name: '[pdp]xbox-75-eur-gift-card-eu-digital-code',
      //     //     //   displayName: 'Xbox €75 EUR Gift Card (EU)  ',
      //     //     //   url: '/q=xbox+€75+eur+giftcard&store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=1',
      //     //     //   queryStr:
      //     //     //     'q=xbox+€75+eur+giftcard&platform=Xbox+Live&productType=giftcard&region=1',
      //     //     //   idSubChild: 1044,
      //     //     // },
      //     //     // {
      //     //     //   name: '[pdp]xbox-r15-brl-gift-card-br-digital-code-p9886371',
      //     //     //   displayName: 'Xbox R$15 BRL Gift Card (BR)',
      //     //     //   url: '/q=xbox+R$15+brl+giftcard&store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=15',
      //     //     //   queryStr:
      //     //     //     'q=xbox+R$15+brl+giftcard&platform=Xbox+Live&productType=giftcard&region=15',
      //     //     //   idSubChild: 1044,
      //     //     // },
      //     //     // {
      //     //     //   name: '[pdp]xbox-100-nzd-gift-card-new-zealand-digital-card-p9886316',
      //     //     //   displayName: 'Xbox $100 NZD Gift Card (NZ)',
      //     //     //   url: '/q=xbox+$100+nzd+giftcard&store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=41',
      //     //     //   queryStr:
      //     //     //     'q=xbox+$100+nzd+giftcard&platform=Xbox+Live&productType=giftcard&region=41',
      //     //     //   idSubChild: 1044,
      //     //     // },
      //     //   ],
      //     // },
      //     // {
      //     //   name: 'Xbox Game Pass',
      //     //   displayName: 'XBOX Game Pass',
      //     //   idSub: 112,
      //     //   children: [
      //     //     {
      //     //       name: '[pdp]xbox-live-gold-1-months-xbox-live-digital-code-p9884093',
      //     //       displayName: 'Xbox Game Pass Core 1 Month',
      //     //       url: '/store?q=xbox+game+pass+core+1+month&platform[]=Xbox+Live&page=1&productType[]=subscription',
      //     //       queryStr:
      //     //         'q=xbox+game+pass+core+1+month&productType=subscription&platform=Xbox+Live',
      //     //       idSubChild: 1103,
      //     //     },
      //     //     {
      //     //       name: '[pdp]xbox-live-gold-3-months-xbox-live-digital-code-p9884072',
      //     //       displayName: 'Xbox Game Pass Core 3 Months',
      //     //       url: '/q=xbox+game+pass+core+3+months&store?platform[]=Xbox+Live&page=1&productType[]=subscription',
      //     //       queryStr:
      //     //         'q=xbox+game+pass+core+3+months&platform=Xbox+Live&productType=subscription',
      //     //       idSubChild: 1104,
      //     //     },
      //     //     {
      //     //       name: '[pdp]xbox-game-pass-core-6-months-eu-xbox-live-digital-code-p9885491',
      //     //       displayName: 'Xbox Game Pass Core 6 Months',
      //     //       url: '/q=xbox+game+pass+core+6+months&store?platform[]=Xbox+Live&page=1&productType[]=subscription',
      //     //       queryStr:
      //     //         'q=xbox+game+pass+core+6+months&platform=Xbox+Live&productType=subscription',
      //     //       idSubChild: 1105,
      //     //     },
      //     //     {
      //     //       name: '[pdp]xbox-live-gold-12-months-us-xbox-live-digital-code-p9884350',
      //     //       displayName: 'Xbox Game Pass Core 12 Months',
      //     //       url: '/q=xbox+game+pass+core+12+months&store?platform[]=Xbox+Live&page=1&productType[]=subscription',
      //     //       queryStr:
      //     //         'q=xbox+game+pass+core+12+months&platform=Xbox+Live&productType=subscription',
      //     //       idSubChild: 1106,
      //     //     },
      //     //     {
      //     //       name: '[pdp]xbox-game-pass-ultimate-1-month-xbox-live-digital-code-p655874',
      //     //       displayName: 'Xbox Game Pass Ultimate - 1 Month',
      //     //       url: '/q=xbox+game+pass+ultimate+1+month&store?platform[]=Xbox+Live&page=1&productType[]=subscription',
      //     //       queryStr:
      //     //         'q=xbox+game+pass+ultimate+1+month&platform=Xbox+Live&productType=subscription',
      //     //       idSubChild: 1107,
      //     //     },
      //     //     {
      //     //       name: '[pdp]xbox-game-pass-ultimate-3-months-xbox-live-digital-code-p125206',
      //     //       displayName: 'Xbox Game Pass Ultimate - 3 Months',
      //     //       url: '/q=xbox+game+pass+ultimate+3+months&store?platform[]=Xbox+Live&page=1&productType[]=subscription',
      //     //       queryStr:
      //     //         'q=xbox+game+pass+ultimate+3+months&platform=Xbox+Live&productType=subscription',
      //     //       idSubChild: 1108,
      //     //     },
      //     //     {
      //     //       name: '[pdp]xbox-game-pass-ultimate-12-month-us-xbox-live-digital-code-p9893933',
      //     //       displayName: 'Xbox Game Pass Ultimate - 12 Months',
      //     //       url: '/q=xbox+game+pass+ultimate+12+months&store?platform[]=Xbox+Live&page=1&productType[]=subscription',
      //     //       queryStr:
      //     //         'q=xbox+game+pass+ultimate+12+months&platform=Xbox+Live&productType=subscription',
      //     //       idSubChild: 1109,
      //     //     },

      //     //     // {
      //     //     //   name: '[pdp]xbox-game-pass-for-pc-trial-3-months-digital-code-p9884875',
      //     //     //   displayName: 'Xbox Game Pass For PC Trial - 3 Months',
      //     //     //   url: '/q=xbox+game+pass+for+pc+trial+3+months&store?platform[]=Xbox+Live&page=1&productType[]=subscription',
      //     //     //   queryStr:
      //     //     //     'q=xbox+game+pass+for+pc+trial+3+months&platform=Xbox+Live&productType=subscription',
      //     //     //   idSubChild: 1110,
      //     //     // },
      //     //     {
      //     //       name: 'xbox-subscription',
      //     //       displayName: 'View All',
      //     //       url: '/store?platform[]=Xbox+Live&page=1&productType[]=subscription',
      //     //       queryStr: 'platform=Xbox+Live&productType=subscription',
      //     //       idSubChild: 1111,
      //     //     },
      //     //     // {
      //     //     //   name: 'ea-play-6-month-subscription',
      //     //     //   displayName: 'EA Play 6 Month Subscription - Xbox Live',
      //     //     //   url: '/q=ea+play+6+month+subscription+xbox+live&store?platform[]=Xbox+Live&page=1&productType[]=subscription',
      //     //     //   queryStr:
      //     //     //     'q=ea+play+6+month+subscription+xbox+live&platform=Xbox+Live&productType=subscription',
      //     //     //   idSubChild: 1044,
      //     //     // },
      //     //     // {
      //     //     //   name: 'ea-play-12-months-subscription',
      //     //     //   displayName: 'EA Play 12 Months Subscription - Xbox Live',
      //     //     //   url: '/q=ea+play+12+months+subscription+xbox+live&store?platform[]=Xbox+Live&page=1&productType[]=subscription',
      //     //     //   queryStr:
      //     //     //     'q=ea+play+12+months+subscription+xbox+live&platform=Xbox+Live&productType=subscription',
      //     //     //   idSubChild: 1044,
      //     //     // },
      //     //   ],
      //     // },
      //     {
      //       name: gift_cards_by_region_msg ?? '',
      //       displayName: 'Gift Cards By Region',
      //       idSub: 113,
      //       children: [
      //         // {
      //         //   name: 'xbox-giftcard-global',
      //         //   displayName: 'Xbox Live Global',
      //         //   url: '/store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=3',
      //         //   queryStr: 'platform=Xbox+Live&productType=giftcard&region=3',
      //         //   idSubChild: 1112,
      //         // },
      //         {
      //           name: 'xbox-gift-cards-united-states',
      //           displayName: 'Xbox Live United States',
      //           url: '/store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=2&worksIn=false',
      //           queryStr:
      //             'platform=Xbox+Live&productType=giftcard&region=2&worksIn=false',
      //           idSubChild: 1113,
      //           navbar: true,
      //         },
      //         {
      //           name: 'xbox-gift-cards-turkey',
      //           displayName: 'Xbox Live Turkey',
      //           url: '/store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=21&worksIn=false',
      //           queryStr:
      //             'platform=Xbox+Live&productType=giftcard&region=21&worksIn=false',
      //           idSubChild: 1114,
      //           navbar: true,
      //         },
      //         {
      //           name: 'xbox-gift-cards-brazil',
      //           displayName: 'Xbox Live Brazil',
      //           url: '/store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=15&worksIn=false',
      //           queryStr:
      //             'platform=Xbox+Live&productType=giftcard&region=15&worksIn=false',
      //           idSubChild: 1115,
      //           navbar: true,
      //         },
      //         {
      //           name: 'xbox-gift-cards-south-africa',
      //           displayName: 'Xbox Live South Africa',
      //           url: '/store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=57&worksIn=false',
      //           queryStr:
      //             'platform=Xbox+Live&productType=giftcard&region=57&worksIn=false',
      //           idSubChild: 1116,
      //           navbar: true,
      //         },
      //         {
      //           name: 'xbox-gift-cards-europe',
      //           displayName: 'Xbox Live Europe',
      //           url: '/store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=1&worksIn=false',
      //           queryStr:
      //             'platform=Xbox+Live&productType=giftcard&region=1&worksIn=false',
      //           idSubChild: 1117,
      //           navbar: true,
      //         },
      //         {
      //           name: 'xbox-gift-cards-new-zealand',
      //           displayName: 'Xbox Live New Zealand',
      //           url: '/store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=41&worksIn=false',
      //           queryStr:
      //             'platform=Xbox+Live&productType=giftcard&region=41&worksIn=false',
      //           idSubChild: 1118,
      //           navbar: true,
      //         },
      //         {
      //           name: 'xbox-gift-cards-germany',
      //           displayName: 'Xbox Live Germany',
      //           url: '/store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=13&worksIn=false',
      //           queryStr:
      //             'platform=Xbox+Live&productType=giftcard&region=13&worksIn=false',
      //           idSubChild: 1119,
      //           navbar: true,
      //         },
      //         {
      //           name: 'xbox-gift-cards',
      //           displayName: view_all_msg ?? '',
      //           url: '/store?platform=Xbox+Live&productType=giftcard&page=1',
      //           queryStr: 'platform=Xbox+Live&productType=giftcard',
      //           idSubChild: 1111,
      //           navbar: true,
      //         },
      //         // {
      //         //   name: 'xbox-gift-cards-united-kingdom',
      //         //   displayName: 'Xbox Live United Kingdom',
      //         //   url: '/store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=8',
      //         //   queryStr: 'platform=Xbox+Live&productType=giftcard&region=8',
      //         //   idSubChild: 1044,
      //         // },
      //         // {
      //         //   name: 'xbox-gift-cards-poland',
      //         //   displayName: 'Xbox Live Poland',
      //         //   url: '/store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=19',
      //         //   queryStr: 'platform=Xbox+Live&productType=giftcard&region=19',
      //         //   idSubChild: 1044,
      //         // },
      //         // {
      //         //   name: 'xbox-gift-cards-france',
      //         //   displayName: 'Xbox Live France',
      //         //   url: '/store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=34',
      //         //   queryStr: 'platform=Xbox+Live&productType=giftcard&region=34',
      //         //   idSubChild: 1044,
      //         // },
      //         // {
      //         //   name: 'xbox-gift-cards-australia',
      //         //   displayName: 'Xbox Live Australia',
      //         //   url: '/store?platform[]=Xbox+Live&page=1&productType[]=giftcard&region=14',
      //         //   queryStr: 'platform=Xbox+Live&productType=giftcard&region=14',
      //         //   idSubChild: 1044,
      //         // },
      //       ],
      //     },
      //     {
      //       name:
      //         platform_subscription_by_region_msg?.replace(
      //           '[_PLATFORM_]',
      //           'Xbox'
      //         ) ?? '',
      //       displayName: 'Xbox Subscriptions By Region',
      //       idSub: 114,
      //       children: [
      //         {
      //           name: 'xbox-subscriptions-global',
      //           displayName: 'Xbox Subscriptions Global',
      //           url: '/store?platform[]=Xbox+Live&page=1&productType[]=subscription&region=3&worksIn=false',
      //           queryStr:
      //             'platform=Xbox+Live&productType=subscription&region=3&worksIn=false',
      //           idSubChild: 1120,
      //           navbar: true,
      //         },
      //         {
      //           name: 'xbox-subscriptions-united-states',
      //           displayName: 'Xbox Subscriptions United States',
      //           url: '/store?platform[]=Xbox+Live&page=1&productType[]=subscription&region=2&worksIn=false',
      //           queryStr:
      //             'platform=Xbox+Live&productType=subscription&region=2&worksIn=false',
      //           idSubChild: 1121,
      //           navbar: true,
      //         },
      //         {
      //           name: 'xbox-subscriptions-turkey',
      //           displayName: 'Xbox Subscriptions Turkey',
      //           url: '/store?platform[]=Xbox+Live&page=1&productType[]=subscription&region=21&worksIn=false',
      //           queryStr:
      //             'platform=Xbox+Live&productType=subscription&region=21&worksIn=false',
      //           idSubChild: 1122,
      //           navbar: true,
      //         },
      //         {
      //           name: 'xbox-subscriptions-brazil',
      //           displayName: 'Xbox Subscriptions Brazil',
      //           url: '/store?platform[]=Xbox+Live&page=1&productType[]=subscription&region=15&worksIn=false',
      //           queryStr:
      //             'platform=Xbox+Live&productType=subscription&region=15&worksIn=false',
      //           idSubChild: 1123,
      //           navbar: true,
      //         },
      //         {
      //           name: 'xbox-subscriptions-south-africa',
      //           displayName: 'Xbox Subscriptions South Africa',
      //           url: '/store?platform[]=Xbox+Live&page=1&productType[]=subscription&region=57&worksIn=false',
      //           queryStr:
      //             'platform=Xbox+Live&productType=subscription&region=57&worksIn=false',
      //           idSubChild: 1124,
      //           navbar: true,
      //         },
      //         {
      //           name: 'xbox-subscriptions-europe',
      //           displayName: 'Xbox Subscriptions Europe',
      //           url: '/store?platform[]=Xbox+Live&page=1&productType[]=subscription&region=1&worksIn=false',
      //           queryStr:
      //             'platform=Xbox+Live&productType=subscription&region=1&worksIn=false',
      //           idSubChild: 1125,
      //           navbar: true,
      //         },
      //         {
      //           name: 'xbox-subscriptions-new-zealand',
      //           displayName: 'Xbox Subscriptions New Zealand',
      //           url: '/store?platform[]=Xbox+Live&page=1&productType[]=subscription&region=41&worksIn=false',
      //           queryStr:
      //             'platform=Xbox+Live&productType=subscription&region=41&worksIn=false',
      //           idSubChild: 1226,
      //           navbar: true,
      //         },
      //         {
      //           name: 'xbox-subscriptions-germany',
      //           displayName: 'Xbox Subscriptions Germany',
      //           url: '/store?platform[]=Xbox+Live&page=1&productType[]=subscription&region=13&worksIn=false',
      //           queryStr:
      //             'platform=Xbox+Live&productType=subscription&region=13&worksIn=false',
      //           idSubChild: 1227,
      //           navbar: true,
      //         },
      //         {
      //           name: 'xbox-gift-cards',
      //           displayName: view_all_msg ?? '',
      //           url: '/store?platform=Xbox+Live&productType=giftcard',
      //           queryStr: 'platform=Xbox+Live&productType=giftcard',
      //           idSubChild: 1111,
      //           navbar: true,
      //         },
      //         // {
      //         //   name: 'xbox-subscription-united-kingdom',
      //         //   displayName: 'Xbox Plus United Kingdom',
      //         //   url: '/store?platform[]=Xbox+Live&page=1&productType[]=subscription&region=8',
      //         //   queryStr: 'platform=Xbox+Live&productType=subscription&region=8',
      //         //   idSubChild: 1044,
      //         // },
      //         // {
      //         //   name: 'xbox-subscription-poland',
      //         //   displayName: 'Xbox Plus Poland',
      //         //   url: '/store?platform[]=Xbox+Live&page=1&productType[]=subscription&region=19',
      //         //   queryStr: 'platform=Xbox+Live&productType=subscription&region=19',
      //         //   idSubChild: 1044,
      //         // },
      //         // {
      //         //   name: 'xbox-subscription-france',
      //         //   displayName: 'Xbox Plus France',
      //         //   url: '/store?platform[]=Xbox+Live&page=1&productType[]=subscription&region=34',
      //         //   queryStr: 'platform=Xbox+Live&productType=subscription&region=34',
      //         //   idSubChild: 1044,
      //         // },
      //         // {
      //         //   name: 'xbox-subscription-australia',
      //         //   displayName: 'Xbox Plus Australia',
      //         //   url: '/store?platform[]=Xbox+Live&page=1&productType[]=subscription&region=14',
      //         //   queryStr: 'platform=Xbox+Live&productType=subscription&region=14',
      //         //   idSubChild: 1044,
      //         // },
      //       ],
      //     },
      //     // {
      //     //   name: 'Price Range',
      //     //   displayName: 'Price Range',
      //     //   idSub: 106,
      //     //   children: [
      //     //     {
      //     //       name: 'xbox-games',
      //     //       displayName: 'Xbox Games',
      //     //       url: '/store?platform[]=Xbox+Live&page=1&productType[]=game',
      //     //       queryStr: 'platform=Xbox+Live&productType=game',
      //     //       idSubChild: 1044,
      //     //     },
      //     //   ]
      //     // }
      //   ],
      //   navbar: true,
      // },

      // {
      //   name: 'nintendo',
      //   displayName: 'Nintendo',
      //   url: '/store?page=1&platform[]=Nintendo',
      //   queryStr: 'platform=Nintendo',
      //   id: 6,
      //   subDivision: [
      //     {
      //       name:
      //         platform_products_msg?.replace('[_PLATFORM_]', 'Nintendo') ?? '',
      //       displayName: 'Nintendo Products',
      //       idSub: 115,
      //       children: [
      //         {
      //           name: 'nintendo-games',
      //           displayName: nintendo_games_msg ?? '',
      //           url: '/store?platform[]=Nintendo&page=1&productType[]=game',
      //           queryStr: 'platform=Nintendo&productType=game',
      //           idSubChild: 1228,
      //           navbar: true,
      //         },
      //         {
      //           name: 'nintendo-dlc',
      //           displayName: 'Nintendo DLCs',
      //           url: '/store?platform[]=Nintendo&page=1&productType[]=dlc',
      //           queryStr: 'platform=Nintendo&productType=dlc',
      //           idSubChild: 1228,
      //           navbar: true,
      //         },
      //         {
      //           name: 'nintendo-gift-cards',
      //           displayName: 'Nintendo Giftcard',
      //           url: '/store?platform[]=Nintendo&page=1&productType[]=giftcard',
      //           queryStr: 'platform=Nintendo&productType=giftcard',
      //           idSubChild: 1228,
      //           navbar: true,
      //         },
      //         {
      //           name: 'nintendo-subsciption',
      //           displayName: 'Nintendo Subscription',
      //           url: '/store?platform[]=Nintendo&page=1&productType[]=subscription',
      //           queryStr: 'platform=Nintendo&productType=subscription',
      //           idSubChild: 1228,
      //           navbar: true,
      //         },
      //         {
      //           name: 'nintendo',
      //           displayName: view_all_msg ?? '',
      //           url: '/store?platform=Nintendo',
      //           queryStr: 'platform=Nintendo',
      //           idSubChild: 1111,
      //           navbar: true,
      //         },
      //         // {
      //         //   name: 'nintendo-gamepoint',
      //         //   displayName: 'Nintendo Gamepoints',
      //         //   url: '/store?platform[]=Nintendo&page=1&productType[]=game+point',
      //         //   queryStr: 'platform=Nintendo&productType=game+point',
      //         //   idSubChild: 1228,
      //         // },
      //       ],
      //     },
      //     // {
      //     //   name: 'Switch Games',
      //     //   displayName: 'Switch Games',
      //     //   idSub: 120,
      //     //   children: [
      //     //     {
      //     //       name: '[pdp]just-dance-2024-eu-nintendo-switch--nintendo--digital-code-p9887907',
      //     //       displayName: 'Just Dance 2024',
      //     //       url: '/just-dance-2024-eu-xbox-series-xs-xbox-live-digital-code-p9887894',
      //     //       queryStr:
      //     //         'just-dance-2024-eu-nintendo-switch--nintendo--digital-code-p9887907',
      //     //       idSubChild: 1265,
      //     //     },
      //     //     {
      //     //       name: '[pdp]assassins-creed-ezio-collection-eu-nintendo-switch-nintendo-digital-code',
      //     //       displayName: "Assassin's Creed: Ezio Collection",
      //     //       url: '/assassins-creed-ezio-collection-eu-nintendo-switch-nintendo-digital-code',
      //     //       queryStr:
      //     //         'assassins-creed-ezio-collection-eu-nintendo-switch-nintendo-digital-code',
      //     //       idSubChild: 1266,
      //     //     },
      //     //     {
      //     //       name: '[pdp]rabbids-party-of-legends-eu-nintendo-switch--nintendo--digital-code-p9889341',
      //     //       displayName: 'Rabbids: Party of Legends',
      //     //       url: '/rabbids-party-of-legends-eu-nintendo-switch--nintendo--digital-code-p9889341',
      //     //       queryStr:
      //     //         'rabbids-party-of-legends-eu-nintendo-switch--nintendo--digital-code-p9889341',
      //     //       idSubChild: 1267,
      //     //     },
      //     //     {
      //     //       name: '[pdp]mario-kart-8-deluxe-edition-us-nintendo-switch-nintendo-digital-code-p9881183',
      //     //       displayName: 'Mario Kart 8 Deluxe Edition',
      //     //       url: '/mario-kart-8-deluxe-edition-us-nintendo-switch-nintendo-digital-code-p9881183',
      //     //       queryStr:
      //     //         'mario-kart-8-deluxe-edition-us-nintendo-switch-nintendo-digital-code-p9881183',
      //     //       idSubChild: 1268,
      //     //     },
      //     //     {
      //     //       name: '[pdp]crysis-remastered-trilogy-eu-nintendo-switch--nintendo--digital-code-p9888161',
      //     //       displayName: 'Crysis Remastered Trilogy',
      //     //       url: '/crysis-remastered-trilogy-eu-nintendo-switch--nintendo--digital-code-p9888161',
      //     //       queryStr:
      //     //         'crysis-remastered-trilogy-eu-nintendo-switch--nintendo--digital-code-p9888161',
      //     //       idSubChild: 1269,
      //     //     },
      //     //     {
      //     //       name: '[pdp]lego-2k-drive-eu-nintendo-switch--nintendo--digital-code-p9888223',
      //     //       displayName: 'LEGO 2K Drive',
      //     //       url: '/lego-2k-drive-eu-nintendo-switch--nintendo--digital-code-p9888223',
      //     //       queryStr:
      //     //         'lego-2k-drive-eu-nintendo-switch--nintendo--digital-code-p9888223',
      //     //       idSubChild: 1270,
      //     //     },
      //     //     {
      //     //       name: '[pdp]super-mario-odyssey-us-nintendo-switch-nintendo-digital-code-p9881184',
      //     //       displayName: 'Super Mario Odyssey',
      //     //       url: '/super-mario-odyssey-us-nintendo-switch-nintendo-digital-code-p9881184',
      //     //       queryStr:
      //     //         'super-mario-odyssey-us-nintendo-switch-nintendo-digital-code-p9881184',
      //     //       idSubChild: 1271,
      //     //     },
      //     //     {
      //     //       name: '[pdp]the-outer-worlds-eu-nintendo-switch--nintendo--digital-code-p9888203',
      //     //       displayName: 'The Outer Worlds',
      //     //       url: '/the-outer-worlds-spacers-choice-edition-eu-pc-steam-digital-code-p9892943',
      //     //       queryStr:
      //     //         'the-outer-worlds-eu-nintendo-switch--nintendo--digital-code-p9888203',
      //     //       idSubChild: 1272,
      //     //     },
      //     //     {
      //     //       name: 'nintendo-switch-games',
      //     //       displayName: 'View All',
      //     //       url: '/store?platform=Nintendo&productType[]=game',
      //     //       queryStr: 'platform=Nintendo&productType=game',
      //     //       idSubChild: 1273,
      //     //     },
      //     //     //  {
      //     //     //   name: '[pdp]ark-survival-evolved-eu-nintendo-switch-cd-key-p599037',
      //     //     //   displayName: "ARK: Survival Evolved",
      //     //     //   url: '/the-outer-worlds-spacers-choice-edition-eu-pc-steam-digital-code-p9892943',
      //     //     //   queryStr: 'ark-survival-evolved-eu-nintendo-switch-cd-key-p599037',
      //     //     //   idSubChild: 1056,
      //     //     // },
      //     //     //  {
      //     //     //   name: '[pdp]sid-meiers-civilization-vi-eu-nintendo-switch--nintendo--digital-code-p9888103',
      //     //     //   displayName: "Sid Meier's Civilization VI",
      //     //     //   url: '/the-outer-worlds-spacers-choice-edition-eu-pc-steam-digital-code-p9892943',
      //     //     //   queryStr: 'sid-meiers-civilization-vi-eu-nintendo-switch--nintendo--digital-code-p9888103',
      //     //     //   idSubChild: 1056,
      //     //     // },
      //     //   ],
      //     // },
      //     // {
      //     //   name: 'Nintendo Gift Cards',
      //     //   displayName: 'Nintendo Gift Cards',
      //     //   idSub: 121,
      //     //   children: [
      //     //     {
      //     //       name: '[pdp]nintendo-eshop-10-gift-card-us-digital-code',
      //     //       displayName: 'Nintendo eShop $10 (US)',
      //     //       url: '/store?q=Nintendo+switch+online+12+months+family&platform[]=PSN&productType[]=subscription&page=1&region=28',
      //     //       queryStr: 'nintendo-eshop-10-gift-card-us-digital-code',
      //     //       idSubChild: 1074,
      //     //     },
      //     //     {
      //     //       name: '[pdp]nintendo-eshop-9000-jpy-gift-card-jp-digital-code',
      //     //       displayName: 'Nintendo eShop ¥9000 (JP)',
      //     //       url: '/store?q=Nintendo+switch+online+12+months+family&platform[]=PSN&productType[]=subscription&page=1&region=28',
      //     //       queryStr: 'nintendo-eshop-9000-jpy-gift-card-jp-digital-code',
      //     //       idSubChild: 1075,
      //     //     },
      //     //     {
      //     //       name: '[pdp]nintendo-eshop-2000-jpy-gift-card-jp-digital-code',
      //     //       displayName: 'Nintendo eShop ¥2000 (JP)',
      //     //       url: '/store?q=Nintendo+switch+online+12+months+family&platform[]=PSN&productType[]=subscription&page=1&region=28',
      //     //       queryStr: 'nintendo-eshop-2000-jpy-gift-card-jp-digital-code',
      //     //       idSubChild: 1076,
      //     //     },
      //     //     {
      //     //       name: '[pdp]nintendo-eshop-100-hkd-gift-card-hk-digital-code-p9883959',
      //     //       displayName: 'Nintendo eShop $100 (HK)',
      //     //       url: '/store?q=Nintendo+switch+online+12+months+family&platform[]=PSN&productType[]=subscription&page=1&region=28',
      //     //       queryStr:
      //     //         'nintendo-eshop-100-hkd-gift-card-hk-digital-code-p9883959',
      //     //       idSubChild: 1077,
      //     //     },
      //     //     {
      //     //       name: '[pdp]nintendo-eshop-500-hkd-gift-card-hk-digital-code-p9883983',
      //     //       displayName: 'Nintendo eShop $500 (HK)',
      //     //       url: '/store?q=Nintendo+switch+online+12+months+family&platform[]=PSN&productType[]=subscription&page=1&region=28',
      //     //       queryStr:
      //     //         'nintendo-eshop-500-hkd-gift-card-hk-digital-code-p9883983',
      //     //       idSubChild: 1078,
      //     //     },
      //     //     {
      //     //       name: '[pdp]nintendo-eshop-300-hkd-gift-card-hk-digital-code-p9883982',
      //     //       displayName: 'Nintendo eShop $300 (HK)',
      //     //       url: '/store?q=Nintendo+switch+online+12+months+family&platform[]=PSN&productType[]=subscription&page=1&region=28',
      //     //       queryStr:
      //     //         'nintendo-eshop-300-hkd-gift-card-hk-digital-code-p9883982',
      //     //       idSubChild: 1079,
      //     //     },
      //     //     {
      //     //       name: '[pdp]nintendo-eshop-50-gift-card-us-digital-code',
      //     //       displayName: 'Nintendo eShop $50 (US)',
      //     //       url: '/store?q=Nintendo+switch+online+12+months+family&platform[]=PSN&productType[]=subscription&page=1&region=28',
      //     //       queryStr: 'nintendo-eshop-50-gift-card-us-digital-code',
      //     //       idSubChild: 1080,
      //     //     },
      //     //     {
      //     //       name: '[pdp]nintendo-eshop-100-gift-card-eu-digital-code-p9883445',
      //     //       displayName: 'Nintendo eShop €100 (EU)',
      //     //       url: '/store?q=Nintendo+switch+online+12+months+family&platform[]=PSN&productType[]=subscription&page=1&region=28',
      //     //       queryStr: 'nintendo-eshop-100-gift-card-eu-digital-code-p9883445',
      //     //       idSubChild: 1081,
      //     //     },
      //     //     {
      //     //       name: 'nintendo-switch-giftcards',
      //     //       displayName: 'View All',
      //     //       url: '/store?platform=Nintendo&productType[]=giftcard',
      //     //       queryStr: 'platform=Nintendo&productType=giftcard',
      //     //       idSubChild: 1282,
      //     //     },
      //     //     //  {
      //     //     //   name: '[pdp]nintendo-eshop-75-gift-card-eu-digital-code-p9883443',
      //     //     //   displayName: 'Nintendo eShop €75 (EU)',
      //     //     //   url: '/store?q=Nintendo+switch+online+12+months+family&platform[]=PSN&productType[]=subscription&page=1&region=28',
      //     //     //   queryStr:
      //     //     //     'nintendo-eshop-75-gift-card-eu-digital-code-p9883443',
      //     //     //   idSubChild: 1050,
      //     //     // },
      //     //     //  {
      //     //     //   name: '[pdp]nintendo-eshop-10-cad-gift-card-ca-digital-code-p9884566',
      //     //     //   displayName: 'Nintendo eShop $10 (CA)',
      //     //     //   url: '/store?q=Nintendo+switch+online+12+months+family&platform[]=PSN&productType[]=subscription&page=1&region=28',
      //     //     //   queryStr:
      //     //     //     'nintendo-eshop-10-cad-gift-card-ca-digital-code-p9884566',
      //     //     //   idSubChild: 1050,
      //     //     // },
      //     //   ],
      //     // },
      //     // {
      //     //   name: 'Switch Online ',
      //     //   displayName: 'Switch Online Membership',
      //     //   idSub: 122,
      //     //   children: [
      //     //     {
      //     //       name: '[pdp]nintendo-switch-online-3-months-individual-membership-gb-digital-code-p9883454',
      //     //       displayName: 'Switch Online 3 Months (UK)',
      //     //       url: '/store?q=Nintendo+switch+online+3+months&platform[]=PSN&productType[]=subscription&page=1&region=8',
      //     //       queryStr:
      //     //         'q=Nintendo+switch+online+3+months&platform=PSN&productType=subscription&region=8',
      //     //       idSubChild: 1283,
      //     //     },
      //     //     {
      //     //       name: '[pdp]nintendo-switch-online-3-months-individual-membership-pl-digital-code',
      //     //       displayName: 'Switch Online 3 Months (PL)',
      //     //       url: '/store?q=Nintendo+switch+online+3+months&platform[]=PSN&productType[]=subscription&page=1&region=19',
      //     //       queryStr:
      //     //         'q=Nintendo+switch+online+3+months&platform=PSN&productType=subscription&region=19',
      //     //       idSubChild: 1284,
      //     //     },
      //     //     {
      //     //       name: '[pdp]nintendo-switch-online-12-months-individual-membership-pl-digital-code',
      //     //       displayName: 'Switch Online 12 Months (PL)',
      //     //       url: '/store?q=Nintendo+switch+online+12+months&platform[]=PSN&productType[]=subscription&page=1&region=19',
      //     //       queryStr:
      //     //         'q=Nintendo+switch+online+12+months&platform=PSN&productType=subscription&region=19',
      //     //       idSubChild: 1285,
      //     //     },
      //     //     {
      //     //       name: '[pdp]nintendo-switch-online-12-months-individual-membership-us-digital-code',
      //     //       displayName: 'Switch Online 12 Months (US)',
      //     //       url: '/store?q=Nintendo+switch+online+12+months&platform[]=PSN&productType[]=subscription&page=1&region=2',
      //     //       queryStr:
      //     //         'q=Nintendo+switch+online+12+months&platform=PSN&productType=subscription&region=2',
      //     //       idSubChild: 1286,
      //     //     },
      //     //     {
      //     //       name: '[pdp]nintendo-switch-online-12-months-individual-membership-eu-digital-code-p9883452',
      //     //       displayName: 'Switch Online 12 Months (EU)',
      //     //       url: '/store?q=Nintendo+switch+online+12+months&platform[]=PSN&productType[]=subscription&page=1&region=1',
      //     //       queryStr:
      //     //         'q=Nintendo+switch+online+12+months&platform=PSN&productType=subscription&region=1',
      //     //       idSubChild: 1287,
      //     //     },
      //     //     {
      //     //       name: '[pdp]nintendo-switch-online-12-months-individual-membership-uk-digital-code-p988511',
      //     //       displayName: 'Switch Online 12 Months (UK)',
      //     //       url: '/store?q=Nintendo+switch+online+12+months&platform[]=PSN&productType[]=subscription&page=1&region=8',
      //     //       queryStr:
      //     //         'q=Nintendo+switch+online+12+months&platform=PSN&productType=subscription&region=8',
      //     //       idSubChild: 1288,
      //     //     },
      //     //     {
      //     //       name: '[pdp]nintendo-switch-online-3-months-individual-membership-us-digital-code-p360295',
      //     //       displayName: 'Switch Online 3 Months (US)',
      //     //       url: '/store?q=Nintendo+switch+online+3+months&platform[]=PSN&productType[]=subscription&page=1&region=2',
      //     //       queryStr:
      //     //         'q=Nintendo+switch+online+3+months&platform=PSN&productType=subscription&region=2',
      //     //       idSubChild: 1289,
      //     //     },
      //     //     {
      //     //       name: '[pdp]nintendo-switch-online-12-months-family-membership-eu-digital-code-p9887833',
      //     //       displayName: 'Switch Online 12 Months Family (EU)',
      //     //       url: '/store?q=Nintendo+switch+online+12+months+family&platform[]=PSN&productType[]=subscription&page=1&region=1',
      //     //       queryStr:
      //     //         'q=Nintendo+switch+online+12+months+family&platform=PSN&productType=subscription&region=1',
      //     //       idSubChild: 1290,
      //     //     },
      //     //     {
      //     //       name: 'swicth-online-membership',
      //     //       displayName: 'View All',
      //     //       url: '/store?platform=Nintendo&productType[]=subscription&page=1',
      //     //       queryStr: 'platform=Nintendo&productType=subscription',
      //     //       idSubChild: 1291,
      //     //     },
      //     //     //   {
      //     //     //   name: '[pdp]nintendo-switch-online-3-months-individual-membership-eu-digital-code-p9883451',
      //     //     //   displayName: 'Switch Online 3 Months (EU)',
      //     //     //   url: '/store?q=Nintendo+switch+online+3+months&platform[]=PSN&productType[]=subscription&page=1&region=1',
      //     //     //   queryStr:
      //     //     //     'q=Nintendo+switch+online+3+months&platform=PSN&productType=subscription&region=1',
      //     //     //   idSubChild: 1050,
      //     //     // },
      //     //     //  {
      //     //     //   name: '[pdp]nintendo-switch-online-3-months-family-membership-eu-digital-code-p9896145',
      //     //     //   displayName: 'Nintendo Switch Online 3 Months Family (EU)',
      //     //     //   url: '/store?q=Nintendo+switch+online+12+months+family&platform[]=PSN&productType[]=subscription&page=1&region=1',
      //     //     //   queryStr:
      //     //     //     'q=Nintendo+switch+online+12+months+family&platform=PSN&productType=subscription&region=1',
      //     //     //   idSubChild: 1050,
      //     //     // },
      //     //   ],
      //     // },
      //     {
      //       name: gift_cards_by_region_msg ?? '',
      //       displayName: 'Gift Cards By Region',
      //       idSub: 123,
      //       children: [
      //         {
      //           name: 'nintendo-gift-cards-united-states',
      //           displayName: 'Nintendo eShop United States',
      //           url: '/store?platform[]=Nintendo&productType[]=giftcard&page=1&region=2&worksIn=false',
      //           queryStr:
      //             'platform=Nintendo&productType=giftcard&region=2&worksIn=false',
      //           idSubChild: 1292,
      //           navbar: true,
      //         },
      //         {
      //           name: 'nintendo-gift-cards-japan',
      //           displayName: 'Nintendo eShop Japan',
      //           url: '/store?platform[]=Nintendo&productType[]=giftcard&page=1&region=17&worksIn=false',
      //           queryStr:
      //             'platform=Nintendo&productType=giftcard&region=17&worksIn=false',
      //           idSubChild: 1293,
      //           navbar: true,
      //         },
      //         {
      //           name: 'nintendo-gift-cards-hong-kong',
      //           displayName: 'Nintendo eShop Hong kong',
      //           url: '/store?platform[]=Nintendo&productType[]=giftcard&page=1&region=22&worksIn=false',
      //           queryStr:
      //             'platform=Nintendo&productType=giftcard&region=22&worksIn=false',
      //           idSubChild: 1294,
      //           navbar: true,
      //         },
      //         {
      //           name: 'nintendo-gift-cards-europe',
      //           displayName: 'Nintendo eShop Europe',
      //           url: '/store?platform[]=Nintendo&productType[]=giftcard&page=1&region=1&worksIn=false',
      //           queryStr:
      //             'platform=Nintendo&productType=giftcard&region=1&worksIn=false',
      //           idSubChild: 1295,
      //           navbar: true,
      //         },
      //         {
      //           name: 'nintendo-gift-cards-united-kingdom',
      //           displayName: 'Nintendo eShop United Kingdom',
      //           url: '/store?platform[]=Nintendo&productType[]=giftcard&page=1&region=8&worksIn=false',
      //           queryStr:
      //             'platform=Nintendo&productType=giftcard&region=8&worksIn=false',
      //           idSubChild: 1296,
      //           navbar: true,
      //         },
      //         {
      //           name: 'nintendo-gift-cards-mexico',
      //           displayName: 'Nintendo eShop Mexico',
      //           url: '/store?platform[]=Nintendo&productType[]=giftcard&page=1&region=32&worksIn=false',
      //           queryStr:
      //             'platform=Nintendo&productType=giftcard&region=32&worksIn=false',
      //           idSubChild: 1297,
      //           navbar: true,
      //         },
      //         {
      //           name: 'nintendo-gift-cards-canada',
      //           displayName: 'Nintendo eShop Canada',
      //           url: '/store?platform[]=Nintendo&productType[]=giftcard&page=1&region=28&worksIn=false',
      //           queryStr:
      //             'platform=Nintendo&productType=giftcard&region=28&worksIn=false',
      //           idSubChild: 1298,
      //           navbar: true,
      //         },
      //         {
      //           name: 'nintendo-gift-cards-brazil',
      //           displayName: 'Nintendo eShop Brazil',
      //           url: '/store?platform[]=Nintendo&productType[]=giftcard&page=1&region=15&worksIn=false',
      //           queryStr:
      //             'platform=Nintendo&productType=giftcard&region=15&worksIn=false',
      //           idSubChild: 1299,
      //           navbar: true,
      //         },
      //         {
      //           name: 'nintendo-gift-cards',
      //           displayName: view_all_msg ?? '',
      //           url: '/store?platform[]=Nintendo&productType[]=giftcard&page=1',
      //           queryStr: 'platform=Nintendo&productType=giftcard',
      //           idSubChild: 1299,
      //           navbar: true,
      //         },
      //       ],
      //     },
      //     {
      //       name:
      //         platform_subscription_by_region_msg?.replace(
      //           '[_PLATFORM_]',
      //           'Nintendo'
      //         ) ?? '',
      //       displayName: 'Nintendo Subscriptions By Region',
      //       idSub: 124,
      //       children: [
      //         {
      //           name: 'nintendo-subscriptions-poland',
      //           displayName: 'Nintendo Subscriptions Poland',
      //           url: '/store?platform=NintendoproductType[]=subscription&page=1&region=19&worksIn=false',
      //           queryStr:
      //             'platform=Nintendo&productType=subscription&region=19&worksIn=false',
      //           idSubChild: 1300,
      //           navbar: true,
      //         },
      //         {
      //           name: 'nintendo-subscriptions-europe',
      //           displayName: 'Nintendo Subscriptions Europe',
      //           url: '/store?platform[]=Nintendo&productType[]=subscription&page=1&region=1&worksIn=false',
      //           queryStr:
      //             'platform=Nintendo&productType=subscription&region=1&worksIn=false',
      //           idSubChild: 1301,
      //           navbar: true,
      //         },
      //         {
      //           name: 'nintendo-subscriptions-united-states',
      //           displayName: 'Nintendo Subscriptions United States',
      //           url: '/store?platform[]=Nintendo&productType[]=subscription&page=1&region=2&worksIn=false',
      //           queryStr:
      //             'platform=Nintendo&productType=subscription&region=2&worksIn=false',
      //           idSubChild: 1302,
      //           navbar: true,
      //         },
      //         {
      //           name: 'nintendo-subscriptions-united-kingdom',
      //           displayName: 'Nintendo Subscriptions United Kingdom',
      //           url: '/store?platform[]=Nintendo&productType[]=subscription&page=1&region=8&worksIn=false',
      //           queryStr:
      //             'platform=Nintendo&productType=subscription&region=8&worksIn=false',
      //           idSubChild: 1303,
      //           navbar: true,
      //         },
      //         {
      //           name: 'nintendo-subscriptions',
      //           displayName: view_all_msg ?? '',
      //           url: '/store?platform[]=Nintendo&productType[]=subscription&page=1',
      //           queryStr: 'platform=Nintendo&productType=subscription',
      //           idSubChild: 1303,
      //           navbar: true,
      //         },
      //       ],
      //     },
      //     // {
      //     //   name: 'Price Range',
      //     //   displayName: 'Price Range',
      //     //   idSub: 111,
      //     //   children: [
      //     //     {
      //     //       name: 'Nintendo by price (Low to High)',
      //     //       displayName: 'Nintendo by price (Low to High)',
      //     //       url: '/store?platform[]=Nintendo&page=1&sort=l2h',
      //     //       queryStr: 'platform=Nintendo&page=1&sort=l2h',
      //     //       idSubChild: 1057,
      //     //     },
      //     //     {
      //     //       name: 'Nintendo by price (High to Low)',
      //     //       displayName: 'Nintendo by price (High to Low)',
      //     //       url: '/store?platform[]=Nintendo&page=1&sort=h2l',
      //     //       queryStr: 'platform=Nintendo&page=1&sort=h2l',
      //     //       idSubChild: 1058,
      //     //     },
      //     //   ],
      //     // },
      //     // {
      //     //   name: 'Gift cards by region',
      //     //   displayName: 'Gift cards by region',
      //     //   children: [
      //     //     {
      //     //       name: 'Nintendo eShop Cards US',
      //     //       displayName: 'Nintendo eShop Cards US',
      //     //       url: '/store?platform[]=Nintendo&productType[]=giftcard&page=1&region[]=2',
      //     //     },
      //     //   ],
      //     // },
      //     // {
      //     //   name: 'Memberships by Region',
      //     //   displayName: 'Memberships by Region',
      //     //   children: [
      //     //     {
      //     //       name: 'Nintendo Online Memberships US',
      //     //       displayName: 'Nintendo Online Memberships US',
      //     //       url: '/store?platform[]=Nintendo&productType[]=giftcard&page=1&region[]=2',
      //     //     },
      //     //   ],
      //     // },
      //   ],
      //   navbar: true,
      // },

      // {
      //   name: 'PC',
      //   displayName: 'PC',
      //   url: '/store?worksOn[]=windows&worksOn[]=mac&worksOn[]=linux&page=1',
      //   queryStr: 'worksOn[]=windows&worksOn[]=mac&worksOn[]=linux&page=1',
      //   id: 7,
      //   subDivision: [
      //     {
      //       name: 'PC Games',
      //       displayName: 'PC Games',
      //       idSub: 125,
      //       children: [
      //         {
      //           name: '[pdp]grand-theft-auto-v-rockstar-digital-download-cd-key-p510811',
      //           displayName: 'Grand Theft Auto V',
      //           url: '/grand-theft-auto-v-rockstar-digital-download-cd-key-p510811',
      //           queryStr: '',
      //           idSubChild: 1304,
      //         },
      //         {
      //           name: '[pdp]palworld-pc-steam-digital-code-p9893165',
      //           displayName: 'Palworld',
      //           url: '/palworld-pc-steam-digital-code-p9893165',
      //           queryStr: '',
      //           idSubChild: 1305,
      //         },
      //         {
      //           name: '[pdp]cities-skylines-ii-pc-steam-digital-code-p9886770',
      //           displayName: 'Cities: Skylines II',
      //           url: '/cities-skylines-ii-pc-steam-digital-code-p9886770',
      //           queryStr: '',
      //           idSubChild: 1306,
      //         },
      //         {
      //           name: '[pdp]hell-let-loose-p9879310',
      //           displayName: 'Hell Let Loose',
      //           url: '/hell-let-loose-p9879310',
      //           queryStr: '',
      //           idSubChild: 1307,
      //         },
      //         {
      //           name: '[pdp]ea-sports-fc-24-pc-ea-play-digital-code-p9886721',
      //           displayName: 'EA SPORTS FC 24',
      //           url: '/ea-sports-fc-24-pc-ea-play-digital-code-p9886721',
      //           queryStr: '',
      //           idSubChild: 1308,
      //         },
      //         {
      //           name: '[pdp]helldivers-2-row-pc-steam-digital-code-p9895570',
      //           displayName: 'Helldivers 2',
      //           url: '/helldivers-2-row-pc-steam-digital-code-p9895570',
      //           queryStr: '',
      //           idSubChild: 1309,
      //         },
      //         {
      //           name: '[pdp]mount-blade-ii-bannerlord-p9879952',
      //           displayName: 'Mount & Blade II: Bannerlord',
      //           url: '/mount-blade-ii-bannerlord-p9879952',
      //           queryStr: '',
      //           idSubChild: 1310,
      //         },
      //         {
      //           name: '[pdp]ready-or-not-eu-pc-steam-digital-code-p9884380',
      //           displayName: 'Ready or Not',
      //           url: '/ready-or-not-eu-pc-steam-digital-code-p9884380',
      //           queryStr: '',
      //           idSubChild: 1311,
      //         },
      //         {
      //           name: '[pdp]minecraft-java-bedrock-edition-pc-microsoft-store-digital-code-p9879164',
      //           displayName: 'Minecraft Java and Bedrock Edition',
      //           url: '/minecraft-java-bedrock-edition-pc-microsoft-store-digital-code-p9879164',
      //           queryStr: '',
      //           idSubChild: 1312,
      //         },
      //         // {
      //         //   name: '[pdp]red-dead-redemption-2-rockstar-digital-download-cd-key-p925113',
      //         //   displayName: 'Red Dead Redemption 2',
      //         //   url: '/red-dead-redemption-2-rockstar-digital-download-cd-key-p925113',
      //         //   queryStr: '',
      //         //   idSubChild: 1056,
      //         // },
      //         // {
      //         //   name: 'Elden Ring ',
      //         //   displayName: 'Elden Ring ',
      //         //   url: '/elden-ring-pc-steam-digital-code-p9884385',
      //         //   queryStr: '',
      //         //   idSubChild: 1056,
      //         // },
      //         // {
      //         //   name: 'Monster Hunter World',
      //         //   displayName: 'Monster Hunter World',
      //         //   url: '/monster-hunter-world-pc-steam-digital-code',
      //         //   queryStr: '',
      //         //   idSubChild: 1056,
      //         // },
      //         // {
      //         //   name: 'Hogwarts Legacy',
      //         //   displayName: 'Hogwarts Legacy',
      //         //   url: '/hogwarts-legacy-pc-steam-digital-code-p9883455',
      //         //   queryStr: '',
      //         //   idSubChild: 1056,
      //         // },
      //         // {
      //         //   name: 'Horizon Zero Dawn Complete Edition',
      //         //   displayName: 'Horizon Zero Dawn Complete Edition',
      //         //   url: '/horizon-zero-dawn-complete-edition-steam-cd-key-p676042',
      //         //   queryStr: '',
      //         //   idSubChild: 1056,
      //         // },
      //       ],
      //     },
      //     {
      //       name: 'PC softwares',
      //       displayName: 'PC softwares',
      //       idSub: 126,
      //       children: [
      //         {
      //           name: '[pdp]exitlag-1-month-software-license-digital-code-p9888286',
      //           displayName: 'ExitLag',
      //           url: '/exitlag-1-month-software-license-digital-code-p9888286',
      //           queryStr: '',
      //           idSubChild: 1313,
      //         },
      //         {
      //           name: '[pdp]3dmark-steam-cd-key-p784330',
      //           displayName: '3DMark',
      //           url: '/3dmark-steam-cd-key-p784330',
      //           queryStr: '',
      //           idSubChild: 1314,
      //         },
      //         {
      //           name: '[pdp]ccleaner-professional-plus-pc-1-device-1-year-digital-code-p9891078',
      //           displayName: 'CCleaner Professional',
      //           url: '/ccleaner-professional-plus-pc-1-device-1-year-digital-code-p9891078',
      //           queryStr: '',
      //           idSubChild: 1315,
      //         },
      //         {
      //           name: '[pdp]dolby-atmos-for-headphones-ar-pc-xbox-one-xbox-series-xs--xbox-live--digital-code-p9884629',
      //           displayName: 'Dolby Atmos for Headphones',
      //           url: '/dolby-atmos-for-headphones-ar-pc-xbox-one-xbox-series-xs--xbox-live--digital-code-p9884629',
      //           queryStr: '',
      //           idSubChild: 1316,
      //         },
      //         // {
      //         //   name: '[pdp]dolby-atmos-for-headphones-ar-pc-xbox-one-xbox-series-xs--xbox-live--digital-code-p9884629',
      //         //   displayName: 'Dolby Atmos for Headphones',
      //         //   url: '/dolby-atmos-for-headphones-ar-pc-xbox-one-xbox-series-xs--xbox-live--digital-code-p9884629',
      //         //   queryStr: '',
      //         //   idSubChild: 1317,
      //         // },
      //         {
      //           name: '[pdp]aomei-backupper-professional-edition-2023-1-server-1-year-digital-code-p9886442',
      //           displayName: 'AOMEI Backupper Professional Edition 2023',
      //           url: '/aomei-backupper-professional-edition-2023-1-server-1-year-digital-code-p9886442',
      //           queryStr: '',
      //           idSubChild: 1318,
      //         },
      //         {
      //           name: '[pdp]avg-ultimate-2023-1-device-2-year-digital-code-p9887991',
      //           displayName: 'AVG Ultimate',
      //           url: '/avg-ultimate-2023-1-device-2-year-digital-code-p9887991',
      //           queryStr: '',
      //           idSubChild: 1319,
      //         },
      //         {
      //           name: '[pdp]mcafee-antivirus-plus-1-device-1-year-digital-code-p9888444',
      //           displayName: 'McAfee AntiVirus',
      //           url: '/mcafee-antivirus-plus-1-device-1-year-digital-code-p9888444',
      //           queryStr: '',
      //           idSubChild: 1320,
      //         },
      //         {
      //           name: '[pdp]autodesk-autocad-edu-2023-pc-2-devices-1-year-digital-code-p9890879',
      //           displayName: 'AUTODESK AUTOCAD (2023)',
      //           url: '/autodesk-autocad-edu-2023-pc-2-devices-1-year-digital-code-p9890879',
      //           queryStr: '',
      //           idSubChild: 1318,
      //         },
      //         // {
      //         //   name: 'PC-softwares',
      //         //   displayName: 'View All',
      //         //   url: '/autodesk-autocad-edu-2023-pc-2-devices-1-year-digital-code-p9890879',
      //         //   queryStr:
      //         //     'productType=software&worksOn[]=windows&worksOn[]=mac&worksOn[]=linux',
      //         //   idSubChild: 1318,
      //         // },

      //         {
      //           name: 'McAfee Total Protection',
      //           displayName: 'McAfee Total Protection',
      //           url: '/mcafee-total-protection-10-devices-1-year-digital-code-p9886474',
      //           queryStr: '',
      //           idSubChild: 1056,
      //         },
      //         // {
      //         //   name: 'AUTODESK AUTOCAD (2024)',
      //         //   displayName: 'AUTODESK AUTOCAD (2024)',
      //         //   url: '/autodesk-autocad-edu-2024-pc-2-devices-1-year-digital-code-p9890881',
      //         //   queryStr: '',
      //         //   idSubChild: 1056,
      //         // },
      //         // {
      //         //   name: 'Eset Smart Security',
      //         //   displayName: 'Eset Smart Security',
      //         //   url: '/eset-smart-security-2023-pc-mac-android-1-device-2-years-digital-code-p9889488',
      //         //   queryStr: '',
      //         //   idSubChild: 1056,
      //         // },
      //         // {
      //         //   name: 'Kaspersky Total Security',
      //         //   displayName: 'Kaspersky Total Security',
      //         //   url: '/kaspersky-total-security-eu-2023-2-devices-1-year-digital-code-p9888428',
      //         //   queryStr: '',
      //         //   idSubChild: 1056,
      //         // },
      //         // {
      //         //   name: 'AOMEI Backupper Professional Edition 2023',
      //         //   displayName: 'AOMEI Backupper Professional Edition 2023',
      //         //   url: '/aomei-backupper-professional-edition-2023-1-server-1-year-digital-code-p9886442',
      //         //   queryStr: '',
      //         //   idSubChild: 1056,
      //         // },
      //         // {
      //         //   name: 'Avast Ultimate',
      //         //   displayName: 'Avast Ultimate',
      //         //   url: '/avast-ultimate-pc-1-device-2-years-digital-code-p9886539',
      //         //   queryStr: '',
      //         //   idSubChild: 1056,
      //         // },
      //       ],
      //     },
      //     {
      //       name: 'PC Gift Cards',
      //       displayName: 'PC Gift Cards',
      //       idSub: 127,
      //       children: [
      //         {
      //           name: '[pdp]steam-gift-card-500-inr-activation-code-p622515',
      //           displayName: 'Steam Wallet ₹500 (IN)',
      //           url: '/store?q=Steam%20Wallet%20₹500%20(IN)',
      //           queryStr: 'store?q=Steam%20Wallet%20₹500%20(IN)',
      //           idSubChild: 1321,
      //         },
      //         {
      //           name: '[pdp]xbox-game-pass-for-pc-trial-3-months-digital-code-p9884875',
      //           displayName: 'Xbox Game Pass for PC Trial',
      //           url: '/store?q=Xbox%20Game%20Pass%20for%20PC%20Trial',
      //           queryStr: 'store?q=Xbox%20Game%20Pass%20for%20PC%20Trial',
      //           idSubChild: 1322,
      //         },
      //         {
      //           name: '[pdp]ea-origin-15-eur-eu-pc-ea-play-digital-code',
      //           displayName: 'EA Origin 15 EUR',
      //           url: '/ea-origin-15-eur-eu-pc-ea-play-digital-code',
      //           queryStr: '',
      //           idSubChild: 1323,
      //         },
      //         {
      //           name: '[pdp]diablo-iv-gift-card-bundle-70-us-battlenet-digital-code',
      //           displayName: 'Diablo IV - Gift Card Bundle $70 (US)',
      //           url: '/store?q=Xbox%20Game%20Pass%20for%20PC%20Trial',
      //           queryStr: 'store?q=Xbox%20Game%20Pass%20for%20PC%20Trial',
      //           idSubChild: 1324,
      //         },
      //         {
      //           name: '[pdp]blizzard-10-gift-card-us-digital-code-p9883433',
      //           displayName: 'Blizzard $10 Gift Card (US)',
      //           url: '/blizzard-10-gift-card-us-digital-code-p9883433',
      //           queryStr: 'blizzard-10-gift-card-us-digital-code-p9883433',
      //           idSubChild: 1325,
      //         },
      //         {
      //           name: '[pdp]ea-play-15-usd-gift-card-us-digital-code-p9886250',
      //           displayName: 'EA Play $15 USD Gift Card (US))',
      //           url: '/store?q=EA%20Play%20$15%20USD%20Gift%20Card%20(US)',
      //           queryStr: 'store?q=EA%20Play%20$15%20USD%20Gift%20Card%20(US)',
      //           idSubChild: 1326,
      //         },
      //         {
      //           name: '[pdp]riot-access-usd-10-code-us-p9878679',
      //           displayName: 'Riot Access $10',
      //           url: '/riot-access-usd-10-code-us-p9878679',
      //           queryStr: '',
      //           idSubChild: 1327,
      //         },
      //         {
      //           name: '[pdp]valorant-5-eur-gift-card-eu-digital-code',
      //           displayName: 'Valorant €5 EUR Gift Card (EU)',
      //           url: '/valorant-5-eur-gift-card-eu-digital-code',
      //           queryStr: '',
      //           idSubChild: 1328,
      //         },
      //         {
      //           name: '[pdp]roblox-200-robux-digital-code-p9884392',
      //           displayName: 'Roblox - 200 Robux',
      //           url: '/roblox-200-robux-digital-code-p9884392',
      //           queryStr: '',
      //           idSubChild: 1329,
      //         },
      //       ],
      //     },
      //     {
      //       name: 'Items By Company',
      //       displayName: 'Items By Company',
      //       idSub: 128,
      //       children: [
      //         {
      //           name: 'steam',
      //           displayName: 'Steam',
      //           url: '/store?worksOn[]=linux&worksOn[]=mac&worksOn[]=windows&page=1&platform[]=Steam',
      //           queryStr:
      //             'worksOn=linux&worksOn=mac&worksOn=windows&platform=Steam',
      //           idSubChild: 1330,
      //         },
      //         {
      //           name: 'battle-net',
      //           displayName: 'Battle.net',
      //           url: '/store?worksOn[]=linux&worksOn[]=mac&worksOn[]=windows&page=1&platform[]=Battle.net',
      //           queryStr:
      //             'worksOn=linux&worksOn=mac&worksOn=windows&platform=Battle.net',
      //           idSubChild: 1331,
      //         },
      //         {
      //           name: 'avg',
      //           displayName: 'AVG',
      //           url: '/store?q=avg&worksOn[]=linux&worksOn[]=mac&worksOn[]=windows&page=1',
      //           queryStr: 'q=avg&worksOn=linux&worksOn=mac&worksOn=windows',
      //           idSubChild: 1401,
      //         },
      //         {
      //           name: 'EA-play',
      //           displayName: 'EA Play',
      //           url: '/store?worksOn[]=linux&worksOn[]=mac&worksOn[]=windows&page=1&platform[]=EA+Play',
      //           queryStr:
      //             'worksOn=linux&worksOn=mac&worksOn=windows&platform=EA+Play',
      //           idSubChild: 1332,
      //         },
      //         {
      //           name: 'aomei',
      //           displayName: 'AOMEI',
      //           url: '/store?q=aomei&worksOn[]=linux&worksOn[]=mac&worksOn[]=windows&page=1',
      //           queryStr: 'q=aomei&worksOn=linux&worksOn=mac&worksOn=windows',
      //           idSubChild: 1402,
      //         },
      //         {
      //           name: 'ubisoft-connect',
      //           displayName: 'Ubisoft Connect',
      //           url: '/store?worksOn[]=mac&worksOn[]=linux&worksOn[]=windows&page=1&platform[]=Ubisoft+Connect',
      //           queryStr:
      //             'worksOn=mac&worksOn=linux&worksOn=windows&platform=Ubisoft+Connect',
      //           idSubChild: 1333,
      //         },
      //         {
      //           name: 'epic-games',
      //           displayName: 'Epic Games',
      //           url: '/store?worksOn[]=mac&worksOn[]=linux&worksOn[]=windows&page=1&platform[]=Epic+Games',
      //           queryStr:
      //             'worksOn=mac&worksOn=linux&worksOn=windows&platform=Epic+Games',
      //           idSubChild: 1334,
      //         },
      //         {
      //           name: 'rockstar-games',
      //           displayName: 'Rockstar Games',
      //           url: '/store?worksOn[]=mac&worksOn[]=linux&worksOn[]=windows&page=1&platform[]=Rockstar+Games',
      //           queryStr:
      //             'worksOn=mac&worksOn=linux&worksOn=windows&platform=Rockstar+Games',
      //           idSubChild: 1335,
      //         },
      //         {
      //           name: 'microsoft-store',
      //           displayName: 'Microsoft Store',
      //           url: '/store?worksOn=mac&worksOn=linux&worksOn=windows&page=1&platform[]=Microsoft+Store',
      //           queryStr:
      //             'worksOn=mac&worksOn=linux&worksOn=windows&platform=Microsoft+Store',
      //           idSubChild: 1336,
      //         },
      //         // {
      //         //   name: 'GOG Games',
      //         //   displayName: 'GOG Games',
      //         //   url: '/store?worksOn[]=mac&worksOn[]=linux&worksOn[]=windows&page=1&platform[]=GOG+COM',
      //         //   queryStr:
      //         //     'worksOn[]=mac&worksOn[]=linux&worksOn[]=windows&platform[]=GOG+COM',
      //         //   idSubChild: 1337,
      //         // },
      //       ],
      //     },
      //     //  {
      //     //   name: 'Price Range',
      //     //   displayName: 'Price Range',
      //     //   idSub: 110,
      //     //   children: [
      //     //     {
      //     //       name: 'nintendo-switch-games',
      //     //       displayName: 'Nintendo Switch Games',
      //     //       url: '/store?platform[]=Nintendo&productType[]=game&page=1',
      //     //       queryStr: 'platform=Nintendo&productType=game',
      //     //       idSubChild: 1055,
      //     //     },
      //     //     {
      //     //       name: 'nintendo-eShop-cards',
      //     //       displayName: 'Nintendo eShop Cards',
      //     //       url: '/store?platform[]=Nintendo&productType[]=giftcard&page=1',
      //     //       queryStr: 'platform=Nintendo&productType=giftcard',
      //     //       idSubChild: 1056,
      //     //     },
      //     //   ],
      //     // },
      //   ],
      // },
      // {
      //   name: 'softwares',
      //   displayName: 'Softwares',
      //   url: '/store?productType[]=software&page=1',
      //   queryStr: 'productType=software',
      //   id: 8,
      //   subDivision: [
      //     {
      //       name: 'System Security',
      //       displayName: 'System Security',
      //       idSub: 129,
      //       children: [
      //         {
      //           name: '[pdp]avg-ultimate-2023-1-device-2-year-digital-code-p9887991',
      //           displayName: 'AVG Ultimate',
      //           url: '/avg-ultimate-2023-1-device-2-year-digital-code-p9887991',
      //           queryStr: 'q=avg+ultimate&productType=software',
      //           idSubChild: 1337,
      //         },
      //         {
      //           name: '[pdp]mcafee-antivirus-plus-1-device-1-year-digital-code-p9888444',
      //           displayName: 'McAfee AntiVirus',
      //           url: '/mcafee-antivirus-plus-1-device-1-year-digital-code-p9888444',
      //           queryStr: 'q=amcafee+antivirus&productType=software',
      //           idSubChild: 1338,
      //         },
      //         {
      //           name: '[pdp]mcafee-total-protection-10-devices-1-year-digital-code-p9886474',
      //           displayName: 'McAfee Total Protection',
      //           url: '/mcafee-total-protection-10-devices-1-year-digital-code-p9886474',
      //           queryStr: 'q=mcafee+total+protection&productType=software',
      //           idSubChild: 1339,
      //         },
      //         {
      //           name: '[pdp]eset-smart-security-2023-pc-mac-android-1-device-2-years-digital-code-p9889488',
      //           displayName: 'Eset Smart Security',
      //           url: '/eset-smart-security-2023-pc-mac-android-1-device-2-years-digital-code-p9889488',
      //           queryStr: 'q=eset+smart+security&productType=software',
      //           idSubChild: 1340,
      //         },
      //         {
      //           name: '[pdp]kaspersky-total-security-eu-2023-2-devices-1-year-digital-code-p9888428',
      //           displayName: 'Kaspersky Total Security',
      //           url: '/kaspersky-total-security-eu-2023-2-devices-1-year-digital-code-p9888428',
      //           queryStr: 'q=kaspersky+total+security&productType=software',
      //           idSubChild: 1341,
      //         },
      //         {
      //           name: '[pdp]avast-ultimate-pc-1-device-1-year-digital-code-p9886540',
      //           displayName: 'Avast Ultimate',
      //           url: '/avast-ultimate-pc-1-device-1-year-digital-code-p9886540',
      //           queryStr: 'q=avast+ultimate&productType=software',
      //           idSubChild: 1342,
      //         },
      //         {
      //           name: '[pdp]kaspersky-internet-security-uk-2023-3-devices-1-year-digital-code-p9888413',
      //           displayName: 'Kaspersky Internet Security',
      //           url: '/kaspersky-internet-security-uk-2023-3-devices-1-year-digital-code-p9888413',
      //           queryStr: 'q=kaspersky+internet+security&productType=software',
      //           idSubChild: 1343,
      //         },
      //         {
      //           name: '[pdp]avast-premium-security-2023-1-device-1-year-digital-code-p9887975',
      //           displayName: 'Avast Premium Security',
      //           url: '/avast-premium-security-2023-1-device-1-year-digital-code-p9887975',
      //           queryStr: 'q=avast+premium+security&productType=software',
      //           idSubChild: 1344,
      //         },
      //         {
      //           name: '[pdp]eset-nod32-antivirus-2023-pc-mac-1-device-2-years-digital-code-p9889490',
      //           displayName: 'Eset NOD32 Antivirus',
      //           url: '/eset-nod32-antivirus-2023-pc-mac-1-device-2-years-digital-code-p9889490',
      //           queryStr: 'q=eset+nod32+anitivirus&productType=software',
      //           idSubChild: 1345,
      //         },
      //         // {
      //         //   name: '[pdp]bitdefender-antivirus-plus-eu-2023-1-device-2-years-digital-code-p9888354',
      //         //   displayName: 'Bitdefender Antivirus Plus',
      //         //   url: '/bitdefender-antivirus-plus-eu-2023-1-device-2-years-digital-code-p9888354',
      //         //   queryStr: 'q=bitdefender+antivirus+plus&productType=software',
      //         //   idSubChild: 1056,
      //         // },
      //       ],
      //     },
      //     {
      //       name: 'System Management',
      //       displayName: 'System Management',
      //       idSub: 130,
      //       children: [
      //         {
      //           name: '[pdp]ccleaner-professional-android-1-device-1-year-digital-code-p9891076',
      //           displayName: 'CCleaner Professional',
      //           url: '/ccleaner-professional-android-1-device-1-year-digital-code-p9891076',
      //           queryStr: 'q=cleaner+professional&productType=software',
      //           idSubChild: 1346,
      //         },
      //         {
      //           name: '[pdp]dolby-atmos-for-headphones-ar-pc-xbox-one-xbox-series-xs--xbox-live--digital-code-p9884629',
      //           displayName: 'Dolby Atmos for Headphones',
      //           url: '/dolby-atmos-for-headphones-ar-pc-xbox-one-xbox-series-xs--xbox-live--digital-code-p9884629',
      //           queryStr: 'q=dolby+atmos+for+headphones&productType=software',
      //           idSubChild: 1347,
      //         },
      //         {
      //           name: '[pdp]aomei-backupper-professional-edition-2023-1-device-lifetime-digital-code-p9886439',
      //           displayName: 'AOMEI Backupper Professional',
      //           url: '/aomei-backupper-professional-edition-2023-1-device-lifetime-digital-code-p9886439',
      //           queryStr:
      //             'q=aomei+backupper+professional+edition+2023+1+device+lifetime&productType=software',
      //           idSubChild: 1348,
      //         },
      //         // {
      //         //   name: '[pdp]aomei-backupper-professional-edition-2023-2-device-lifetime-digital-code-p9886438',
      //         //   displayName:
      //         //     'AOMEI Backupper Professional Edition 2023 - 2 Device Lifetime',
      //         //   url: '/aomei-backupper-professional-edition-2023-2-device-lifetime-digital-code-p9886438',
      //         //   queryStr: '',
      //         //   idSubChild: 1056,
      //         // },
      //         {
      //           name: '[pdp]aomei-partition-assistant-professional-edition-2023-2-devices-lifetime-digital-code-p9886437',
      //           displayName: 'AOMEI Partition Assistant Professional',
      //           url: '/aomei-partition-assistant-professional-edition-2023-2-devices-lifetime-digital-code-p9886437',
      //           queryStr: '',
      //           idSubChild: 1349,
      //         },
      //         // {
      //         //   name: '[pdp]aomei-backupper-professional-edition-2023-1-device-1-year-digital-code-p9886444',
      //         //   displayName:
      //         //     'AOMEI Backupper Professional Edition 2023 1 Device 1 Year',
      //         //   url: '/aomei-backupper-professional-edition-2023-1-device-1-year-digital-code-p9886444',
      //         //   queryStr: '',
      //         //   idSubChild: 1056,
      //         // },
      //         {
      //           name: '[pdp]vmware-workstation-version-17-pc-2-devices-lifetime-key-digital-code-p9890886',
      //           displayName: 'VMWARE WORKSTATION: Version 17',
      //           url: '/vmware-workstation-version-17-pc-2-devices-lifetime-key-digital-code-p9890886',
      //           queryStr: '',
      //           idSubChild: 1350,
      //         },
      //         {
      //           name: '[pdp]aomei-backupper-workstation-1-device-lifetime-digital-code-p9886446',
      //           displayName: 'AOMEI Backupper Workstation',
      //           url: '/aomei-backupper-workstation-1-device-lifetime-digital-code-p9886446',
      //           queryStr: '',
      //           idSubChild: 1351,
      //         },

      //         {
      //           name: '[pdp] aomei-onekey-recovery-family-pack-edition-4-pc-lifetime-digital-code-p9886445',
      //           displayName: 'AOMEI OneKey Recovery Family Pack',
      //           url: '/aomei-backupper-workstation-1-device-lifetime-digital-code-p9886446',
      //           queryStr: '',
      //           idSubChild: 1352,
      //         },
      //         {
      //           name: '[pdp]avast-driver-updater-1-device-3-years-digital-code-p9886546',
      //           displayName: 'Avast Driver Updater',
      //           url: '/aomei-backupper-workstation-1-device-lifetime-digital-code-p9886446',
      //           queryStr: '',
      //           idSubChild: 1353,
      //         },
      //         {
      //           name: '[pdp]avg-driver-updater-1-device-1-year-digital-code-p9886554',
      //           displayName: 'AVG Driver Updater',
      //           url: '/aomei-backupper-workstation-1-device-lifetime-digital-code-p9886446',
      //           queryStr: '',
      //           idSubChild: 1354,
      //         },
      //         // {
      //         //   name: 'AOMEI OneKey Recovery Family Pack Edition 4 PC Lifetime',
      //         //   displayName:
      //         //     'AOMEI OneKey Recovery Family Pack Edition 4 PC Lifetime',
      //         //   url: '/aomei-onekey-recovery-family-pack-edition-4-pc-lifetime-digital-code-p9886445',
      //         //   queryStr: '',
      //         //   idSubChild: 1055,
      //         // },
      //         // {
      //         //   name: 'Avast Driver Updater',
      //         //   displayName: 'Avast Driver Updater',
      //         //   url: '/avast-driver-updater-1-device-3-years-digital-code-p9886546',
      //         //   queryStr: '',
      //         //   idSubChild: 1056,
      //         // },
      //       ],
      //     },
      //     {
      //       name: 'VPNs',
      //       displayName: 'VPNs',
      //       idSub: 131,
      //       children: [
      //         {
      //           name: '[pdp]avast-secureline-vpn-2022-10-devices-1-year-digital-code-p9886549',
      //           displayName: 'Avast SecureLine VPN',
      //           url: '/avast-secureline-vpn-2022-10-devices-1-year-digital-code-p9886549',
      //           queryStr: '',
      //           idSubChild: 1355,
      //         },
      //         {
      //           name: '[pdp]avg-secure-vpn-pc-android-mac-ios-10-devices-3-years-digital-code-p9886566',
      //           displayName: 'AVG Secure VPN',
      //           url: '/avg-secure-vpn-pc-android-mac-ios-10-devices-3-years-digital-code-p9886566',
      //           queryStr: '',
      //           idSubChild: 1356,
      //         },
      //         {
      //           name: '[pdp]bitdefender-premium-vpn-pc-10-devices-1-year-digital-code-p9888316',
      //           displayName: 'Bitdefender Premium VPN',
      //           url: '/bitdefender-premium-vpn-pc-10-devices-1-year-digital-code-p9888316',
      //           queryStr: '',
      //           idSubChild: 1357,
      //         },
      //         {
      //           name: '[pdp]hma-pro-vpn-pc-5-devices-2-years-digital-code-p9888526',
      //           displayName: 'HMA! Pro VPN',
      //           url: '/hma-pro-vpn-pc-5-devices-2-years-digital-code-p9888526',
      //           queryStr: '',
      //           idSubChild: 1358,
      //         },
      //         {
      //           name: '[pdp]f-secure-freedome-vpn-pc-3-devices-1-year-digital-code-p9891043',
      //           displayName: 'F-Secure Freedome VPN',
      //           url: '/f-secure-freedome-vpn-pc-3-devices-1-year-digital-code-p9891043',
      //           queryStr: '',
      //           idSubChild: 1359,
      //         },
      //         {
      //           name: '[pdp]kaspersky-vpn-secure-connection-pc-5-devices-1-year-digital-code-p9891053',
      //           displayName: 'Kaspersky VPN Secure Connection',
      //           url: '/kaspersky-vpn-secure-connection-pc-5-devices-1-year-digital-code-p9891053',
      //           queryStr: '',
      //           idSubChild: 1360,
      //         },
      //         {
      //           name: '[pdp]mcafee-safe-vpn-premium-pc-5-devices-1-year-digital-code-p9890969',
      //           displayName: 'McAfee Safe VPN Premium',
      //           url: '/mcafee-safe-vpn-premium-pc-5-devices-1-year-digital-code-p9890969',
      //           queryStr: '',
      //           idSubChild: 1361,
      //         },
      //         {
      //           name: '[pdp]norton-secure-vpn-us-pc-5-devices-1-year-digital-code-p9891018',
      //           displayName: 'Norton Secure VPN',
      //           url: '/norton-secure-vpn-us-pc-5-devices-1-year-digital-code-p9891018',
      //           queryStr: '',
      //           idSubChild: 1362,
      //         },
      //       ],
      //     },
      //     {
      //       name: 'System Performance',
      //       displayName: 'System Performance',
      //       idSub: 132,
      //       children: [
      //         {
      //           name: '[pdp]exitlag-12-months-software-license-digital-code-p9888290',
      //           displayName: 'ExitLag',
      //           url: '/exitlag-12-months-software-license-digital-code-p9888290',
      //           queryStr: '',
      //           idSubChild: 1363,
      //         },
      //         {
      //           name: '[pdp]3dmark-steam-cd-key-p784330',
      //           displayName: '3DMark',
      //           url: '/3dmark-steam-cd-key-p784330',
      //           queryStr: '',
      //           idSubChild: 1364,
      //         },
      //         {
      //           name: '[pdp]aida64-extreme-pc-1-device-lifetime-key-digital-code-p9890878',
      //           displayName: 'AIDA64 EXTREME',
      //           url: '/aida64-extreme-pc-1-device-lifetime-key-digital-code-p9890878',
      //           queryStr: '',
      //           idSubChild: 1365,
      //         },
      //         {
      //           name: '[pdp]avg-pc-tuneup-2023-unlimited-device-2-years-digital-code-p9887994',
      //           displayName: 'AVG PC TuneUp',
      //           url: '/avg-pc-tuneup-2023-unlimited-device-2-years-digital-code-p9887994',
      //           queryStr: '',
      //           idSubChild: 1366,
      //         },
      //         {
      //           name: '[pdp]ccleaner-professional-plus-pc-1-device-1-year-digital-code-p9891078',
      //           displayName: 'CCleaner Professional Plus',
      //           url: '/ccleaner-professional-plus-pc-1-device-1-year-digital-code-p9891078',
      //           queryStr: '',
      //           idSubChild: 1367,
      //         },
      //         {
      //           name: '[pdp]avast-cleanup-boost-pro-android-1-device-3-years-digital-code-p9891040',
      //           displayName: 'Avast Cleanup & Boost Pro',
      //           url: '/avast-cleanup-boost-pro-android-1-device-3-years-digital-code-p9891040',
      //           queryStr: '',
      //           idSubChild: 1368,
      //         },
      //         {
      //           name: '[pdp]avast-cleanup-premium-1-device-3-years-digital-code-p9886543',
      //           displayName: 'Avast Cleanup Premium',
      //           url: '/avast-cleanup-premium-1-device-3-years-digital-code-p9886543',
      //           queryStr: '',
      //           idSubChild: 1369,
      //         },
      //         {
      //           name: '[pdp]avg-cleaner-pro-android-1-device-3-years-digital-code-p9891010',
      //           displayName: 'AVG Cleaner Pro',
      //           url: '/avg-cleaner-pro-android-1-device-3-years-digital-code-p9891010',
      //           queryStr: '',
      //           idSubChild: 1370,
      //         },
      //         {
      //           name: '[pdp]iolo-system-mechanic-pc-5-devices-1-year-digital-code-p9888491',
      //           displayName: 'Iolo System Mechanic',
      //           url: '/iolo-system-mechanic-pc-5-devices-1-year-digital-code-p9888491',
      //           queryStr: '',
      //           idSubChild: 1371,
      //         },
      //       ],
      //     },
      //     {
      //       name: 'Professional Software',
      //       displayName: 'Editing/Creative Software',
      //       idSub: 133,
      //       children: [
      //         {
      //           name: '[pdp]autodesk-autocad-edu-2023-pc-2-devices-1-year-digital-code-p9890879',
      //           displayName: 'AUTODESK AUTOCAD (2023)',
      //           url: '/autodesk-autocad-edu-2023-pc-2-devices-1-year-digital-code-p9890879',
      //           queryStr:
      //             '/autodesk-autocad-edu-2023-pc-2-devices-1-year-digital-code-p9890879',
      //           idSubChild: 1372,
      //         },
      //         {
      //           name: '[pdp]autodesk-autocad-edu-2024-pc-2-devices-1-year-digital-code-p9890881',
      //           displayName: 'AUTODESK AUTOCAD (2024)',
      //           url: '/autodesk-autocad-edu-2024-pc-2-devices-1-year-digital-code-p9890881',
      //           queryStr: '',
      //           idSubChild: 1373,
      //         },
      //         {
      //           name: '[pdp]wondershare-filmora-12-video-editor-1-device-lifetime-digital-code-p9886467',
      //           displayName: 'Wondershare Filmora 12 Video Editor',
      //           url: '/wondershare-filmora-12-video-editor-1-device-lifetime-digital-code-p9886467',
      //           queryStr: '',
      //           idSubChild: 1374,
      //         },
      //         {
      //           name: '[pdp]nero-platinum-unlimited-pc-1-device-lifetime-key-digital-code-p9891070',
      //           displayName: 'Nero Platinum Unlimited',
      //           url: '/nero-platinum-unlimited-pc-1-device-lifetime-key-digital-code-p9891070',
      //           queryStr: '',
      //           idSubChild: 1375,
      //         },
      //       ],
      //     },
      //   ],
      // },
      //  {
      //   name: 'softwares',
      //   displayName: 'Softwares',
      //   url: '/store?productType[]=software&page=1',
      //   queryStr: 'productType=software',
      //   id: 8,
      // },
      // {
      //   name: 'pre-order',
      //   displayName: pre_order_msg ?? '',
      //   url: '/store?preOrder=true&page=1',
      //   queryStr: 'preOrder=true&limit=50',
      //   id: 9,
      //   navbar: true,
      // },
      // {
      //   name: 'ea-play',
      //   displayName: 'EA play',
      //   url: '/store?productType[]=game&page=1',
      //   queryStr: 'platform=EA+Play',
      //   id: 10,
      //   navbar: true,
      // },
      // {
      //   name: 'zomato',
      //   displayName: 'Zomato',
      //   url: '/store?platform=Zomato&page=1',
      //   queryStr: 'platform=Zomato',
      //   id: 12,
      //   navbar: true,
      // },
      // {
      //   name: 'zalando',
      //   displayName: 'Zalando',
      //   url: '/store?platform=Zalando&page=1',
      //   queryStr: 'platform=Zalando',
      //   id: 13,
      //   navbar: true,
      // },
      // {
      //   name: 'myntra',
      //   displayName: 'Myntra',
      //   url: '/store?platform=Myntra&page=1',
      //   queryStr: 'platform=Myntra',
      //   id: 14,
      //   navbar: true,
      // },
      // {
      //   name: 'MakeMyTrip',
      //   displayName: 'MakeMyTrip',
      //   url: '/store?platform=MakeMyTrip&page=1',
      //   queryStr: 'platform=MakeMyTrip',
      //   id: 15,
      //   navbar: true,
      // },
      // {
      //   name: 'microsoft-store',
      //   displayName: 'Microsoft Store',
      //   url: '/store?platform=Microsoft+Store&page=1',
      //   queryStr: 'platform=Microsoft+Store',
      //   id: 16,
      //   navbar: true,
      // },

      // {
      //   name: 'nykaa',
      //   displayName: 'Nykaa',
      //   url: '/store?platform=Nykaa&page=1',
      //   queryStr: 'platform=Nykaa',
      //   id: 17,
      //   navbar: true,
      // },
      // {
      //   name: 'swiggy',
      //   displayName: 'Swiggy',
      //   url: '/store?platform=Swiggy&page=1',
      //   queryStr: 'platform=Swiggy',
      //   id: 18,
      //   navbar: true,
      // },

      // //  {
      // //   name: 'other',
      // //   displayName: 'Other',
      // //   url: '/store?platform=Other&productType=game&page=1',
      // //   queryStr: 'platform=Other&productType=game',
      // //   id: 15,
      // //   navbar:false
      // // },
      // {
      //   name: 'steal deals',
      //   displayName: steal_deals_msg ?? '',
      //   url: '/store/collection/steal-deals',
      //   queryStr: '/store',
      //   id: 19,
      //   navbar: true,
      // },
      // {
      //   name: 'gog-com',
      //   displayName: gog_com_msg ?? '',
      //   url: '/store?page=1&platform[]=GOG+COM',
      //   queryStr: 'platform=GOG+COM&page=1',
      //   id: 20,
      //   navbar: true,
      // },
      // {
      //   name: 'ubisoft-connect',
      //   displayName: 'Ubisoft Connect',
      //   url: '/store?page=1&platform[]=Ubisoft+Connect',
      //   queryStr: 'platform=Ubisoft+Connect&page=1',
      //   id: 21,
      //   navbar: true,
      // },
      // {
      //   name: 'roblox',
      //   displayName: 'Roblox',
      //   url: '/store?page=1&platform[]=Roblox',
      //   queryStr: 'platform=Roblox&page=1',
      //   id: 22,
      //   navbar: true,
      // },
      // {
      //   name: 'mojang',
      //   displayName: 'Mojang',
      //   url: '/store?page=1&platform[]=Mojang',
      //   queryStr: 'platform=Mojang&page=1',
      //   id: 23,
      //   navbar: true,
      // },
      // {
      //   name: 'ncsoft',
      //   displayName: 'Ncsoft',
      //   url: '/store?page=1&platform[]=NCSoft',
      //   queryStr: 'platform=NCSoft&page=1',
      //   id: 24,
      //   navbar: true,
      // },
      // {
      //   name: 'save with plus',
      //   displayName: plus_save_with_plus_msg ?? '',
      //   url: '/plus',
      //   queryStr: '',
      //   id: 25,
      //   navbar: true,
      // },
    ],
  };
};
