import Link from 'next/link';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { generateNavigation } from '../../constants/navItems';
import { useLocale } from '../../contexts/LocalizationContext';
import { GWMobileWrapper } from '../../styles/window.style';
import BrandLogo from '../BrandLogo/BrandLogo';
import { MaskIcon } from '../Icons/Icons';
import NavItem from './NavItem';
import {
  Overlay,
  SidebarHeader,
  SidebarNavContainer,
} from './SidebarMegamenuStyles';

//
export interface NavItemI {
  name: string;
  link: string;
  subItems?: NavItemI[];
}

const SidebarMegamenu: React.FC<{
  open: boolean;
  handleClose: () => void;
}> = ({ open, handleClose }) => {
  let { messages } = useLocale();

  const navItems = useMemo(() => generateNavigation(messages), [messages]);
  const dropdownRef = useRef<null | HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (e: any) => {
      const { target } = e;
      if (dropdownRef.current) {
        if (!dropdownRef.current.contains(target)) {
          handleClose();
        }
      }
    },
    [dropdownRef]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [open]);

  const additionalData = [
    {
      name: 'pre order',
      displayName: 'Pre-order' ?? '',
      url: '/store/pre-order',
      queryStr: '/store',
      id: 19,
      navbar: true,
    },
    {
      name: 'steal deals',
      displayName: 'Steal Deals' ?? '',
      url: '/store/collection/steal-deals',
      queryStr: '/store',
      id: 19,
      navbar: true,
    },
    {
      name: 'save with plus',
      displayName: 'Save with' ?? '',
      url: '/plus',
      queryStr: '/store',
      id: 19,
      navbar: true,
    },
  ];

  return (
    <>
      <SidebarNavContainer ref={dropdownRef} open={open}>
        <ul>
          <SidebarHeader>
            <Link href="/" passHref>
              <a>
                <BrandLogo />
              </a>
            </Link>
            <MaskIcon
              url="/icons/close-24.svg"
              width="24px"
              height="24px"
              margin="0"
              color="#fff"
              onClick={handleClose}
              style={{ cursor: 'pointer' }}
            />
          </SidebarHeader>
          {navItems.children
            .filter((cur) => !!cur.navbar)
            .map((cur, i) => (
              <NavItem
                handleClose={handleClose}
                type="subDivision"
                data={cur}
                key={i}
                open={open}
                step={1}
              />
            ))}
          <GWMobileWrapper>
            {additionalData.map((cur, i) => (
              <NavItem
                handleClose={handleClose}
                type="subDivision"
                data={cur}
                key={i}
                open={open}
                additional
                step={1}
              />
            ))}
          </GWMobileWrapper>
        </ul>
      </SidebarNavContainer>
      {open && <Overlay />}
    </>
  );
};

export default SidebarMegamenu;
